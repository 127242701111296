import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, NgZone, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SignupService } from '../services/signup.service';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BaseService } from '../services/base.service';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs/public_api';
import { ProductService } from '../services/product.service';
import { industries, employees, countries } from '../shared/test-data';
import { OnBoardingUserService } from '../services/on-boarding-user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Compiler } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UamService } from '../services/uam.service';
import { UserDeviceService } from '../services/user-device.service';
import { Observable } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Country, State } from 'country-state-city';

// declare let JSEncrypt: any;
@Component({
  selector: 'app-home-v2',
  templateUrl: './home-v2.component.html',
  styleUrls: ['./home-v2.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  // encapsulation: ViewEncapsulation.None
})
export class HomeV2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('headTabs') staticTabs: TabsetComponent;
  signUpForm: FormGroup;
  submit = false;
  agreed = true;
  emailverificationForm: FormGroup;
  otpVerificationForm: FormGroup;
  attSignInForm: FormGroup;
  signInForm: FormGroup;
  createPasswordForm: FormGroup;
  matchpassword = true;
  userMail: any;
  createPass = false;
  createPass1 = false;
  signUpMail: any;
  userStatus: any;
  UserData: any;
  displayFP = false;
  forgetPassForm: FormGroup;
  fpSubmitted = false;
  employees = employees;
  industry = industries;
  invitedUser = false;
  fieldTextType = false;
  fieldTextTypeCreatPass = false;
  fieldTextTypeCreatPass1 = false;
  isLoggedIn$: Observable<boolean>;
  userprofileData: any;
  userData: any;
  subscribeProducts: any;
  userPData: any;
  countries = countries;
  setDisableInEmail = false;
  display = false;
  displayPolicy = false;
  multitab = false;
  subscribeServices: any = [];
  WOId: any;
  AzurLoginUrl: any;

  contactusUrl: any;
  focusTab = 1;
  deviceInfo: any=[];
  googleRedirectUrl: any;
  facebookRedirectUrl: any;
  linkedinRedirectUrl: any;
  secreteKey: any;
  displayPage:any = "";
  characters = '0123456789';
  externalFlow: boolean = false;
  timer1:any;
  timer2:any;
  signup: boolean = true;
  showErrMsg = false;
  countryList:any=[];
  stateList:any=[];
  constructor(
    public messageService: MessageService,
    private signupService: SignupService,
    public route: Router,
    private base: BaseService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private product: ProductService,
    public activatedRoute: ActivatedRoute,
    private uamService: UamService,

    private onBoardingUser: OnBoardingUserService,
    public loader: NgxUiLoaderService,
    private location: Location,
    private _compiler: Compiler,
    private deviceService: UserDeviceService,
    private data: DataSharingService,
    private ngZone: NgZone,
    private http: HttpClient
  ) { }

  ngOnInit() {
    localStorage.removeItem('isLoggedIn');
    // localStorage.openpages = Date.now();
    // let localStorageUrl = localStorage.getItem('breadCrumb');
    // let userLoggedIn = localStorage.getItem('currentWindow');
    // if(localStorageUrl){
    //   let flag = localStorageUrl.includes('https://nsdev.straviso.net');
      // let flag = localStorageUrl.includes(window.location.origin);
      // localStorage.setItem('currentWindow','2');
      // let currentWindow = localStorage.getItem('currentWindow');
      // if(currentWindow){
      //   let count = parseInt(currentWindow) + 1;
      //   localStorage.setItem('currentWindow', JSON.stringify(count));
      // } else {
      //   localStorage.setItem('currentWindow','1');
      // } 
      // if(flag){
        // this.displayPage = "alreadyLoggedIn";
        // this.multitab = true;
    //     this.route.navigateByUrl("/business-dashboard");
    //   }
    // }
    let url = window.location.hash;
    let differentUrl = url.includes("home?email");
    if(localStorage.getItem('isLoggedIn') == 'true' && !differentUrl) {
      this.route.navigateByUrl("/business-dashboard");
      return;
    }
    this.isLoggedIn$ = this.signupService.isLoggedIn;
    console.log('LoggedIn',this.signupService.isLoggedIn);
    this._compiler.clearCache();
    this.loader.start();
    this.deviceService.storeIP();
    this.deviceService.epicFunction();
    this.deviceService.shareDeviceDetails.subscribe(
      (res: any) => {
        console.log('Device Info',res);
        this.deviceInfo = res;
      }
    );
    this.setCountryStateCity();
    this.data.urlChange(this.route.url);
    this.signupService.loggedIn.next(false);
    this.userMail = localStorage.getItem('mail');
    this.contactusUrl = environment.contactusUrl;
    this.AzurLoginUrl = environment.azureuserloginurl;
    this.googleRedirectUrl = environment.googleuserloginurl;
    this.facebookRedirectUrl = environment.facebookuserloginurl;
    this.linkedinRedirectUrl = environment.linkedinuserloginurl;
    // this.getSecretKey()
    if(this.activatedRoute.snapshot.queryParams.Origin == 'CLK') {
      this.signup = false;
    }
    if (this.activatedRoute.snapshot.queryParams &&
          this.activatedRoute.snapshot.queryParams.token &&
          this.activatedRoute.snapshot.queryParams.emailId &&
          this.activatedRoute.snapshot.queryParams.loginType) {
      // localStorage.setItem('auth_token', this.activatedRoute.snapshot.queryParams['token']);
      // Commented by deepanshu
      this.extLoginOnlyFlgBtn = JSON.parse(localStorage.getItem('extLogin'));
      this.externalAdProviderBtn = localStorage.getItem('extProvider');
      let userId = localStorage.getItem('emailId');
      if( this.extLoginOnlyFlgBtn = true && this.externalAdProviderBtn == "azure"){
        if(userId != this.activatedRoute.snapshot.queryParams.emailId){
          this.showErrMsg = true;
          localStorage.clear();
          this.loader.stop();
          return; 
        }
        this.userId = this.activatedRoute.snapshot.queryParams.emailId;
        this.selectedBusinessId = localStorage.getItem('businessId');
        this.externalFlow = true;
        this.nsSignIn();
      } else if( this.extLoginOnlyFlgBtn = true && this.externalAdProviderBtn == "google"){
        if(userId != this.activatedRoute.snapshot.queryParams.emailId){
          this.showErrMsg = true;
          localStorage.clear();
          this.loader.stop();
          return; 
        }
        this.userId = this.activatedRoute.snapshot.queryParams.emailId;
        this.selectedBusinessId = localStorage.getItem('businessId');
        this.externalFlow = true;
        this.nsSignIn();
      } else {
        localStorage.clear();
        localStorage.setItem("tToken", this.activatedRoute.snapshot.queryParams.token);
        let req = {
          emailId : this.activatedRoute.snapshot.queryParams.emailId
        };
        // this.displayPage = "business";
        this.externalFlow = true;
        this.showOtpTab = true;
        this.fetchOrgList(req);
      }     
      // sessionStorage.setItem('tChecks', JSON.parse('1'));
      // Commented by deepanshu
      // localStorage.setItem('userData', JSON.stringify({ accessToken: this.activatedRoute.snapshot.queryParams.token }));
      // this.trackUserLogin(this.activatedRoute.snapshot.queryParams.loginType,this.activatedRoute.snapshot.queryParams.emailId);
      // this.getUserDetailNew(this.activatedRoute.snapshot.queryParams.emailId, this.activatedRoute.snapshot.queryParams.userRole, this.activatedRoute.snapshot.queryParams.businessId);
    }
    else if (this.activatedRoute.snapshot.queryParams &&
      this.activatedRoute.snapshot.queryParams.email &&
      this.activatedRoute.snapshot.queryParams.mode) {
      sessionStorage.setItem('inviteEmail', this.activatedRoute.snapshot.queryParams.email);
      sessionStorage.setItem('mode', this.activatedRoute.snapshot.queryParams.mode);
      localStorage.setItem('userData', JSON.stringify({ accessToken: this.activatedRoute.snapshot.queryParams.authtoken }));
      localStorage.setItem('businessId', this.activatedRoute.snapshot.queryParams.businessId)
      localStorage.setItem('businessName', this.activatedRoute.snapshot.queryParams.businessName)
      this.displayPage = "login";
      this.invitedUser = true;
    }
    else if (sessionStorage.getItem('inviteEmail')) {
      this.displayPage = "login";
      this.invitedUser = true;
    }
    else {
      this.displayPage = "login";
      this.invitedUser = false;
    }
    if ((localStorage.getItem('verify') &&
      localStorage.getItem('verify') === 'true' &&
      localStorage.getItem('verify') !== undefined) ||
      (localStorage.getItem('verfiyIM') && localStorage.getItem('verfiyIM') === 'true')) {
      this.displayPage = "login";
      this.createPass = true;
      this.messageService.add({ severity: 'warn', summary: 'Please create password.' });
    }

    // if(localStorage.getItem('verfiyIM') && localStorage.getItem('verfiyIM') === 'true') {
    //   this.createPass = true;
    //   //console.log('true');

    // } else if(localStorage.getItem('verify') &&
    // localStorage.getItem('verify') === 'true' && localStorage.getItem('verify') != undefined){
    //   this.createPass = true;
    // }

    // this.signUpForm = new FormGroup({
    //   email: new FormControl('', [Validators.required, Validators.email]),
    //   agreed: new FormControl('', [Validators.required])
    // });

    this.signUpForm = new FormGroup({
      // businessName: new FormControl('', [Validators.required,Validators.pattern('^(?! )[A-Za-z0-9 ]*$')]),
      businessName: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z][a-zA-Z0-9\s]*[a-zA-Z0-9]$/)]),
      industry: new FormControl(undefined),
      employee: new FormControl(undefined),
      country: new FormControl(undefined, [Validators.required]),
      firstName: new FormControl('', [Validators.required,
            Validators.pattern('^(?! )[A-Za-z ]*$'), Validators.maxLength(30)]),
      lastName: new FormControl('', [Validators.required,
          Validators.pattern('^(?! )[A-Za-z ]*$'), Validators.maxLength(30)]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      contactNo: new FormControl('', [Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
      jobTitle: new FormControl('', [Validators.maxLength(50), Validators.pattern('^(?! )[a-zA-Z- ]*$')]),
      agreed: new FormControl('', [Validators.required]),
      state: new FormControl(undefined)
    });

    this.createPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8),
        Validators.pattern( /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
      re_password: new FormControl('', [Validators.required])
    });
    this.emailverificationForm = new FormGroup({
      emailId: new FormControl('', [Validators.required,Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)]),
      // password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    this.otpVerificationForm = new FormGroup ({
      emailId: new FormControl('', [Validators.required, Validators.email]),
      // password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
    this.attSignInForm = new FormGroup ({
      emailId: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
    this.signInForm = new FormGroup({
      emailId: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    if(!this.externalFlow){
      this.createFPassForm();
      this.location.replaceState('#/home');
    }
}

  ngAfterViewInit() {
    if( this.extLoginOnlyFlgBtn = true && this.showErrMsg == true){ 
      this.messageService.add({ severity: 'error', summary: 'Email id used for login is different from the email id used for the initial otp login.', life: 5000 });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return;
    }
    if (sessionStorage.getItem('inviteEmail') && sessionStorage.getItem('mode')) {
      console.log('Sign up tab active');
      if(this.staticTabs){
        this.staticTabs.tabs[1].active = true;
        this.staticTabs.tabs[0].disabled = true;
      }
      // this.getBusinessData(sessionStorage.getItem('inviteEmail'), 'User');
      // console.log('INVITED');
      this.setDisableInEmail = true;
      // this.staticTabs.tabs[0].disabled = true;
    } else {
       if(!this.externalFlow){
        if(this.staticTabs){
          this.staticTabs.tabs[0].disabled = false;
        }
      
       }
    }

    if ((localStorage.getItem('verify') &&
          localStorage.getItem('verify') === 'true' &&
          localStorage.getItem('verify') !== undefined) ||
          (localStorage.getItem('verfiyIM') && localStorage.getItem('verfiyIM') === 'true')) {
            if(this.staticTabs){
              this.staticTabs.tabs[0].disabled = false;
              this.staticTabs.tabs[0].active = true;
              this.staticTabs.tabs[1].disabled = true;
            }
   
      this.userprofileData = '';
      this.signUpForm.reset();
    }
    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.signup === 'true') {
      if(this.staticTabs){
        this.staticTabs.tabs[1].active = true;
      }
      this.userprofileData = '';
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  }

  getSecretKey(){
    this.signupService.getSecretKey().subscribe(
      (res: any) => {
        if(res.message === 'success'){
          this.secreteKey = res?.data;
          // console.log("SecretKey", this.secreteKey);
        }
      },
      err=>{
        this.messageService.add({ severity: 'warn', sticky: true,
        summary: 'Something went wrong. Please contact NBOSupport for further assistance.' });
      }
    );
  }

  getUserDetailNew(email, userRole, businessId) {

    this.signupService.getUserDataV1(email, userRole, businessId)
      .subscribe((res: any) => {
        this.loader.stop();
        if (res.status.statusMsg === 'SUCCESS') {
          localStorage.setItem('userPData', JSON.stringify(res?.data));
          if (res) {
            this.userStatus = res.userStatus;
          }
          if (this.userStatus && this.userStatus === 'PP') {
            this.route.navigateByUrl('/');
          } else {
            this.signupService.getUserDetails()
              .subscribe((resp: any) => {
                if (resp.status.statusMsg === 'SUCCESS') {
                  const tempToken = JSON.parse(localStorage.getItem('userData')).accessToken;
                  const userData = resp?.data;
                  userData.accessToken = tempToken;
                  localStorage.setItem('userData', JSON.stringify(userData));
                  this.signupService.checkIflogin(true, '/business-dashboard');
                  // this.route.navigateByUrl("/business-dashboard");
                }
              },
                err => {

                });
          }
        }
      },
        error => {
          this.loader.stop();
        });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextTypeCreatePass() {
    this.fieldTextTypeCreatPass = !this.fieldTextTypeCreatPass;
  }
  toggleFieldTextTypeCreatePass1() {
    this.fieldTextTypeCreatPass1 = !this.fieldTextTypeCreatPass1;
  }
  SignUp(value) {
    if (this.invitedUser) {
      if (value.firstName.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'First Name cannot be empty string.' });
        return;
      }
      else if (value.lastName.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'Last Name cannot be empty string.' });
        return;
      }
      else {
        this.loader.start();
        const req = {
          contact: value.contactNo,
          email: value.emailId,
          firstname: value.firstName,
          lastname: value.lastName,
          jobtitle: value.jobTitle,
          country: value.country,
          state: value?.state,
          businessId: localStorage.getItem('businessId')
        };

        this.submit = true;

        this.signupService.inviteUserSignUp(req)
          .subscribe((res: any) => {
            if (res.status.statusMsg === 'Success') {
              this.loader.stop();
              console.log('Invite user sign up successfully');
              this.signUpMail = sessionStorage.getItem('inviteEmail');
              this.messageService.add({ key: 'c', sticky: true, severity: 'success',
              summary: 'CONGRATULATIONS !', detail: 'Your registration process has been initiated successfully.' });
             
            }
          },
            err => {
              this.loader.stop();
            });
      }
    }
    else {
      if (value.businessName.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'Business Name cannot be empty string.' });
        return;
      }
      else if (value.country.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'Company Name cannot be empty string.' });
        return;
      }
      else if (value.firstName.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'First Name cannot be empty string.' });
        return;
      }
      else if (value.lastName.trim() === '') {
        this.messageService.add({ severity: 'warn', summary: 'Last Name cannot be empty string.' });
        return;
      }
      else {
        //console.log(value);
        if (value.agreed !== true) {
          return;
        }
        const bName = value.businessName.trim();
        if (bName === '') {
          //console.log('ITS EMPTY');
          this.messageService.add({ severity: 'warn', summary: 'Buisness Name cannot be empty string.' });
          return;
        }
        //console.log('Bui name is ', bName);
        this.loader.start();
        this.submit = true;
        const req = {
          accActivationDate: '',
          accNumber: '',
          accPrimaryEmail: value.emailId,
          accPrimaryFirstName: value.firstName,
          accPrimaryLastName: value.lastName,
          accPrimaryPhone: value.contactNo?value.contactNo:"",
          accStatus: '',
          businessAddress1: '',
          businessAddress2: '',
          businessId: '',
          businessIndustry: value.industry?value.industry:"",
          businessLogoId: '',
          businessName: bName,
          businessNoofEmployees: value.employee?value.employee:"",
          businessWebsite: '',
          city: '',
          country: value.country?value.country:"",
          state: value.country?value.state:"",
          createdBy: '',
          createdDate: '2019-12-12T07:19:26.369Z',
          id: 0,
          updatedBy: '',
          updatedDate: '',
          userJobTitle: value.jobTitle?value.jobTitle:"",
          zip: ''
        };

        this.signupService.signUpNew(req)
          .subscribe((res: any) => {
            if (res.status.statusMsg === 'Success') {
              this.loader.stop();
              //console.log("res : " + res);
              this.signUpMail = value.emailId;
              // this.messageService.add({ key: 'c', sticky: true, severity: 'success',
              // summary: 'CONGRATULATIONS !', detail: 'Your registration process has been initiated successfully.' });
              this.messageService.add({ key: 'd', sticky: true, severity: 'success',
              summary: 'Registration request has been successfully initiated.', detail: 'Your request has been sent for approval.'});
              this.signUpForm.reset();
              this.signUpForm.controls.country.setValue(undefined);
              this.signUpForm.controls.employee.setValue(undefined);
              this.signUpForm.controls.industry.setValue(undefined);
            }
            else {
              this.messageService.add({ severity: 'warn', summary: res.status.statusMsg });
              this.loader.stop();
            }
          },
            err => {
              this.loader.stop();
              this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
            });
      }
    }
  }

  createPassword(value) {

    if (value.password !== value.re_password) {
      this.matchpassword = false;
      this.loader.stop();
      return;
    }
    else {
    // let encrypt = new JSEncrypt();
    // const publickey = this.secreteKey;
    // const decryptDataRow = value.password;
    // encrypt.setPublicKey(publickey);
    // let encryptedData = encrypt.encrypt(decryptDataRow);
    // // value.password = encryptedData;

      const req = {
        emailId: this.userMail,
        // userPassword: encryptedData,
        userPassword: value.password,
        businessId : localStorage.getItem('businessId')
        // isRequestFromNS : true
      };
      this.loader.start();
      // localStorage.clear();
      this.signupService.setPassword(req)
        .subscribe((res: any) => {
          if (res.status.statusMsg === 'SUCCESS') {
            this.messageService.add({ severity: 'success', summary: 'Password Created Successfully' });
            this.createPass = false;
            this.createPasswordForm.reset();
            localStorage.removeItem('verify');
            res.data.accessToken = JSON.parse(localStorage.getItem('userData')).accessToken;
            this.userData = res?.data;
            localStorage.setItem('userData', JSON.stringify(res?.data));
            let businessId = localStorage.getItem('businessId') ? localStorage.getItem('businessId') : this.UserData.businessId;
            this.signupService.getUserDataV1(this.userData.emailId, this.userData.userRole, businessId)
              .subscribe(resp => {
                if (resp.status.statusMsg === 'SUCCESS') {
                  //console.log("User Data Fetched successfully");
                  localStorage.setItem('userPData', JSON.stringify(resp.data));
                  this.userPData = resp.data;
                  //if not invited user
                  // if (!sessionStorage.getItem('mode')) {
                  //   this.signIn({ emailId: this.userMail, password: value.password })
                  // }
                  // else {
                  //   this.product.getUserSubscribeProduct(this.userData.businessId)
                  //     .subscribe((res: any) => {
                  //       this.loader.stop();
                  //       if (res.status['statusMsg'] === 'SUCCESS') {
                  //         this.subscribeProducts = res?.data['products'];
                  //         this.subscribeServices = res?.data['services'];
                  //         this.subscribeProducts.forEach(element => {
                  //           switch (element.productId) {
                  //             case 'SVSLUM':
                  //               this.addUserInLumen();
                  //               break;
                  //             case 'SVSNEX':
                  //               this.addUserInNexus();
                  //               break;
                  //             case 'SVSBUZ':
                  //               this.addUserInbuzz();
                  //               break;
                  //             case 'SVSTSE':
                  //               this.addUserIntaskEasy();
                  //               break;
                  //             case 'SVSFSV':
                  //               this.addUserInFieldServe();
                  //               break;
                  //             default:
                  //               break;
                  //           }
                  //         });

                  //         this.subscribeServices.forEach(ele => {
                  //           switch (ele.productId) {
                  //             case 'SVSAPS':
                  //               this.addUserInAppointmentScheduling();
                  //               break;
                  //             case 'SVSAMT':
                  //               this.addUserInAmtrack();
                  //               break;
                  //             case 'SVSTKT':
                  //               this.addUserInTicketEasy();
                  //               break;
                  //             case 'SVSKNM':
                  //               this.addUserInKnowMe();
                  //               break;
                  //             case 'SVSWOM':
                  //               this.addUserInWorkOrder();
                  //               break;
                  //             case 'SVSASM':
                  //               this.addUserInAssessment();
                  //               break;
                  //             // case 'SVSPMS':
                  //             //   this.addUserInPartner();
                  //             //   break;
                  //             default:
                  //               break;
                  //           }
                  //         });
                  //       }
                  //       else {
                  //         //console.log("error");
                  //       }
                  //     },
                  //       error => {
                  //         //console.log('error');
                  //       })
                  // }

                  this.signIn({ emailId: this.userMail, password: value.password });
                }
              },
                error => {
                  //console.log("User Data Fetcheing failed");
                },
                () => {
                  // this.signIn({ emailId: this.userMail, password: value.password })
                  // this.loader.stop();
                });

          }
          else {
            this.messageService.add({ severity: 'error', summary: res.status.statusMsg });
          }
        },
          err => {
            this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again.' });
          });
    }
  }

  config = {
    length:5,
    allowNumbersOnly:true,
    isPasswordInput:false
  }

  selectedBusinessId:any = "";
  showAuthSection:boolean = undefined;
  setectedBusinessData = null;
  extLoginOnlyFlgBtn = false;
  externalAdProviderBtn = "";
  selectBusiness(obj){
    console.log("value",obj);
    this.setectedBusinessData = obj;
    if(obj.extLoginOnlyFlg == 'Y'){
      this.extLoginOnlyFlgBtn = true;
      this.externalAdProviderBtn = obj.externalAdProvider;
      localStorage.setItem('extLogin', JSON.stringify(this.extLoginOnlyFlgBtn));
      localStorage.setItem('extProvider',obj.externalAdProvider);
      localStorage.setItem('businessId',obj.businessId);
      localStorage.setItem('emailId',this.userId);
    } else {
      this.extLoginOnlyFlgBtn = false;
      this.externalAdProviderBtn = "";
      localStorage.removeItem('extLogin');
      localStorage.removeItem('extProvider');
      localStorage.removeItem('businessId');
      localStorage.removeItem('emailId');
    }    
    if(obj.businessSecAuthFlag =='Y'){
      this.password = "";
      this.showAuthSection = true;
    } else {
      this.password = "";
      this.showAuthSection = false;
    }
    this.selectedBusinessId = obj.businessId;
  }

  showPasswordField: boolean = true;
  checkUserInput(value){
    // console.log(value.emailId)
    localStorage.removeItem('extLogin');
    localStorage.removeItem('extProvider');
    localStorage.removeItem('businessId');
    localStorage.removeItem('emailId');
    if(localStorage.getItem('isLoggedIn') == 'true') {
      this.route.navigateByUrl("/business-dashboard");
      return;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(value.emailId)){
      this.showPasswordField = false;
      this.sendOTPToLoginUser(value)
      return;
    } 
    // if(value.emailId.length>5 && value.emailId.length<=7 && /^([0-9]|[a-z])+([0-9a-z]+)$/i.test(value.emailId)){
    //   this.showPasswordField = true;
    //   this.showOtpTab = true;
    //   this.attFlow = true;
    //   this.attSignInForm.controls.emailId.setValue(value.emailId);
    // } else {
    //   this.messageService.add({ severity: 'warn', summary: "Please enter a valid user id", life: 3000 });
    // }
  }

  showOtpTab:boolean = false;
  sendOTPToLoginUser(value){
    this.loader.start();
    this.userId = value.emailId;
    let req = {
      "userId": value.emailId
    }
    if(this.externalFlow){
      this.signupService.sendOTPToLoginUserV1(req).subscribe((res: any) => {
        this.loader.stop();
        if(res['status'].statusMsg === "Success"){
          this.messageService.add({ severity: 'success', summary: "A One Time Passcode will be sent to the email address you provide.", life: 3000 });
        } else if(res['status'].statusMsg === "You've exceeded the maximum number of attempts to enter correct verification code. Please try after sometime") {
          this.messageService.add({ severity: 'error', summary: `You've exceeded the maximum number of attempts to enter correct verification code. Please contact to Admin for assistance.`, life: 3000 });
          setTimeout(() => {
            window.location.reload();
          }, 6000); 
        } 
        else if(res['status'].statusMsg === "Too many attempts. Please try after sometime") {
          this.messageService.add({ severity: 'error', summary: `Too many attempts. Please try after sometime`, life: 3000 });
          setTimeout(() => {
            window.location.reload();
          }, 6000); 
        }
        else {
          // this.messageService.add({ severity: 'error', summary: "Please enter a valid registered email id", life: 3000 });
          this.messageService.add({ severity: 'error', summary: res['status'].statusMsg  ? res['status'].statusMsg  : "Please enter a valid registered email id", life: 3000 });
        }
      },
      error => {
        this.loader.stop();
        if(error.error.message){
          console.log(error);
          this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
        }
        else{
          console.log(error.error.status.statusMsg);
          this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
        }
      });
    } else {
      localStorage.clear();
      this.otpVerificationForm.controls['emailId'].setValue(value.emailId);
      this.signupService.sendOTPToLoginUserV1(req).subscribe((res: any) => {
        this.loader.stop();
        
        if(res['status'].statusMsg === "Success"){
          console.log("res",res);
          this.showOtpTab = true;
          this.messageService.add({ severity: 'success', summary: "A One Time Passcode will be sent to the email address you provide.", life: 3000 });
        } 
        else if(res['status'].statusMsg === "You've exceeded the maximum number of attempts to enter correct verification code. Please try after sometime") {
          this.messageService.add({ severity: 'error', summary: `Account is locked as you've exceeded the maximum number of attempts to enter the correct verification code. Please contact the Admin for assistance.`, life: 5000 });
        } 
        else if(res['status'].statusMsg === "Too many attempts. Please try after sometime") {
          this.messageService.add({ severity: 'error', summary: `Too many attempts. Please try after sometime`, life: 3000 });
        }
        else {
          // this.messageService.add({ severity: 'error', summary: "Please enter a valid registered email id", life: 3000 });
          this.messageService.add({ severity: 'error', summary: res['status'].statusMsg  ? res['status'].statusMsg  : "Please enter a valid registered email id", life: 3000 });
        }
      },
      error => {
        this.loader.stop();
        if(error.error.message){
          console.log(error);
          this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
        }
        else{
          console.log(error.error.status.statusMsg);
          this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
        }
      });
    }
  }
  
  enableExtVerify: boolean = false;
  setOTPForExtFlow(otp){
    if(otp.length == 5){
      this.OTP = otp;
      this.invalidOTP = false;
      this.enableVerify = true;
    } else {
      this.enableVerify = false;
      return;
    }
  }

  OTP: any = "";
  enableVerify: boolean = false;
  invalidOTP: boolean = false;
  verifyOTP(otp){
    if(otp.length == 5){
      this.OTP = otp;
      this.invalidOTP = false;
      this.enableVerify = true;
    } else {
      this.enableVerify = false;
      return;
    }
  }

  businessList:any = [];
  verifyAndFetchOrgList(value){
    if(localStorage.getItem('isLoggedIn') == 'true') {
      this.route.navigateByUrl("/business-dashboard");
      return;
    }
    this.loader.start();
    let req = {
      "userId": this.userId,
      "otp": this.OTP
    } 
    this.signupService.verifyLoginUserOTPV1(req).subscribe((res: any) => {
      if(res['status'].statusMsg === "OTP verification successfull"){
        localStorage.setItem("tToken",res['data'].tempToken);
        this.timer1 = setTimeout(() => {
          this.messageService.add({ severity: 'warn', summary: 'Please enter a valid user id and/or password.This window will close in few seconds.', life: 30000 });
        }, 270000); 
        this.timer2 = setTimeout(() => {
          window.location.reload();
        }, 300000); 
        this.invalidOTP = false;
        this.enableVerify = true;
        this.businessList = [];
        this.signupService.fetchOrgListforUserV1({userId : value.emailId}).subscribe((res: any) => {
          if(res['status'].statusMsg == "Success"){
           if(res['data'].orgList){
            this.businessList = res['data']?.orgList;
            this.selectedBusinessId = this.businessList[0]?.businessId;
            if(this.businessList[0]?.businessSecAuthFlag === 'Y'){
              this.showAuthSection = true;
            } 
            else if (this.businessList.length == 1) {
              if(this.businessList[0]?.externalAdProvider === 'azure'){
                this.displayPage = ""; 
                this.extLoginOnlyFlgBtn = true;
                this.externalAdProviderBtn = this.businessList[0]?.externalAdProvider;
                localStorage.setItem('extLogin', JSON.stringify(this.extLoginOnlyFlgBtn));
                localStorage.setItem('extProvider',this.businessList[0].externalAdProvider);
                localStorage.setItem('businessId',this.businessList[0].businessId);
                localStorage.setItem('emailId',res['data'].userId);
                window.location.href = this.AzurLoginUrl;
                return;
              } else if(this.businessList[0]?.externalAdProvider === 'google'){
                console.log("Google redirection functionality is not added.") 
                this.displayPage = ""; 
                this.extLoginOnlyFlgBtn = true;
                this.externalAdProviderBtn = this.businessList[0]?.externalAdProvider;
                localStorage.setItem('extLogin', JSON.stringify(this.extLoginOnlyFlgBtn));
                localStorage.setItem('extProvider',this.businessList[0]?.externalAdProvider);
                localStorage.setItem('businessId',this.businessList[0]?.businessId);
                localStorage.setItem('emailId',res['data'].userId);
                window.location.href = this.googleRedirectUrl;
                return;            
              } else {
                this.nsSignIn();
                return;
              }
            }         
            this.displayPage = "business";
            // setTimeout(() => {
            // }, 500);        
              this.signInForm.controls['emailId'].setValue(res['data'].userId);
            // }, 500);
          }       
          this.loader.stop(); 
          } else {
            this.loader.stop();
          }
        },
        error => {
          this.loader.stop();
          console.log(error);
          this.messageService.add({ severity: 'error', summary: error.error.message });
        });
      } else if(res['status'].statusMsg === "Please enter a valid OTP"){
        this.loader.stop();
        this.messageService.add({ severity: 'warn', summary: `Please enter a valid OTP`, life: 3000 });
      } else if(res['status'].statusMsg === 'OTP Expires'){
        this.loader.stop();
        this.messageService.add({ severity: 'warn', summary: `One Time Passcode has expired .Please use the resend OTP option and retry.`, life: 3000 });
        setTimeout(() => {
          window.location.reload();
        }, 7000); 
      } else {
        this.loader.stop();
        this.messageService.add({ severity: 'error', summary: `Account is locked as you've exceeded the maximum number of attempts to enter the correct verification code. Please contact the Admin for assistance.`, life: 3000 });
        setTimeout(() => {
          window.location.reload();
        }, 7000); 
      }
    },
    error => {
      this.loader.stop();
      console.log(error);
      this.messageService.add({ severity: 'error', summary: error.error.message });
    });
  }

  fetchOrgList(value){
    this.signupService.fetchOrgListforUserV1({userId : value.emailId}).subscribe((res: any) => {
      if(res['status'].statusMsg == "Success"){
        this.userId = res['data'].userId;   
        this.timer1 = setTimeout(() => {
          this.messageService.add({ severity: 'warn', summary: 'Please enter a valid user id and/or password.This window will close in few seconds.', life: 30000 });
        }, 270000); 
        this.timer2 = setTimeout(() => {
          window.location.reload();
        }, 300000); 
        if(this.attFlow){
          this.businessList = res['data']?.orgList;
          this.displayPage = "business";
          this.showOtpTab = true;
          this.selectedBusinessId = this.businessList[0]?.businessId;
        } else {
          this.businessList = res['data'].orgList;
          this.selectedBusinessId = this.businessList[0]?.businessId;
          if(this.businessList[0]?.businessSecAuthFlag === 'Y'){
            this.showAuthSection = true;
          }
          else if (this.businessList.length == 1) {
            if(this.businessList[0]?.extLoginOnlyFlg === 'Y'){
              if(this.businessList[0]?.externalAdProvider === 'azure'){
                this.displayPage = ""; 
                this.extLoginOnlyFlgBtn = true;
                this.externalAdProviderBtn = this.businessList[0]?.externalAdProvider;
                localStorage.setItem('extLogin', JSON.stringify(this.extLoginOnlyFlgBtn));
                localStorage.setItem('extProvider',this.businessList[0]?.externalAdProvider);
                localStorage.setItem('businessId',this.businessList[0]?.businessId);
                localStorage.setItem('emailId',res['data'].userId);
                window.location.href = this.AzurLoginUrl;
                return;                         
              } else if(this.businessList[0]?.externalAdProvider === 'google'){
                console.log("Google redirection functionality is not added.")    
                this.displayPage = ""; 
                this.extLoginOnlyFlgBtn = true;
                this.externalAdProviderBtn = this.businessList[0]?.externalAdProvider;
                localStorage.setItem('extLogin', JSON.stringify(this.extLoginOnlyFlgBtn));
                localStorage.setItem('extProvider',this.businessList[0]?.externalAdProvider);
                localStorage.setItem('businessId',this.businessList[0]?.businessId);
                localStorage.setItem('emailId',res['data'].userId);
                window.location.href = this.googleRedirectUrl;
                return;              
              }
            } else {
              this.nsSignIn();
              return;
            }            
          } 
          this.displayPage = "business"; 
        }
        this.loader.stop();
        // this.userId = res['data'].userId;    
      } else {
        this.loader.stop();
      }
    },
    error => {
      this.loader.stop();
      console.log(error);
      this.messageService.add({ severity: 'error', summary: error.error.message });
    });
  }

  attFlow: boolean = false;
  attLogin(value){
    // console.log(value);
    if(localStorage.getItem('isLoggedIn') == 'true') {
      this.route.navigateByUrl("/business-dashboard");
      return;
    }
    this.loader.start();
    let req = {
      emailId : value.emailId,
      password : value.password,
      userLoginIp : this.deviceInfo?.IP ? this.deviceInfo.IP : '',
      loginBrowser : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.browser : '',
      deviceType : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.device : '',
      loginType : 'Global_ATT',
    };
    // this.product.isLogged = true;
    this.signupService.loginNSATT(req).subscribe((res: any) => {
      this.loader.stop();
      if(res['status'] == 'Success'){
        // this.UserData = res['data'];
        let tkn = res['accessToken'];
        localStorage.setItem("tToken",res['accessToken']);
        this.fetchOrgList({emailId:res['emailId']});
        // this.getUserDetail(res['data']); //to be commented for UAm role check //
        // this.base.stateEmitter('loginstatus'); //to be commented for UAm role check //
      } else {
        this.loader.stop();
        this.messageService.add({ severity: 'error', summary: "Please enter a valid OTP" });
      }
    },
    error => {
      this.loader.stop();
      console.log(error);
      this.messageService.add({ severity: 'error', summary: error.error.message });
    });
  }

  userId:any;
  password:any;
  nsSignIn(){
    if(localStorage.getItem('isLoggedIn') == 'true') {
      this.route.navigateByUrl("/business-dashboard");
      return;
    }
    if(this.externalFlow && !this.attFlow){
      if(this.userId && this.selectedBusinessId){
        if(this.showAuthSection){
          if(this.OTP.length != 5){
            this.messageService.add({ severity: 'warn', summary: "Please enter a valid OTP" });
            return;
          }
        }
        this.loader.start();
        let value = {
          emailId : this.userId,
          password : "",
          businessId : this.selectedBusinessId,
          userLoginIp : this.deviceInfo?.IP ? this.deviceInfo.IP : '',
          loginBrowser : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.browser : '',
          deviceType : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.device : '',
          loginType : 'External',
          otp: this.showAuthSection ? this.OTP : ""
        };
        let encoded = btoa(JSON.stringify(value));
       
        this.product.isLogged = true;
        let payload = { data: encoded }
        this.signupService.nsAuthAPIV1(payload).subscribe((res: any) => {
            this.loader.stop();
            if(res['data'].status == 'Success'){
              this.UserData = res['data'];
              localStorage.removeItem("tToken");
              localStorage.removeItem('extLogin');
              localStorage.removeItem('extProvider');
              localStorage.removeItem('businessId');
              localStorage.removeItem('emailId');
              localStorage.setItem('userData', JSON.stringify(res['data']));
              this.getUserDetail(res['data']); //to be commented for UAm role check //
              this.base.stateEmitter('loginstatus'); //to be commented for UAm role check //
            } else if(res['data'].status === `Account is locked as you've exceeded the maximum number of attempts. Please try after sometime.`){
              this.loader.stop();
              setTimeout(() => {
                window.location.reload();
              }, 7000); 
              this.messageService.add({ severity: 'error', summary: `Account is locked as you've exceeded the maximum number of attempts to enter the correct verification code. Please contact the Admin for assistance.` });
            } else {
              this.loader.stop();
              this.messageService.add({ severity: 'error', summary: "Please enter a valid OTP" });
            }
          },
          error => {
            this.loader.stop();
            if(error.error.message){
              console.log(error);
              this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
            }
            else{
              console.log(error.error.status.statusMsg);
              this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
            }
          });
      }
    } else if(!this.externalFlow && !this.attFlow) {
      if(this.userId && this.selectedBusinessId){
        this.loader.start();
        let value = {
          emailId : this.userId,
          password : this.showAuthSection ? this.password : "",
          businessId : this.selectedBusinessId,
          userLoginIp : this.deviceInfo?.IP ? this.deviceInfo.IP : '',
          loginBrowser : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.browser : '',
          deviceType : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.device : '',
          loginType : 'NorthStar'
        };
        this.product.isLogged = true;
        let encoded = btoa(JSON.stringify(value));
        this.product.isLogged = true;
        let payload = { data: encoded }
        this.signupService.nsAuthAPIV1(payload).subscribe((res: any) => {
            this.loader.stop();
            if(res['data'].status == 'Success'){
              this.UserData = res['data'];
              localStorage.removeItem("tToken");
              localStorage.setItem('userData', JSON.stringify(res['data']));
              this.getUserDetail(res['data']); //to be commented for UAm role check //
              this.base.stateEmitter('loginstatus'); //to be commented for UAm role check //
            } else if(res['data'].status === `Account is locked as you've exceeded the maximum number of attempts. Please try after sometime.`){
              this.loader.stop();
              setTimeout(() => {
                window.location.reload();
              }, 7000); 
              this.messageService.add({ severity: 'error', summary: `Account is locked as you have exceeded the maximum number of login attempts. Please contact to Admin for assistance.` });
            } else {
              this.loader.stop();
              this.messageService.add({ severity: 'error', summary: "Please enter a valid password" });
            }
          },
          error => {
            this.loader.stop();
            if(error.error.message){
              console.log(error);
              this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
            }
            else{
              console.log(error.error.status.statusMsg);
              this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
            }
          });
      }
    } else if(this.attFlow){
      this.loader.start();
      let value = {
        emailId : this.userId,
        password : "",
        businessId : this.selectedBusinessId,
        userLoginIp : this.deviceInfo?.IP ? this.deviceInfo.IP : '',
        loginBrowser : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.browser : '',
        deviceType : this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.device : '',
        loginType : 'Global_ATT',
        otp: this.showAuthSection ? this.OTP : ""
      };
      this.product.isLogged = true;
        let encoded = btoa(JSON.stringify(value));
        this.product.isLogged = true;
        let payload = { data: encoded }
      this.signupService.nsAuthAPIV1(payload).subscribe((res: any) => {
          this.loader.stop();
          if(res['data'].status === 'Success'){
            this.UserData = res['data'];
            localStorage.removeItem("tToken");
            localStorage.setItem('userData', JSON.stringify(res['data']));
            this.getUserDetail(res['data']); 
            this.base.stateEmitter('loginstatus');
          } else if(res['data'].status === `Account is locked as you've exceeded the maximum number of attempts. Please try after sometime.`){
            this.loader.stop();
            setTimeout(() => {
              window.location.reload();
            }, 7000); 
            this.messageService.add({ severity: 'error', summary: `Account is locked as you've exceeded the maximum number of attempts to enter the correct verification code. Please contact the Admin for assistance.` });
          } else {
            this.loader.stop();
            this.messageService.add({ severity: 'error', summary: "Please enter a valid OTP" });
          }
        },
        error => {
          this.loader.stop();
          if(error.error.message){
            console.log(error);
            this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
          }
          else{
            console.log(error.error.status.statusMsg);
            this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
          }
        });
    }
  }

  signIn(value) {
    this.loader.start();
    // localStorage.clear();
    //Comment for now in prod deployment
    value.businessId = localStorage.getItem('businessId');
    value.userLoginIp = this.deviceInfo?.IP ? this.deviceInfo.IP : '';
    value.loginBrowser = this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.browser : '';
    value.deviceType = this.deviceInfo?.deviceInfo ? this.deviceInfo?.deviceInfo.device : '';
    value.loginType = 'NorthStar';
    this.product.isLogged = true;
    //code for encryption
    //  let encrypt = new JSEncrypt();
    //   const publickey = this.secreteKey;
    //   const decryptDataRow = value.password;
    //   encrypt.setPublicKey(publickey);
    //   let encryptedData = encrypt.encrypt(decryptDataRow);
    //   value.password = encryptedData
    //   value.isRequestFromNS = true;
    this.signupService.signIn(value)
      .subscribe((res: any) => {
        this.UserData = res;
        //this.checkUAM()
        // this.loader.stop();
        // if (res.status.statusMsg === 'Success') {
        localStorage.setItem('userData', JSON.stringify(res));

        this.getUserDetail(res); //to be commented for UAm role check //

        // this.getWOUserData();
        //console.log(res);
        // emit loginstatus subject value. store status in localstoragetoo.

        this.base.stateEmitter('loginstatus'); //to be commented for UAm role check //
        // this.route.navigateByUrl('/landingPage');
        // this.messageService.add({ severity: 'success', summary: 'Sign In Successfully.' });
        // }
        // else {
        //   this.messageService.add({ severity: 'error', summary: 'Something went wrong, please try again.' });
        // }
      },
        error => {
          this.loader.stop();
          console.log(error);
          this.messageService.add({ severity: 'error', summary: error.error.message });
        });

  }

  checkUAM() {
    this.base.getUAMSearvice().subscribe(
      (res: any) => {
        if (res.status.statusMsg === 'SUCCESS') {
          const data = res?.data;
          if (data) {
            if (this.userData.userRole !== 'Super Admin') {
              this.getRolesbyMail();
            }
            else {
              this.getUserDetail(res);
              this.base.stateEmitter('loginstatus');
            }
          }
          else {
            this.getUserDetail(res);
            this.base.stateEmitter('loginstatus');
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getRolesbyMail() {
    if (!this.userData.emailId) {
      return;
    }
    this.loader.start();
    this.uamService.getRoleByMailV1(this.userData.emailId).subscribe((res: any) => {
      console.log(res);
      if (res.message === 'Success' && res?.responseData && res?.responseData.userAccess) {
        this.getUserDetail(res);
        this.base.stateEmitter('loginstatus');
        this.loader.stop();
      }
      else {
        this.messageService.clear();
        this.loader.stop();
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn',
        summary: 'Access not given, Please contact Admin', detail: 'Confirm to proceed' });
      }
    });
  }

  onReject() {

  }

  onReject1(){

  }
  
  getUserDetail(res) {
    let businessId = localStorage.getItem('businessId') ? localStorage.getItem('businessId') : this.UserData.businessId;
    this.signupService.getUserDataV1(this.UserData.emailId, this.UserData.userRole, businessId)
      .subscribe(resp => {
        // this.loader.stop();
        if (resp.status.statusMsg === 'SUCCESS') {
          //console.log("User Data Fetched successfully");
          localStorage.setItem('userPData', JSON.stringify(resp.data));
          if (res) {
            this.userStatus = res.userStatus;
          }
          if (this.userStatus && this.userStatus === 'PP') {
            this.route.navigateByUrl('/landingPage/account');
          } else {
            const uData = JSON.parse(localStorage.getItem('userData'));
            this.product.checkProductSubscribeV1(uData.businessId)
              .subscribe((response: any) => {
                if (response?.data) {
                  this.loader.stop();
                  let upData = JSON.parse(localStorage.getItem("userPData"));
                  localStorage.setItem("productIfSubscribed", response.data)
                  // if(upData['industry'] == 'Hospital/Health Care' && upData['dashboardTemplate'] == 'Healthcare'  ) {
                  //   this.signupService.checkIflogin(true, '/healthcare-dashbaord');
                  // }
                  // else {

                  // AG - Added change for if landing page url is configure for any business then it will redirect to that configured landing page else it will stays on northstar landing page.
                  if (this.UserData?.landingPage) {
                    window.location.href = this.UserData.landingPage.includes("https://") || this.UserData.landingPage.includes("http://") ? `${this.UserData?.landingPage}${this.UserData.accessToken}` : `${environment.nineDotBaseUrl}${this.UserData?.landingPage}${this.UserData.accessToken}`;
                  }
                  else {
                    this.signupService.checkIflogin(true, '/business-dashboard');
                  }
                  // }
                }
                else {
                  this.loader.stop();
                  this.signupService.checkIflogin(true, '/landingPage/products-overview');
                  // this.route.navigateByUrl('/landingPage/products-overview');
                }
              });
          }
        }
      },
        error => {
          this.loader.stop();
          //console.log("User Data Fetcheing failed");
        });
  }

  createFPassForm() {
    this.forgetPassForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.loader.stop();
  }

  get f() {
    return this.forgetPassForm.controls;
  }

  onFPSubmit() {
    this.loader.start();
    // this.fpSubmitted = true;
    // if (this.forgetPassForm.invalid) {
    //   return;
    // }
    // this.base.getData(`forgotPassword?userEmail=${this.forgetPassForm.value.email}`)
    let req = {
      "userEmail": this.userId,
      "businessId": this.selectedBusinessId
    }        
    this.signupService.forgotPasswordRequest(req).subscribe((res: any) => {
      this.loader.stop();
      if (res && res.status.statusCode === 2000) {
        if (res && res.status.statusMsg === 'User Not Found') {
          this.messageService.add({ severity: 'warn', summary: 'User not found', life: 5000 });
        } else if (res && res.status.statusMsg.includes('Forget Password feature has been disabled')){
          this.messageService.add({ severity: 'error',
          summary: `Forget Password feature has been disabled for associated business organization.
          Please contact your System Administrator for further support` });
        } else if(res.status.statusMsg === "Max Password Reset Limit Exceeded") {
          this.messageService.add({ severity: 'warn', summary: res.status.statusMsg });
        } else {
          this.messageService.add({ severity: 'success',
          summary: 'Forgot password link emailed to ' + this.userId, life: 3000 });
          // setTimeout(() => {
          // this.route.navigateByUrl('/home');
          window.location.reload();
          // }, 500);
        }
        // this.forgetPassForm.reset();
        // this.displayFP = false;
      } else {
        this.messageService.add({ severity: 'warn', summary: res.status.statusMsg });
        // this.forgetPassForm.reset();
      }
    }, err => {
      this.loader.stop();
      //console.log(err);
      this.messageService.add({ severity: 'warn', summary: 'Something went wrong.' });
      // this.forgetPassForm.reset();
    });
  }

  // Previous forgot password flow
  // openFPForm() {
  //   this.displayFP = !this.displayFP;
  //   this.forgetPassForm.reset();
  // }

  onSelect(e: TabDirective) {
    //console.log(e.heading);
    if (e.heading === 'Sign in') {
      this.signUpForm.reset();
      this.signUpForm.controls.country.setValue(null);
      this.signUpForm.controls.employee.setValue(undefined);
      this.signUpForm.controls.industry.setValue(undefined);
      this.focusTab = 1;
    }
    else if ((e.heading === 'Sign up') && sessionStorage.getItem('inviteEmail')) {
      this.getBusinessData(sessionStorage.getItem('inviteEmail'), 'User');
    }
    else if (e.heading === 'Sign up') {
      this.signInForm.reset();
    }
  }

  getBusinessData(email, role) {
    this.loader.start();
    let businessId = localStorage.getItem('businessId') ? localStorage.getItem('businessId') : this.UserData.businessId;
    this.base.getData(`profile/getUserProfileV1?userEmail=${btoa(email)}&userRole=${btoa(role)}&businessId=${btoa(businessId)}`)
      .subscribe((res: any) => {
        this.loader.stop();
        if (res.status.statusMsg === 'SUCCESS') {
          this.userprofileData = res?.data;

          this.signUpForm.patchValue({
            businessName: localStorage.getItem('businessName') ? localStorage.getItem('businessName') : this.userprofileData.businessName,
            industry: this.userprofileData.industry ? this.userprofileData.industry : undefined,
            employee: this.userprofileData.employees ? this.userprofileData.employees : undefined,
            country: this.userprofileData?.country,
            state: this.userprofileData?.state ? this.userprofileData?.state:'',
            emailId: email,
            firstName: this.userprofileData.firstname ? this.userprofileData.firstname : '',
            lastName: this.userprofileData.lastname ? this.userprofileData.lastname : '',
          });
          this.onCountrySelect(this.userprofileData?.country);
          console.log(this.signUpForm);
        } else {
          this.messageService.add({ severity: 'warn', summary: `${res.status.statusMsg}`, life: 2000 });
        }
      }, err => {
        this.loader.stop();
        // this.messageService.add({ severity: 'warn', summary: `Something went wrong!!`, life: 2000 });
          this.messageService.add({ severity: 'warn', summary: `Given link is expired, please contact administrator`, life: 2000 });
      });
  }

  addUserInNexus() {

    const req = {
      firstName: this.userData.firstName === null ? '' : this.userData.firstName,
      lastName: this.userData.lastName === null ? '' : this.userData.lastName,
      email: this.userData.emailId,
      uid: this.userData.emailId,
      organization: 'Test Platform',
      typeId: 3
    };

    this.onBoardingUser.nexus(req)
      .subscribe((res: any) => {
        //console.log("Nexus User on boarding completed")
      },
        error => {
          //console.log("Nexus User on boarding completed")
        },
        () => {

        });
  }

  addUserInLumen() {
    const uData = {
      createdBy: '',
      emailAddress: this.userData.emailId,
      firstName: this.userData.firstName === null ? '' : this.userData.firstName,
      lastName: this.userData.lastName === null ? '' : this.userData.lastName,
      lstUserModule: [
        {
          accessType: 'X',
          module: 'ALL'
        }
      ],
      password: '',
      userName: this.userData.firstName
    };

    this.onBoardingUser.lumen(uData)
      .subscribe((res: any) => {
        this.ngOnInit();
        if (res === 'Success') {
          // this.ngOnInit();
          this.messageService.add({ severity: 'success', summary: 'User Successfully inserted into Lumen' });
        }
        else {
          // this.ngOnInit();
          // this.messageService.add({ severity: 'warn', summary: 'User already Exist' });
        }
      },
        error => {

        },
        () => {
          // this.addUserInNexus();
        });

    const req = {
      query: null
    };

    this.onBoardingUser.sara(req)
      .subscribe((res: any) => {
        //console.log(res);
      },
      err => {
        //console.log(err);
      });
  }

  addUserInbuzz() {

    const req = {
      businessId: this.userData.businessId,
      userRole: this.userData.userRole ? this.userData.userRole : '',
      userEmail: this.userData.emailId,
      userFirstName: this.userData.firstName,
      userLastName: this.userData.lastName,
    };

    this.onBoardingUser.Buzz(req)
      .subscribe((res: any) => {
        //console.log("Buzz OnBoarding Success..")
      },
        err => {
          //console.log('Buzz OnBoarding Failed');
        });
  }

  addUserInFieldServe() {
    // let req = {
    //   "email_id": this.userData.emailId,
    //   "first_name": this.userPData.firstname,
    //   "last_name": this.userPData.lastname
    // }

    const req = {
      address1: this.userPData.address1,
      address2: this.userPData.address2,
      businessName: this.userPData.businessName,
      city: this.userPData.city,
      contact: this.userPData.contact,
      country: this.userPData.country,
      state: this.userPData?.state,
      email_id: this.userData.emailId,
      employees: this.userPData.employees,
      first_name: this.userPData.firstname,
      industry: this.userPData.industry,
      jobtitle: this.userPData.jobtitle,
      last_name: this.userPData.lastname,
      websiteurl: this.userPData.websiteurl,
      zipcode: this.userPData.zipcode
    };

    this.onBoardingUser.fieldServe(req)
      .subscribe((res: any) => {
        //console.log("fieldServe OnBoarding Successful");
      },
        err => {
          //console.log("fieldServe OnBoarding Failed");
        });
  }

  addUserIntaskEasy() {
    const req = {
      roleName: this.userData.userRole,
      createdBy: 'NorthStar',
      userName: this.userData.firstName + ' ' + this.userData.lastName,
      emailId: this.userData.emailId,
      isAdmin: false,
    };

    this.onBoardingUser.TaskEasy(req)
      .subscribe((res: any) => {
        //console.log("TaskEasy OnBoarding Successful");
      },
        err => {
          //console.log("TaskEasy OnBoarding Failed");
        });
  }

  addUserInAmtrack() {
    const req = {
      roleName: this.userData.userRole,
      createdBy: 'NorthStar',
      first_name: this.userData.firstName + ' ' + this.userData.lastName,
      last_name: this.userPData.lastname,
      email_id: this.userData.emailId,
      businessName: this.userPData.businessName,
      contact: this.userPData.contact
    };
    this.onBoardingUser.Amtrack(req)
      .subscribe((res: any) => {
        //console.log("TaskEasy OnBoarding Successful");
      },
        err => {
          //console.log("TaskEasy OnBoarding Failed");
        });
  }

  addUserInTicketEasy() {

    const req = {
      userName: this.userData.firstName,
      emailId: this.userData.emailId,
      roleName: this.userData.userRole,
      roleId: 1,
      isAdmin: false,
      disabled: false,
      createdBy: 'NorthStar'
    };

    this.onBoardingUser.TicketEasy(req)
      .subscribe((res: any) => {
        //console.log('TicketEasy OnBoarding Successful !');
      },
      err => {
        //console.log(err);
      });

  }

  addUserInKnowMe() {
    const req = {
      businessId: this.userData.businessId,
      userEmail: this.userData.emailId,
      userFirstName: this.userData.firstName,
      userLastName: this.userData.lastname
    };
    this.onBoardingUser.KnowMe(req)
      .subscribe((res: any) => {
        //console.log("KnowMe OnBoarding Successful");
      },
        err => {
          //console.log("KnowMe OnBoarding Failed");
        });
  }

  addUserInAppointmentScheduling() {
    const req = {
      firstName: this.userData.firstName === null ? '' : this.userData.firstName,
      lastName: this.userData.lastName === null ? '' : this.userData.lastName,
      email: this.userData.emailId,
      uid: this.userData.emailId,
      organization: 'Test Platform',
      typeId: 3
    };

    this.onBoardingUser.Appointment(req)
      .subscribe((res: any) => {
        //console.log("Appointemnt onboarding done .. !")
      },
      err => {
        //console.log("error occure while onboarding appointment");
      });
  }

  addUserInWorkOrder() {
    const req = {
      userName: this.userData.firstName + ' ' + this.userData.lastName,
      emailId: this.userData.emailId,
      roleName: '',
      roleId: 1,
      isAdmin: (this.userData.userRole === 'Super Admin' || this.userData.userRole === 'Admin') ? true : false,
      disabled: false
    };

    this.onBoardingUser.WorkOrder(req)
      .subscribe((res: any) => {
        //console.log("WorkOrder onboarding done .. !")
      },
      err => {
        //console.log("error occure while onboarding WorkOrder");
      });
  }

  addUserInAssessment() {
    const req = {
      businessId: this.userData.businessId,
      userRole: this.userData.userRole ? this.userData.userRole : '',
      userEmail: this.userData.emailId,
      userFirstName: this.userData.firstName,
      userLastName: this.userData.lastName,
    };

    this.onBoardingUser.Assessment(req)
      .subscribe((res: any) => {
        console.log('Assessment onboarding done .. !');
      },
      err => {
        console.log('error occure while onboarding Assessment');
      });
  }

  addUserInPartner() {
    const req = {
      businessId: this.userData.businessId,
      userRole: this.userData.userRole ? this.userData.userRole : '',
      userEmail: this.userData.emailId,
      userFirstName: this.userData.firstName,
      userLastName: this.userData.lastName,
    };
    this.onBoardingUser.Partner(req).subscribe((res: any) => {
      console.log('Partner onboarding done .. !');
    },
      err => {
        console.log('error occure while onboarding Partner');
      });
  }
  openTermsDialog() {
    this.display = true;
  }

  openPrivacyPolicy() {
    this.displayPolicy = true;
  }

  openContactPage() {
    window.open(environment.contactusUrl);
  }

  /* @HostListener('window:resize', ['$event'])
  onResize(event){
     //console.log("Width: " + event.target.innerWidth);
  } */

  trackUserLogin(loginType,emailId){
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const req = {
          emailId,
          password: emailId,
          loginBrowser: this.deviceInfo.deviceInfo.browser,
          userLoginIp: this.deviceInfo.IP,
          deviceType: this.deviceInfo.deviceInfo.device,
          loginType
        };

        this.signupService.trackUserLogin(req).subscribe(
          (res: any) => {

          },
          err => {
            console.log(err);
          });
      } , 1000);
      });
  }

  setCountryStateCity() {
    const allCountriesList = Country?.getAllCountries();
    this.countryList= [];
    allCountriesList?.forEach(el => {
      const countryObj = {
        label: '', value: {name: '', isoCode: ''}
      }
  
      countryObj.label = el.name;
      countryObj.value.name = el.name;
      countryObj.value.isoCode = el?.isoCode;
      this.countryList.push(countryObj);
    });
    console.log("country===",this.countryList)
  }

  onCountrySelect(selectedCountry: string) {
    console.log('Selected Country:', selectedCountry);
    const selectedCountryObject = this.countryList.find(c => c.label === selectedCountry);
    console.log('Selected Country Object:', selectedCountryObject);
    this.setStateOptions(selectedCountryObject?.value?.isoCode);
  }

  setStateOptions(countrycode) {
    const stateList1 = State.getStatesOfCountry(countrycode);
    this.stateList = [];
    stateList1.forEach(el => {
      const stateObj = {
        label: '', value: { name: '', isoCode: '' }
      }
      stateObj.label = el?.name;
      stateObj.value.name = el?.name;
      stateObj.value.isoCode = el?.isoCode;
      this.stateList.push(stateObj);
    });
    this.stateList = [...this.stateList]
    console.log('Selected Country Object:', this.stateList);
    if(!this.userPData?.state){
      this.signUpForm.patchValue({
        state:null
      })
    }
  }
  // openmessage(){
  //   this.messageService.add({ key: 'c', sticky: true, severity:
  // 'success', summary: 'CONGRATULATIONS !', detail: 'Your registration process has been initiated successfully.' });

  // }
}

import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  public loggedIn = new BehaviorSubject<boolean>(false);
  redirectUrl: string;

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  constructor(private http: HttpClient,private router: Router,
    private ngZone: NgZone) { }

  signUp(req) {
    return this.http.post<any>(environment.mainurl + 'registerNewUser', req);
  }

  signUpNew(req) {
    return this.http.post<any>(environment.mainurl + 'registerBusiness', req);
  }

  inviteUserSignUp(req) {
    return this.http.post<any>(environment.mainurl + 'sendEmailLinkToInviteUser', req);
  }

  signIn(req) {
    return this.http.post<any>(environment.azureBaseUrl + 'auth/login', req);
  }

  nsAuthAPI(req) {
    return this.http.post<any>(environment.mainurl + 'nsAuthAPI', req);
  }

  nsAuthAPIV1(req) {
    return this.http.post<any>(environment.mainurl + 'nsAuthAPIV1', req);
  }

  trackUserLogin(req) {
    return this.http.post<any>(environment.azureBaseUrl + 'auth/trackUserLogin', req);
  }

  OTPRequest(token) {
    return this.http.get<any>(environment.mainurl + 'verifyRegistrationLink?token=' + token);
  }

  verifyOTP(otp, mail, businessId) {
    return this.http.get<any>(environment.mainurl + 'verifyUserOTP?OTP=' + otp + '&userEmail=' + mail + '&businessId=' + businessId);
  }

  resendOTP(mail) {
    return this.http.get<any>(environment.mainurl + 'resendOTP?email=' + mail);
  }

  setPassword(req) {
    return this.http.post<any>(environment.mainurl + 'setUpPasswordForUser', req);
  }

  uploadBusinessLogo(formData, Bid) {
    return this.http.post<any>(environment.mainurl + 'profile/addBusinessLogo?businessId=' + Bid, formData);
  }

  getUserData(mail, role, businessId) {
    return this.http.get<any>(environment.mainurl + 'profile/getUserProfile?userEmail=' + mail + '&userRole=' + role + '&businessId=' + businessId);
  }

  getUserDataV1(mail, role, businessId) {
    let encodedMail = btoa(mail);
    let encodedRole = btoa(role);
    let encodedBusinessId = btoa(businessId);
    return this.http.get<any>(environment.mainurl + 'profile/getUserProfileV1?userEmail=' + encodedMail + '&userRole=' + encodedRole + '&businessId=' + encodedBusinessId);
  }

  getWOUserData() {
    return this.http.get<any>(`${environment.workorderDashUrl}workOrder/core/user/getUserInfo`);
  }

  getUserDetails() {
    return this.http.get<any>(environment.mainurl + 'profile/getUserInfo');
  }

  checkIflogin(value, route) {
    if (!!localStorage.getItem('userData')) {
      if(JSON.parse(localStorage.getItem('userData')).accessToken){
        this.router.navigateByUrl(route);
        this.ngZone.runOutsideAngular(() => {
          setTimeout(()=> this.loggedIn.next(true),1000);
        });
        localStorage.setItem('isLoggedIn', 'true');
      }
    }
    else {
      this.loggedIn.next(false);
      localStorage.setItem('isLoggedIn', 'true');
      //this.isLoggedIn$ = this.loginService.isLoggedIn;
    }
  }
  // Get Data

  getSecretKey() {
    return this.http.get<any>(environment.signInUrl + 'NorthStar/azure-key-vault/config/getSecret?secretName=LoginPublicKey');
  }

  sendOTPToLoginUser(req){
    return this.http.post<any>(environment.mainurl + 'sendOTPToLoginUser' , req);
  }

  sendOTPToLoginUserV1(value){
    let req = { data: btoa(JSON.stringify(value)) };
    return this.http.post<any>(environment.mainurl + 'sendOTPToLoginUserV1' , req);
  }

  verifyLoginUserOTP(req){
    return this.http.post<any>(environment.mainurl + 'verifyLoginUserOTP' , req);
  }
  verifyLoginUserOTPV1(value){
    const req = { data: btoa(JSON.stringify(value))};
    return this.http.post<any>(environment.mainurl + 'verifyLoginUserOTPV1' , req);
  }

  fetchOrgListforUser(req){
    return this.http.post(environment.mainurl + 'fetchOrgListforUser' , req);
  }

  fetchOrgListforUserV1(json){
    const req = { data: btoa(JSON.stringify(json)) };
    return this.http.post(environment.mainurl + 'fetchOrgListforUserV1' , req);
  }

  forgotPasswordRequest(req){
    return this.http.post(environment.mainurl + 'forgotPasswordRequest' , req);
  }

  verifyUserEmailLink(token) {
    return this.http.get<any>(environment.mainurl + 'verifyUserEmailLink?token=' + token);
  }

  loginNSATT(req) {
    return this.http.post<any>(environment.attUrl + 'loginNSATT', req);
  }

  approveBusiness(token,email,businessId){
    return this.http.get<any>(environment.mainurl+'approveBusiness?token='+token+'=>'+email+'=>'+businessId);
  }

  rejectBusiness(token,email,businessId){
    return this.http.get<any>(environment.mainurl+'rejectBusiness?token='+token+'=>'+email+'=>'+businessId);
  }

  getBusinessUserData(businessId,email){
    return this.http.get<any>(environment.mainurl+'getBusinessUserData?tempBusinessId='+businessId+'&userEmail='+email);
  }
  
  regenerateApproveLink(businessId){
    return this.http.get<any>(environment.mainurl+'getRegeneratebusinessApprovalReminder?businessId='+businessId);
  }
}
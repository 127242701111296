<!-- feedback modal start here -->
<p-dialog [(visible)]="feedbackModal" class="feedbackModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div class="heading">
        <h2 class="">Feedback</h2>
        <p>Please rate your experience</p>
    </div>
    <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback(feedbackForm.value)">
        <div class="row text-center">
            <div class="col-12">
                <rating class="rating" formControlName="rating" [max]="max" [readonly]="isReadonly"
                    (onHover)="hoveringOver($event)" (onLeave)="resetStar()"></rating>
                <p class="ratingLabel">{{ratingName}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <textarea class="feedbackTextarea" rows="8" cols="45" formControlName="comment"
                    placeholder="Your comments....."></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12 buttonCol">
                <button class="submit" [disabled]="feedbackForm.invalid">Submit</button>
                <button type="button" class="cancel" (click)="clearFeedbackForm()">Cancel</button>
            </div>
        </div>
    </form>
</p-dialog>

<!-- feedback modal end here -->

<!-- About Modal Start here -->
<p-dialog [(visible)]="aboutModal" class="aboutModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div class="row m-0">
        <div class="col-12 mt-3">
            <h6 class="info-label mb-0">Product Information</h6>
            <h1 class="productHeading">StraViso Global Cloud</h1>
            <p class="info-label mb-0">NorthStar {{currentYear}}</p>
            <p class="info-label mb-0">Version 6.5</p>
        </div>
    </div>
    <div class="info-row mt-3">
        <div class="row m-0 product-row">
            <div class="col-6 col-md-4 col-lg p-0 mt-4" *ngFor="let img of aboutImages">
                <img [src]="img.src" [alt]="img.alt">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 buttonCol text-center">
                <button class="cancel info-button" (click)="clearAboutForm()">Close</button>
            </div>
        </div>
    </div>
</p-dialog>
<!-- About Modal End here -->

<!-- Help Modal Start here -->
<p-dialog header="Help" [(visible)]="displayFPS" class="overlayDialogHelp" [modal]="true" [position]="position"
     [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="checkClose()">
    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    <div class="help-search p-2">
        <div class="ui-inputgroup">
            <span class="searchSpan d-inline-block mr-2">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input placeholder="Search" class="searchBar" type="text" [(ngModel)]="helpText"
                    (ngModelChange)="searchValues(helpText)">
            </span>
            <!-- <span class="ui-inputgroup-addon"><i class="pi pi-search" style="line-height: 1.25;"></i></span>
            <span class="searchSpan">
                <input placeholder="Search" class="searchBar" type="text"  style="
                    margin-bottom: 10px;">
            </span> -->

            <span>
                <button class="btn btn-sm qrefbtn float-right" (click)="downloadQuickRefPdf()">Quick Reference
                    Guide</button>
            </span>
        </div>
    </div>
    <p-accordion (onOpen)="onTabOpen($event)" [activeIndex]="index">
        <p-accordionTab>
            <p-header *ngIf="!textvalueText" style="cursor: pointer;">Index <span class="ml-3"
                    *ngIf="textvalueText">Search Results Found
                    ({{guideResponseLengthNewData}}) </span> </p-header>
            <p-header *ngIf="textvalueText" style="cursor: pointer;">Index <span class="ml-3"
                    *ngIf="textvalueText">Search Results Found
                    ({{guideResponseLengthNewData}}) </span> </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">

                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of guideResponse;let indexofEle=index;">
                        <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName == highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>

                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div>


                        <div *ngIf="showDetails == indexofEle " style="margin-left: 30px;padding: 0px">
                            <div *ngFor="let guideHeading of guide.indexData?.headings;let indexofEless=index;">
                                <span style="display: inline-block">
                                    <p class="faqPara hovera"
                                        (click)="openDetailsHelpItemHeading(indexofEless,guideHeading.headingName,guide.serviceName)"
                                        style="display: list-item;font-size: 14px;cursor: pointer;">

                                        <i class="arrow-text-secondary down"
                                            *ngIf="guideHeading?.headingName && guideHeading?.headingName == highlightCheckHelpHeading "
                                            style="margin-right: 5px;margin-left: 4px;"></i>
                                        <i class="arrow-text-secondary right text-secondary"
                                            *ngIf="guideHeading?.headingName && guideHeading?.headingName && guideHeading.headingName != highlightCheckHelpHeading "
                                            style="margin-right: 5px;margin-left: 4px;"></i>


                                        <span class="text-secondary">{{guideHeading?.headingName}}</span>
                                    </p>

                                </span>
                                <div *ngIf="showDetailsHelpHeading == indexofEless && highlightCheckHelpHeading == guideHeading.headingName "
                                    style="margin-left: 30px;padding: 0px">
                                    <div
                                        *ngFor="let guideSubHeading of guideHeading?.subHeadings;let indexofElesNew=index;">
                                        <span style="display: inline-block">
                                            <p class="faqPara hovera"
                                                (click)="openDetailsHelpItemHeadingNew(indexofElesNew,guideSubHeading.subHeadingName,guideSubHeading.Response)"
                                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                                <i class="arrow-text-secondary down text-secondary"
                                                    *ngIf="guideSubHeading?.subHeadingName && guideSubHeading?.subHeadingName == highlightCheckHelpHeadingNew "
                                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                                <i class="arrow-text-secondary right text-secondary"
                                                    *ngIf="guideSubHeading?.subHeadingName && guideSubHeading?.subHeadingName != highlightCheckHelpHeadingNew "
                                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                                <span class="text-secondary">{{guideSubHeading?.subHeadingName}}</span>
                                            </p>

                                        </span>

                                        <div *ngIf="showDetailsHelpHeadingNew == indexofElesNew && highlightCheckHelpHeadingNew == guideSubHeading.subHeadingName"
                                            style="margin-left: 30px;padding: 0px">
                                            <div
                                                *ngFor="let topic of guideSubHeading?.helpTopics;let indexofElesFinal=index;">
                                                <span style="display: inline-block">
                                                    <p class="faqPara hovera"
                                                        (click)="openDetailsHelpItemFinal(indexofElesFinal,topic.topicName)"
                                                        style="display: list-item;font-size: 14px;cursor: pointer;">

                                                        <i class="arrow-text-secondary down text-secondary"
                                                            *ngIf="topic?.topicName && topic?.topicName == highlightCheckHelpHeading "
                                                            style="margin-right: 5px;margin-left: 4px;"></i>
                                                        <i class="arrow-text-secondary right text-secondary"
                                                            *ngIf="topic?.topicName && topic?.topicName != highlightCheckHelpHeading "
                                                            style="margin-right: 5px;margin-left: 4px;"></i>


                                                        <span class="text-secondary">{{topic?.topicName}}</span>
                                                    </p>

                                                </span>

                                                <img style="display: inline-block" *ngIf="topic?.topicName" 
                                                    src="assets/img/maximize.png" alt="customer"
                                                    style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                                                    (click)="openFormDetails(topic.answer,topic.topicName,'Help',[] )">

                                                <p *ngIf="showDetailsHelpHeadingFinal == indexofElesFinal && highlightCheckHelpFinal == topic?.topicName"
                                                    style="padding-left: 20px">
                                                    <span style="font-size: 13px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(topic?.answer)"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let faqHelp of  guideResponseNewData;let indexofEles=index;">
                        <span style="display: inline-block;width: 280px">
                            <p class="faqPara hovera"
                                (click)="openDetailsHelpItem(indexofEles,faqHelp.topicName,faqHelp.answer)"
                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                <i class="arrow down text-secondary " *ngIf="faqHelp.topicName == highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right text-secondary" *ngIf="faqHelp.topicName != highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                <b style="display: inline-block;"
                                    style="font-family: Roboto !important;">{{faqHelp.topicName}}</b>

                            </p>

                        </span>

                        <img style="display: inline-block" src="assets/img/maximize.png" alt="customer"
                            style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                            (click)="openFormDetails(faqHelp.answer,faqHelp.topicName,'Help',faqHelp.imgLink)">

                        <div *ngIf="showDetailsHelp == indexofEles && newValueCheck == faqHelp.answer ">
                            <p style="padding-left: 20px;font-family: Roboto !important;"><span class="text-secondary"
                                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(faqHelp.answer)"></span></p>
                        </div> ​
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
        <p-accordionTab>
            <p-header style="cursor: pointer;">FAQ's<span class="ml-3" *ngIf="helpText"> Search Results Found
                    ({{faqResponseNew.length}})</span>
            </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">
                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of faqResponse;let indexofEle=index;">
                        <!-- <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName == highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
    
                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div> -->
                        <!-- <div *ngIf="showDetails == indexofEle" style="margin-left: 30px;padding: 0px"> -->
                        <div *ngFor="let faqHelp of  guide?.faqs;let indexofEles=index;">
                            <span style="display: inline-block">
                                <p class="faqPara hovera"
                                    (click)="openDetailsHelpItem(indexofEles,faqHelp.topicName,'')"
                                    style="display: list-item;font-size: 14px;cursor: pointer;">

                                    <i class="arrow down " *ngIf="faqHelp.topicName == highlightCheckHelp "
                                        style="margin-right: 5px;margin-left: 4px;"></i>
                                    <i class="arrow right " *ngIf="faqHelp.topicName != highlightCheckHelp "
                                        style="margin-right: 5px;margin-left: 4px;"></i>


                                    <b style="display: inline-block;"
                                        style="font-family: Roboto !important;">{{faqHelp.topicName}}</b>
                                </p>
                            </span>

                            <img style="display: inline-block" src="assets/img/maximize.png" alt="customer"
                                style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                                (click)="openFormDetails(faqHelp.answer,faqHelp.topicName,'Help',[])">

                            <p *ngIf="showDetailsHelp == indexofEles && highlightCheckHelp == faqHelp.topicName"
                                style="padding-left: 20px"><span class="text-secondary"
                                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(faqHelp.answer)"></span></p>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let guide of faqResponseNew;let indexofEle=index;">
                        <!-- <div *ngFor="let faqHelp of  guide.faqs;let indexofEles=index;"> -->
                        <span style="display: inline-block">
                            <p class="faqPara hovera"
                                (click)="openDetailsHelpItem(indexofEle,guide.topicName,guide.answer)"
                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                <i class="arrow down " *ngIf="guide.topicName == highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.topicName != highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                <b style="display: inline-block;"
                                    style="font-family: Roboto !important;">{{guide.topicName}}</b>
                            </p>
                        </span>

                        <img style="display: inline-block" src="assets/img/maximize.png" alt="customer"
                            style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                            (click)="openFormDetails(guide.answer,guide.topicName,'Help',[])">

                        <p *ngIf="showDetailsHelp == indexofEle && highlightCheckHelp == guide.topicName"
                            style="padding-left: 20px"><b style="font-family: Roboto !important; font-size: 13px;"
                                [innerHTML]="sanitizer.bypassSecurityTrustHtml(guide.answer)"></b></p>
                        <!-- </div> -->
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
        <p-accordionTab>
            <p-header style="cursor: pointer;">Videos <span class="ml-3" *ngIf=" helpText">Search Results Found
                    ({{videoResponseNew.length}})</span> </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">
                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of videoResponse;let indexofEle=index;">
                        <!-- <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName == highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
    
                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div> -->
                        <!-- <div *ngIf="showDetails == indexofEle" style="margin-left: 30px;padding: 0px"> -->
                        <div *ngFor="let video of  guide?.videos;let indexofEles=index;">
                            <img (click)="openVideo(video.answer,video.topicName)" src="assets/img/play.png"
                                alt="" style="width: 28px;display: inline-block;cursor: pointer;margin-right: 15px;">
                            <p class="faqPara" (click)="openVideo(video.answer,video.topicName)"
                                style="font-family: Roboto !important;font-size: 15px;margin-bottom: 5px;display: inline-block;">
                                <b style="cursor: pointer;">{{video.topicName}}</b></p>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let guide of videoResponseNew;let indexofEle=index;">
                        <!-- <div *ngFor="let video of  guide.videos;let indexofEles=index;"> -->
                        <img (click)="openVideo(guide.answer,guide.topicName)" src="assets/img/play.png"
                            alt="" style="width: 28px;display: inline-block;cursor: pointer;margin-right: 15px;">
                        <p class="faqPara" (click)="openVideo(guide.answer,guide.topicName)"
                            style="font-family: Roboto !important;font-size: 15px;margin-bottom: 5px;display: inline-block;">
                            <b style="cursor: pointer;">{{guide.topicName}}</b></p>
                        <!-- </div> -->
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
    </p-accordion>
</p-dialog>

<p-dialog [(visible)]="displaydetailHelp" class="textMsgModalHelp" [modal]="true" [baseZIndex]="10000"
    [position]="overposition" [draggable]="false" [resizable]="false"
    [style]="{width: '950px', height: 'auto',left: '80px'}">
    <p-header>
        <p class="pheading">
            {{textNameData}}</p>
    </p-header>
    <div>
        <p class="faqPara"><b>{{moduleNameData}}</b></p>
    </div>
    <div>
        <div>
            <p class="left-padding">
                <span class="text-secondary" [innerHTML]="sanitizer.bypassSecurityTrustHtml(detailformData)"></span>
            </p>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="videoModalHelp" class="textMsgModalHelpVideo" [modal]="true" [baseZIndex]="10000"
    [position]="overposition" [draggable]="false" [resizable]="false"
    [style]="{width: '950px', height: 'auto',left: '80px'}">

    <p-header>
        <p class="pheading">
            {{videoNameData}}</p>
    </p-header>

    <iframe [src]="videoLink" width="920" height="750" scrolling="no"></iframe>
    <a [href]="videoLink" target="_blank">
        <span>
            <i class="fa fa-expand expandicon"></i>
        </span>
    </a>

</p-dialog>

<!-- Help Modal End here -->

<!-- support Modal start here -->
<p-dialog [(visible)]="textMsgModal" class="textMsgModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
[resizable]="false">
<div>
    <div class="heading">
        <h6 class="title">Send text message at this number</h6>
    </div>
    <div>
        <p class="contactNox text-center">{{onInputChange(supportContactNo,false)}}</p>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12 buttonCol text-center">
        <button class="cancel" (click)="cleartextMsg()">Close</button>
    </div>
</div>
</p-dialog>
<p-dialog [(visible)]="mailModal" class="textMsgModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
[resizable]="false">
<div>
    <div class="heading">
        <h6 class="title">Email Us</h6>
    </div>
    <div>
        <p class="contactMail text-center">{{supportMailId}}</p>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12 buttonCol text-center">
        <button class="cancel" (click)="cleartextMail()">Close</button>
    </div>
</div>
</p-dialog>
<!-- support Modal end here -->
  <!-- Create Ticket Form -->
  <div *ngIf="createTicketSidebar">
    <p-sidebar class="addTicketSidebar" [(visible)]="createTicketSidebar" [position]="position" [baseZIndex]="10000">
        <h6 class="heading">Create Support Ticket for StraViso</h6>
        <form [formGroup]="createTicketForm" (ngSubmit)="createTicket(createTicketForm.value)">
            <div class="row">
                <div class="col-4 businessId pr-1">
                    <label class="label m-0" for="">Business Id</label>
                    <input type="text" formControlName="businessId" name="businessId" disabled=true>
                </div>
                <div class="col-8 businessName pl-1">
                    <label class="label m-0" for="">Business Name</label>
                    <input type="text" formControlName="businessName" name="businessName" disabled=true>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 subject">
                    <div class="Cshadow">
                        <div class="row">
                            <div class="col-2 text-center">
                                <img src="assets/imgs/subject.png" alt="subject">
                            </div>
                            <div class="col-10">
                                <label class="label m-0" for="">Subject<span class="redSpan"> *</span></label>
                                <input type="text" formControlName="subject" name="subject" autocomplete="off"
                                    maxlength="70" placeholder="Maximum 70 characters...">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 product">
                    <div class="Cshadow">
                        <div class="row">
                            <div class="col-2 text-center">
                                <img src="assets/imgs/ticket-product.png" alt="ticket-product">
                            </div>
                            <div class="col-5 pr-1">
                                <label class="label m-0" for="">Product</label>
                                <p-dropdown #dd [options]="products" filter="true" formControlName="product"
                                    name="product" (onShow)="dd.resetFilter()" optionLabel="name"
                                    placeholder="Select a product"></p-dropdown>
                            </div>
                            <div class="col-5 pl-1">
                                <label class="label m-0" for="">Category</label>
                                <p-dropdown #projectdd [options]="projects" filter="true"
                                    (onShow)="projectdd.resetFilter()" formControlName="category" name="category"
                                    optionLabel="name" placeholder="Select a category"></p-dropdown>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-5 pr-1">
                                <label class="label m-0" for="">Priority</label>
                                <p-dropdown #prioritydd [options]="priority" filter="true" formControlName="priority"
                                    ui-dropdown-filter='' (onShow)="prioritydd.resetFilter()" optionLabel="name"
                                    placeholder="Select a Priority">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row mt-1">
                <div class="col-12 des">
                    <div class="Cshadow">
                        <div class="row">
                            <div class="col-2 text-center">
                                <img src="assets/imgs/desc.png" alt="desc">
                            </div>
                            <div class="col-10">
                                <label class="label m-0" for="">Description<span class="redSpan"> *</span></label>
                                <textarea rows="6" formControlName="description" name="description"></textarea>
                            </div>
                            <div class="col-2"></div>
                            <div class="col-7">
                                <label class="label m-0" for="">Attachments</label>
                                <p>{{files[0]?.name}}</p>
                            </div>
                            <div class="col-3 text-right">
                                <input #file style='display: none;' type="file" (change)="onFileChange($event)"
                                    formControlName="file" name="file">
                                <button type="button" (click)="file.click()">Attach File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row mt-3">
                <div class="col-12 des text-right">
                    <button type="submit" [disabled]="createTicketForm.invalid">Create</button>
                    <button class="ml-2" type="button" (click)="clearTicketForm()">Cancel</button>
                </div>
            </div>
        </form>
    </p-sidebar>
  </div>
  
<p-toast position="center" class="success otpFailed" key="v" [modal]="true" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="fa fa-exclamation-circle exclamation" aria-hidden="true"></i>
            <h6>OTP expired</h6>
            <h6>Please Click to resend OTP</h6>
        </div>
        <div class="ui-g ui-fluid">
            <button type="button" (click)="resendOTP()" class="btn btn-primary">Resend OTP</button>

        </div>
    </ng-template>
</p-toast>
<p-toast position="center" class="success otpFailed" key="op" [modal]="true" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="fa fa-exclamation-circle exclamation" aria-hidden="true"></i>
            <h6>You have reached the OTP Limit </h6>
            <h6>For further help, please reach out to us at NBOSupport@Northstar.straviso.net.</h6>
        </div>
        <div class="ui-g ui-fluid">
            <button type="button" (click)="onConfirm()" class="btn btn-primary">OK</button>
        </div>
    </ng-template>
</p-toast>
<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div class="default-theme">
    <nav class="navbar navbar-expand-md bg-northstar navbar-light">
        <div id="ctl_straviso_logo_div" class="brand_name_div tooltip_custom">
            <a class="navbar-brand">
                <img src="assets/imgs/header/stravisologo.svg" alt="Logo" class="head-logo">
            </a>
            <!-- <a class="navbar-brand">
                <div class="bluecontainer">
                    <img src="assets/imgs/Banner21.png" alt="Logo" class="blue-logo">
                    <div class="centered"><pre> Verify Email ID </pre></div>
                </div>
            </a> -->
        </div>
    </nav>
</div>
<div class="container-fluid verifyContainer">
    <!-- <div class="row header">
        <img class="logo pl-5 pb-3" src="assets/imgs/straviso.png" alt="">
    </div> -->
    <div class="verifyRow text-center">
        <form [formGroup]="verifyForm" (ngSubmit)="verifyOTP(verifyForm.value)">
            <p class="m-0 pb-3 big head">Verify Your Identity</p>
            <p class="m-0 pb-3 medium">You are trying to log into NorthStar</p>
            <p class="m-0 pb-1 small">Enter the verification code we emailed to</p>
            <p class="m-0 pb-1 big">{{mail}}</p>
            <p class="m-auto w-20 pb-3 small text-break font-weight-lighter text-muted">Please check your Spam/Junk
                folder if you do not receive the message in your inbox</p>
            <p class="m-0 pb-2 small">Verification code</p>
            <!-- appDigitonly -->
            <p class="m-0 pb-2"><input class="otpInput text-center" decimals="0" type="number" autocomplete="off"
                    formControlName="otp" name="otp" id=""></p>
            <p class="m-0 pb-3 big"><button type="submit" [disabled]="!verifyForm.valid"
                    class="btn btn-primary verifyBtn" maxlength="5">Verify</button></p>
            <p class="m-0 small resend" (click)="resendOTP()">Resend Code</p>
        </form>
        <p class="p-0 m-0" *ngIf="UISentmailFlag">OTP sent successfully. Please check your email</p>
    </div>

    <!-- <button (click)="test()"></button> -->
</div>
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseService } from 'src/app/services/base.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent implements OnInit, OnChanges {
  @Input() displayhelpcontent = false;
  @Output() cancelhelpEmit = new EventEmitter<boolean>();
  position = 'right';
  overposition = 'left';
  guideResponseNew: any = [];
  guideResponse: any;
  CopyguideResponse: any;
  fileName = '/assets/pdf/User Guide_V2.pdf';
  highlightCheck = '';
  showDetails: any;
  highlightCheckHelpHeading: string;
  showDetailsHelpHeading: number;
  checkValueHelp = false;
  guideResponseNewData = [];
  highlightCheckHelpHeadingNew: any;
  showDetailsHelpHeadingFinal: any;
  showDetailsHelpHeadingNew: any;
  highlightCheckHelpFinal: any;
  detailformData: any;
  detailformDataImg: any;
  moduleNameData: any;
  textNameData: any;
  displaydetailHelp: boolean;
  faqResponse: any = [];
  CopyfaqResponse: any = [];
  textvalueText = false;
  textvalueTextNew = true;
  inputText = 'string';
  arrayNotEmptyNew: boolean;
  newArrayHelp = [];
  faqResponseNew = [];
  videoResponseNew = [];
  videoResponse = [];
  CopyvideoResponse: any;
  guideResponseLengthNewData: any;
  arrayNotEmpty: boolean;
  sameLenghtfaq: boolean;
  highlightCheckHelp = '';
  showDetailsHelp: any;
  newValueCheck: any;
  unsafeLink: any;
  videoLink: any;
  videoModalHelp: boolean;
  videoNameData: any;
  index: any = 0;
  helpText: any;
  userGuideResponse:any=[];
  displayUserGuide:boolean = false;
  userGuideTitle:any;
  userGuideLink:any;
  quickRefGuideLabel:any;
  quickRefGuideLink:any;
  constructor(private ngxService: NgxUiLoaderService,private http: HttpClient,
    private baseService: BaseService,
    public sanitizer: DomSanitizer,) {
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(environment.redirectUrl);
     }

  ngOnInit() {
    // this.videoLink = this.sanitizer.bypassSecurityTrustUrl(environment.mainurl);
    this.getTextValues();
    this.quickRefGuideLabel="Reference Guide";
    this.quickRefGuideLink ="assets/refDoc/Reference Guide - Portal Version 051322.html";
    this.userGuideResponse = [
      {
        label:"Reference Guide",
        link:"assets/refDoc/Reference Guide - Portal Version 051322.html"
      },
      {
        label: "Business Logger User Guide",
        link:"assets/refDoc/Business Logger User Guide.html"
      },
      {
        label:"Cloning Utility User Guide",
        link:"assets/refDoc/Cloning utility user guide (1).html"
      },
      {
        label: "Data Export User Guide",
        link:"assets/refDoc/Data Export user guide.html"
      },
      {
        label:"Data Transaction DLL User Guide",
        link:"assets/refDoc/Data Transaction DLL Doc.html"
      },
      {
        label:"DLL Upload User Guide",
        link:"assets/refDoc/Custom DLL creation and upload user guide.html"
      },
      {
        label:"Error Board User Guide",
        link:"assets/refDoc/Error Board User Guide.html"
      },
      {
        label:"Lumen Audit Report User Guide",
        link:"assets/refDoc/Lumen Audit Report User Guide 2022.html"
      },
      {
        label:"Lumen CLICK User Guide",
        link:"assets/refDoc/LumenCLICK User Guide 2022.html"
      },
      {
        label:"Lumen START User Guide",
        link:"assets/refDoc/LumenSTART User Guide.html"
      },
      {
        label:"Lumen User Guide",
        link:"assets/refDoc/Lumen User Guide 2022.html"
      },
      {
        label:"Nexus User Guide",
        link:"assets/refDoc/Nexus User Guide 2022.html"
      },
      {
        label:"SARA User Guide",
        link:"assets/refDoc/SARA User Guide 2022.html"
      },
     /*  {
        label:"User Onboarding Guide",
        link:"assets/refDoc/User Guide - User Onboarding.html"
      } */
    ]
  }

  ngOnChanges() {
    if (this.displayhelpcontent) {
      this.index = 0;
      this.getTextValues();
    }
  }

  getTextValues() {
    this.ngxService.start();
    let faqList = [];
    this.baseService.getHelpData('FAQ').subscribe((response: any) => {
      faqList = response.responseData;
      this.faqResponse = faqList;
      this.faqResponseNew = faqList;
      this.CopyfaqResponse = faqList;
    });
    let videoList = [];
    this.baseService.getHelpData('Video').subscribe((response: any) => {
      console.log(response);
      videoList = response.responseData;
      this.videoResponse = videoList;
      this.videoResponseNew = videoList;
      this.CopyvideoResponse = videoList;
    });
    let indexList: any;
    console.log(sessionStorage.getItem('indexList'));
    if (sessionStorage.getItem('indexList') === null) {
      this.baseService.getHelpData('Index').subscribe((response: any) => {
        console.log(response);
        indexList = response.responseData;
        this.guideResponse = indexList;
        this.CopyguideResponse = indexList;
      });
    }
    else {
      this.guideResponse = JSON.parse(sessionStorage.getItem('indexList'));
      this.CopyguideResponse = JSON.parse(sessionStorage.getItem('indexList'));
      for (let i = 0; i < this.guideResponse.length; i++) {
        this.guideResponseNew.push(this.guideResponse[i]);
      }
      console.log('guide session=== ==', this.guideResponseNew);
    }
    this.ngxService.stop();
  }

  onTabOpen(e) {
    this.index = e.index;
  }

  openDetails(index, Newjson) {

    if (this.highlightCheck === Newjson) {
      this.highlightCheck = '';
      this.showDetails = -1;
    } else {
      this.highlightCheck = Newjson;
      this.showDetails = index;
    }
    console.log(index + Newjson);
  }

  openDetailsHelpItemHeading(index, heading, service) {
    if (this.highlightCheckHelpHeading === heading) {
      this.highlightCheckHelpHeading = '';
      this.showDetailsHelpHeading = -1;
    } else {

      this.highlightCheckHelpHeading = heading;
      this.showDetailsHelpHeading = index;
    }
    console.log(this.showDetailsHelpHeading);
    console.log(index + heading);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openDetailsHelpItemHeadingNew(index, subheading, newvalue) {

    if (this.highlightCheckHelpHeadingNew === subheading) {
      this.highlightCheckHelpHeadingNew = '';
      this.showDetailsHelpHeadingNew = -1;
    } else {

      this.highlightCheckHelpHeadingNew = subheading;
      this.showDetailsHelpHeadingNew = index;
    }
    console.log(this.showDetailsHelpHeadingNew);
    console.log(index + subheading);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openDetailsHelpItemFinal(json, Newjson) {

    if (this.highlightCheckHelpFinal === Newjson) {
      this.highlightCheckHelpFinal = '';
      this.showDetailsHelpHeadingFinal = -1;
    } else {

      this.highlightCheckHelpFinal = Newjson;
      this.showDetailsHelpHeadingFinal = json;
    }
    console.log(this.showDetailsHelpHeadingFinal);
    console.log(json + Newjson);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openFormDetails(json, name, Pnaame, imgArray) {
    console.log(JSON.stringify(json));
    this.detailformData = json;
    this.detailformDataImg = imgArray;
    this.moduleNameData = name;
    this.textNameData = Pnaame;
    this.displaydetailHelp = true;
  }

  CancelForm() {
    this.displaydetailHelp = false;
  }

  openDetailsHelpItem(json, Newjson, newvalue) {
    if (this.highlightCheckHelp === Newjson) {
      this.highlightCheckHelp = '';
      this.showDetailsHelp = -1;
    } else {

      this.highlightCheckHelp = Newjson;
      this.showDetailsHelp = json;
      this.newValueCheck = newvalue;
    }
    console.log(this.showDetailsHelp);
    console.log(json + Newjson);
    this.checkValueHelp = !this.checkValueHelp;
  }

  searchValues(text) {

    console.log(text.length);
    if (text.length > 0) {
      console.log('sssssssssssss');
      this.textvalueText = true;
      this.textvalueTextNew = false;
    } else {
      this.textvalueTextNew = true;
      this.textvalueText = false;
      console.log('dddddddddddddddddddd');
      this.inputText = 'string';
      this.arrayNotEmptyNew = true;
      this.guideResponseNew = this.CopyguideResponse;
      this.videoResponseNew = this.CopyvideoResponse;
      this.faqResponseNew = this.CopyfaqResponse;
      return;
    }


    this.newArrayHelp = [];
    this.faqResponseNew = [];
    this.videoResponseNew = [];
    this.guideResponseNewData = [];


    for (let i = 0; i < this.CopyfaqResponse.length; i++) {
      if (this.CopyfaqResponse[i].faqs) {
        for (let j = 0; j < this.CopyfaqResponse[i].faqs.length; j++) {
          // if(this.faqResponse[i].faqItem[j].Searchkeyword.toLowerCase() === text.toLowerCase()){
          if (this.CopyfaqResponse[i].faqs[j].keyword.toLowerCase().includes(text.toLowerCase())) {
            this.faqResponseNew.push((this.CopyfaqResponse[i].faqs[j]));
            // this.faqResponse.push((this.faqResponse[i].faqItem[j]));
          }
        }
      }
    }

    console.log(JSON.stringify(this.faqResponseNew));

    //video search
    for (let i = 0; i < this.CopyvideoResponse.length; i++) {
      if (this.CopyvideoResponse[i].videos) {
        for (let j = 0; j < this.CopyvideoResponse[i].videos.length; j++) {

          console.log(this.newArrayHelp);
          console.log(this.CopyvideoResponse[i].videos[j]);
          console.log(this.CopyvideoResponse[i].videos[j].keyword.toLowerCase());
          // if(this.faqResponse[i].faqItem[j].keyword.toLowerCase() === text.toLowerCase()){
          if (this.CopyvideoResponse[i].videos[j].keyword.toLowerCase().includes(text.toLowerCase())) {
            console.log('inside video');
            // this.newArrayHelp.push((this.videoResponse[i].videoItem[j]));
            this.videoResponseNew.push((this.CopyvideoResponse[i].videos[j]));
          }
        }
      }
    }

    //items search
    for (let i = 0; i < this.CopyguideResponse.length; i++) {
      if (this.CopyguideResponse[i].indexData) {
        for (let j = 0; j < this.CopyguideResponse[i].indexData.headings.length; j++) {
          console.log(JSON.stringify(this.CopyguideResponse[i]));

          // for(let k=0 ;k<this.CopyguideResponse[i].heading[j].headingName.length;k++){


          for (let l = 0; l < this.CopyguideResponse[i].indexData.headings[j].subHeadings.length; l++) {

            console.log(JSON.stringify(this.CopyguideResponse[i].indexData.headings[j]));

            for (let m = 0; m < this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics.length; m++) {
              // if(this.guideResponse[i].guideItem[j].Searchkeyword.toLowerCase() === text.toLowerCase()){

              // console.log(this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m]?.keyword)
              if (this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m]) {
                if (this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m]?.keyword.toLowerCase().includes(text.toLowerCase())) {
                  this.guideResponseNewData.push((this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m]));
                }
              }
              // }
            }
          }
        }
      }
    }
    console.log(JSON.stringify(this.guideResponseNewData));


    this.guideResponseLengthNewData = this.guideResponseNewData.length;

    if (this.guideResponseLengthNewData > 0) {
      this.guideResponseLengthNewData = this.guideResponseNewData.length;
    } else {
      this.guideResponseLengthNewData = 0;
    }

    console.log(JSON.stringify(this.videoResponseNew));
    console.log(JSON.stringify(this.videoResponseNew.length));
    console.log(JSON.stringify(this.newArrayHelp));
    if (text.length) {
      this.sameLenghtfaq = true;

    } else {
      this.sameLenghtfaq = false;
    }

    if (this.faqResponseNew.length > 0) {
      console.log('aaaaaaaaaaaaaaaaaaaaa');
      this.inputText = 'none';
      this.arrayNotEmpty = true;
    } else {
      console.log('ffffffffffffffff');
      this.inputText = 'string';
      this.arrayNotEmptyNew = true;

    }
    console.log(this.textvalueText);
  }

  openVideo(link, name) {
    console.log(link);
    this.unsafeLink = link;
    const envCheck = environment.mainurl.includes('northstar-app.straviso.net');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const req = {
      userEmail: userData.emailId,
      userName: userData.firstName + ' ' + userData.lastName,
      business_name: envCheck ? 'Northstar' : 'Nsfarm'
    };
    this.unsafeLink = link +'?userdata=' + JSON.stringify(req);
    this.videoLink = this.transform(this.unsafeLink);
    // this.videoLink = this.transform(this.videoLink);
    this.videoNameData = name;
    this.videoModalHelp = true;
  }


  transform(url) {
    url = url.replace(/<[^>]*>/g, '');
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadQuickRefPdf() {
    const link = document.createElement('a');
    link.href = this.fileName;
    link.target = '_blank';
    link.click();
  }

  checkClose() {
    this.cancelhelpEmit.emit(false);
    this.helpText = '';
    this.textvalueText = false;
  }

  
  openUserGuidePopup(label,link){
    this.userGuideTitle = label;
    this.userGuideLink = this.transform(link);
  /*   this.http.get(this.userGuideLink,{ observe: 'response' }).subscribe((res) => {
      console.log(res);
     }); */
    this.displayUserGuide = true;
  }

  userGuideFullScreenView(userGuideDialog: Dialog){
    userGuideDialog.maximize();
  }
}

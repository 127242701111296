import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class DataService {
    currentData: Observable<any>;
    private dataSource = new BehaviorSubject('');
    constructor() {
        this.currentData = this.dataSource.asObservable();

     }

    changeData(data: string) {
        //console.log(data)
        this.dataSource.next(data);
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any, term: string, excludes: any = []): any {
    if (!term || !items) {return items;}

    return FilterPipe.filter(items, term, excludes);
  }

  static filter(items: Array<{ [key: string]: any }>, term: string, excludes: any): Array<{ [key: string]: any }> {
    return items.filter(function(item) {
      return this.checkInside(item, term,excludes);
    });
  }

  checkInside(item: any, term: string, excludes: any) {
    const toCompare = term.toLowerCase();
    if (typeof item === 'string' && item.toString().toLowerCase().includes(toCompare)) {
      return true;
    }

    for (const property in item) {
      if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
        continue;
      }
      if (typeof item[property] === 'object') {
        if (this.checkInside(item[property], term, excludes)) {
          return true;
        }
      }
      else if (item[property].toString().toLowerCase().includes(toCompare)) {
        return true;
      }
    }
    return false;
  }

}

<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div>
  <p-confirmDialog [style]="{width: '25vw'}" [position]="position" [baseZIndex]="10000"></p-confirmDialog>
  <div class="default-theme">
    <nav class="navbar navbar-expand-md bg-northstar navbar-light">
      <div id="ctl_straviso_logo_div" class="brand_name_div tooltip_custom">
        <a class="navbar-brand">
          <img src="assets/imgs/header/stravisologo.svg" alt="Logo" class="head-logo">
        </a>
      </div>
    </nav>
  </div>
  <div class="container-fluid approval-container background-img1">
    <div class="row m-0 justify-content-center approval-row" *ngIf="!isRejected && !isExpired && !isError && !isRegenaratedLink">
      <p class="m-0 medium">Are you sure you want to Decline?</p>
    </div>
    <div class="row m-0 justify-content-center approval-row" *ngIf="isExpired && !isError">
      <p class="m-0 medium">Link has been expired. Click on Re-generate button and try again.</p>
    </div>
    <div class="row m-0 justify-content-center approval-row" *ngIf="isRejected && !isExpired && !isError && !isRegenaratedLink">
      <p class="m-0 medium">Below requestor has been rejected.</p>
    </div>
    <div class="row m-0 justify-content-center approval-row" *ngIf="isError">
      <p class="m-0 medium error-meesage"><img src="assets/imgs/warn.png" style="width: 23px;"
          class="mr-2">{{errorMessage}}</p>
    </div>
    <div class="row m-0 justify-content-center mt-2">
      <p-card class="approval-card" [ngClass]="{'rejected-header':isRejected1, 'approved-header':isApproved}">
        <p-header>
          <div class="row m-0">
            <div class="col-11 p-0">
              User Verification 
            </div>
            <div class="col-1 p-0 pl-4 verify-user-icon">
              <img src="assets/imgs/verify_1.png" style="width: 18px;">
            </div>
          </div>
        </p-header>
        <div class="row m-0">
          <div class="col-9 p-0">
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Business Name<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.businessName}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Industry<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.businessIndustry}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Employees<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.businessNoofEmployees}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Country<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.country}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">First Name<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.accPrimaryFirstName}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Last Name<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.accPrimaryLastName}}</div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Email ID<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.accPrimaryEmail}}
              </div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Contact No.<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.accPrimaryPhone}}
              </div>
            </div>
            <div class="row form-group mb-1 m-0">
              <div class="col-lg-3 col-sm-3 col-xs-3 pr-0 parameterNameCss">
                <label class="formLabel mb-0 parameterNameCss" style="font-size:13px">Job Title<span class="mobileview-colon1">  :</span></label>
              </div>
              <div class="col-lg-9 col-sm-9 col-xs-9 pr-0 pl-0 parameterValueCss"> <span class="mobileview-colon">: &nbsp;&nbsp;</span>
                {{approvedBusinessData?.userJobTitle}}</div>
            </div>
          </div>
          <div class="col-3 pl-4">
            <img class="mt-5 pt-3 image-verify" src="assets/imgs/verified.png" style="width: 95px;">
          </div>
        </div>
      </p-card>
    </div>

    <div class="row m-0 justify-content-center mt-2" *ngIf="!isRejected  && !isExpired  && !isError && !isRegenaratedLink">
      <button type="button" class="btn btn-default submitBtn ml-3 mr-2 mt-2" (click)="rejectBusiness()">Yes</button>
      <button type="button" class="btn btn-default submitBtnNo mt-2" (click)="onCancel()">No</button>
    </div>
    <div class="row m-0 ml-4 mt-3 justify-content-center mt-3" *ngIf="isExpired  && !isError && !isRegenaratedLink">
      <button type="button" class="btn btn-default submitBtn" (click)="regenerateLink()">Re-generate</button>
    </div>
    <div class="row m-0 justify-content-end mt-2" *ngIf="isRejected1 || isRejected">
      <div class="col-7"></div>
      <div class="col-5 symbol-approve-reject" style="padding-left:88px">
        <p class="m-0 rejected-text"><img class="mr-1" src="assets/imgs/reject.png" style="width: 18px;">Rejected</p>
      </div>
    </div>
    <div class="row m-0 justify-content-end mt-2" *ngIf="isApproved">
      <div class="col-7"></div>
      <div class="col-5 symbol-approve-reject" style="padding-left:83px">
        <p class="m-0 rejected-text"><img class="mr-1" src="assets/imgs/approve.png" style="width: 18px;">Approved</p>
      </div>
    </div>
  </div>
</div>
<p-breadcrumb [model]="breadCrumb"></p-breadcrumb>
<div class="mainDiv">
  <!-- <ol class="breadcrumbClass">
    <li class="breadcrumb-item item1"><a href="https://nsdev.straviso.net/#/business-dashboard">Business Dashboard</a></li>
    <li class="breadcrumb-item item2 active" aria-current="page">Data Export</li>
  </ol> -->
  <div class="dashDate">Last <b style="text-decoration: underline;cursor:pointer;" (click)="lastUpdatedRefresh()">Updated</b> :{{lastUpdated}}</div>
  <div id="partConfiguration" class="partConfiguration">
    <div class="container-fluid dashboardContainer">
      <div style="text-align: left;
      z-index: 1;
      width: 50%;
      display: inline-block;
      position: relative;
      top: 8px;">
        <p style="font-family: roboto, sans-serif !important;">My Data Export</p>
      </div> 
      <div class="add-task1" style="z-index: 1;width:50%; display: inline-block;text-align: right;">
        <button class="btn btn-sm addbtncss pointer addTask addtaskA " (click)="generateBtnClick()">Data Export
          </button>
        <i class="pi pi-search"></i>
        <input type="text" (input)="dt.filterGlobal($event.target.value,'contains')" placeholder="Global Search"
          class="searchBar" #searchValueClear name="searchFilter">
        <a class="pointer export addtaskA" pTooltip="Reset Filter" tooltipPosition="top">
          <img class="exportImg" src="assets/imgs/filter.png"
            (click)="clearFilterConfig()">
        </a>
      </div>

      <div class="row m-0 ">
        <div class="table-Content-container">
          <p-table class="table-Content" [columns]="cols" class="table-Content" #dt [value]="exportDataList"
            [filterDelay]="0" [paginator]="true" [rows]="15" [scrollable]="true" [showCurrentPageReport]="true">
            <ng-template pTemplate="header" let-columns>
              <tr style="height: 20px !important">
                <th *ngFor="let col of columns;let i=index;" [class]="col.class" scope="col" class="table-head-auto" [ngClass]="{'highlight': col.isHighlighted}">
                  <span [pSortableColumn]="col.field">
                    {{col.header}}
                  </span>
                  <span [ngSwitch]="col.field">
                    <p-multiSelect class="recordid" *ngSwitchCase="'id'" [(ngModel)]="dt00" [options]="tablerecordid"
                    defaultLabel="{{col.header}}" appendTo="body" 
                    (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i);" dropdownIcon="pi-caret-down">
                  </p-multiSelect>
                    <p-multiSelect class="componentName" *ngSwitchCase="'componentName'" [(ngModel)]="dt01" [options]="tablecomponentName"
                      defaultLabel="{{col.header}}" appendTo="body" 
                      (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i);" dropdownIcon="pi-caret-down">
                    </p-multiSelect>
                    <p-multiSelect class="reportName" *ngSwitchCase="'fileName'" [(ngModel)]="dt02" [options]="tablereportName"
                      defaultLabel="{{col.header}}" appendTo="body"
                      (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i);" dropdownIcon="pi-caret-down">
                    </p-multiSelect>
                    <p-multiSelect class="triggerDate" *ngSwitchCase="'triggerDate'" [(ngModel)]="dt03" [options]="tabletriggerDate"
                      defaultLabel="{{col.header}}" appendTo="body"
                      (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i)" dropdownIcon="pi-caret-down">
                    </p-multiSelect>
                    <p-multiSelect class="size" *ngSwitchCase="'size'" [(ngModel)]="dt04" [options]="tablesize"
                      defaultLabel="{{col.header}}" appendTo="body"
                      (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i)"  dropdownIcon="pi-caret-down">
                    </p-multiSelect>
                    <p-multiSelect class="validTill" *ngSwitchCase="'validTill'" [(ngModel)]="dt05" [options]="tablevalidTill"
                      defaultLabel="{{col.header}}" appendTo="body"
                      (onChange)="dt.filter($event.value, col.field, 'in');checkClass($event,i)" dropdownIcon="pi-caret-down">
                    </p-multiSelect>
                  </span>
                </th>
                <th class="table-head-auto action">Actions</th>
                <!-- <th class="table-head-auto action">View</th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="rowIndex">
              <tr class="table-row">
                <td class="word recordid">
                  <span pTooltip="{{data?.id}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top">
                    {{data?.id}}
                  </span>
                </td>
                <td class="word componentName">
                  <span pTooltip="{{data?.componentName}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top">
                    {{data?.componentName}}
                  </span>
                </td>
                <td class="word reportName" >
                  <span pTooltip="{{data?.fileName}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top"> 
                    {{data?.fileName}}
                  </span>
                 </td>
                <td class="word triggerDate" >
                  <span pTooltip="{{data?.triggerDate | date:'MM/d/yy, HH:mm'}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top"> 
                    {{data?.triggerDate | date:'MM/d/yy, HH:mm' }}
                  </span>
                 </td>
                 <td class="word validTill">
                   <span pTooltip="{{data?.validTill | date:'MM/d/yy, HH:mm'}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top">
                    {{data?.validTill | date:'MM/d/yy, HH:mm' }}
                   </span>
                 </td>
                 <td class="word size">
                  <span pTooltip="{{data?.size ? byteConvert(data?.size) : '0'}}" tooltipStyleClass="dataexport-tooltip" tooltipPosition="top">
                    {{data?.size ? byteConvert(data?.size) : "0"}}
                  </span>
                 </td>

                <td class="word action">
                  <ng-container [ngSwitch]="data?.status">
                    <span *ngSwitchCase="'Completed'">
                      <i class="pi pi-download" pTooltip="Download" tooltipPosition="top" (click)="downloadFile(data?.filePath)"
                        style="color: #3A5AD1;margin-left: 2px;margin-top: 1px;font-size: 0.84rem;"></i>
                      <i class="pi pi-trash" pTooltip="Delete" tooltipPosition="top"
                        style="color: red;margin-left: 7px;margin-top: 1px;font-size: 0.8rem;" (click)="deleteFile(data)"></i> 
                        <i class="pi pi-eye" (click)="getFileDataStream(data);currentPayload=data;" pTooltip="View" tooltipPosition="top" 
                        style="color: #3A5AD1;margin-left: 10px;font-size: 1rem;"></i>
                    </span>
                    <span *ngSwitchCase="'In Progress'"  pTooltip="In Progress" tooltipPosition="top">In Progress</span>
                    <span *ngSwitchCase="'No Record'"  pTooltip="No Record" tooltipPosition="top">No Record</span>
                    <span *ngSwitchCase="'Failed'" style="color: red;"  pTooltip="Failed" tooltipPosition="top">Failed</span>
                    <span *ngSwitchCase="'Deleted'"  pTooltip="Deleted" tooltipPosition="top">Deleted</span>
                    <span *ngSwitchCase="'File Too Large'"  pTooltip="File Too Large" tooltipPosition="top">File Too Large</span>
                    <span *ngSwitchDefault  pTooltip="{{data?.status}}" tooltipPosition="top">{{data?.status}}</span>
                  </ng-container>
                </td>
               
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length">No records found</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft" let-rowData>
              <p class="displayPagination">
                Displaying{{rowData.totalRecords < 1 ? 0 : rowData.first + 1}} to {{rowData.totalRecords> 0 ?
                  rowData.rows + rowData.first <= rowData.totalRecords ? rowData.rows + rowData.first :
                    rowData.totalRecords : 0}} of {{rowData.totalRecords ? rowData.totalRecords : 0}} Records </p>
            </ng-template>
          
          </p-table>
          <p-dialog class="deleteReportModal" [(visible)]="deleteReportModal" modal="modal" [style]="{width: '30vw'}"  [draggable]="false" positionTop="50" [responsive]="true">
            <p-header>
              <!-- <button pButton type="button" icon="fa-info-circle" ></button> -->
              <i style="margin-top: 4px;" class="fa fa-trash" aria-hidden="true"></i>  <span style="margin-left: 5px;">Delete Confirmation</span>
            </p-header>
       
            <h6 style="font-size: 16px;text-align: left;margin-left: 5px;padding: 15px;
            padding-left: 0px;"> Are you sure, you want to delete record Id <b>{{deleteId}}</b> ?</h6>
            <div class="text-right">
              <!-- <button type="button" class="btn btn-default" (click)="close()">No</button> -->
              <button type="button" style="width: 80px;
              position: relative;background-color: #3f51b5;
border-color: #3f51b5;
              right: 30%;cursor: pointer;" class="btn btn-primary sub" (click)="deleteReportDialog(deleteRecord.id)" >Yes</button>
              <button type="button" style="width: 80px;
              position: relative;background-color: #3f51b5;
border-color: #3f51b5;right:27%;
             margin-right:10px;cursor: pointer;" class="btn btn-primary sub" (click)="cancelDelete()" >No</button>
            </div>
        
            <!-- <p-footer>
                <button type="button" pButton icon="fa-check" (click)="display=false" label="Yes">hghk</button>
            </p-footer> -->
        </p-dialog>
        </div>
      </div>
    </div>
  </div>
  <div class="view-file-modal" [style.display]="modalStyle">
    <div class="file-modal-content">
      <div class="modalHeader">
          <h5>{{ currentPayload.id }}:{{ currentPayload.fileName }}</h5>
          <span (click)="hideFileModal();" style="float: right;cursor: pointer;">
            <i class="pi pi-times" style="font-size: 1.5em; color: #fff;padding-top: 1px; padding-left: 2px;"></i>
          </span>
          <span (click)="resetModalTable();loadTable();" pTooltip="Reset filter" style="float: right;cursor: pointer;">
            <img class="resetTable" src="assets/imgs/filter-white.png">
            <!-- <i class="pi pi-filter" style="font-size: 1.5em; color: #fff;"></i> -->
          </span>
      </div>
      <div class="modalBody">
        
        <p-table  *ngIf="viewFileData" class="view-file-table-content" #viewDt [columns]="fileCols" [value]="viewFileData" [scrollable]="true" [loading]="loading"
         [virtualScroll]="true" [virtualRowHeight]="14" scrollHeight="calc(100vh - 208px)" [rows]="rows" [lazyLoadOnInit]="false" 
         [lazy]="true" (onLazyLoad)="loadMoreData($event)">
          <ng-template pTemplate="header" let-columns>
            <tr style="height: 20px !important">
              <th *ngFor="let col of columns;let i=index;" scope="col" class="table-head-view"
                [ngClass]="{'highlight': col.isHighlighted}">
                <span [pSortableColumn]="col.field">
                  {{col.header | titlecase}}
                </span>
                <span>
                  <p-multiSelect [(ngModel)]="fileFilterValues[col.field]" [options]="col['filter']"
                    defaultLabel="{{col.header}}" appendTo="body" [virtualScroll]="true" itemSize="20"
                    (onChange)="viewDt.filter($event.value, col.field, 'in');filterTable($event,i,col.field);"
                    dropdownIcon="pi-caret-down">
                  </p-multiSelect>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-columns="columns">
            <tr class="table-row">
              <td class="word" *ngFor="let col of columns">
                <span pTooltip="{{ rowData[col.field] }}" tooltipStyleClass="dataexport-tooltip"
                  tooltipPosition="top">
                  {{ rowData[col.field] }}
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody" let-columns="columns">
            <tr >
              <td *ngFor="let col of columns">
                <div class="loading-text"></div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="table-row">
              <td class="word" [attr.colspan]="columns.length">No records found</td>
            </tr>
          </ng-template>
    </p-table>
      </div>
      <div *ngIf="viewFileData" class="modalFooter">
        <p class="footer-text">
          Displaying 1 to {{ viewFileData.length }} of {{ filterFlag ? backupFileData.length : viewFileData.length }} Records
        </p>
      </div>
    </div>
  </div>
</div>
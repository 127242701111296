<div class="default-theme"  *ngIf="isLoggedIn$ | async">

    <nav class="navbar navbar-expand-md bg-northstar navbar-light" *ngIf="headerLogo ">

        <div id="ctl_straviso_logo_div" class="brand_name_div tooltip_custom">
            <a class="navbar-brand m-0" [href]="browserBaseUrl+'/#/business-dashboard'">
                <div class="businessLogoContainer" *ngIf="headerLogo;else defaultImg">
                    <div class="preview d-flex flex-row m-0"
                        [attr.style]="'background-color:'+ headerLogo.fontBackgroundColor +';'">
                        <div class="businessLogo col-3 p-0" *ngIf="headerLogo.businessLogo;else defaultBusinessLogoImg">
                            <img class="logo-img" [src]="'data:image/png;base64,' + headerLogo.businessLogo" alt=""
                                width="40" height="40">
                        </div>
                        <ng-template #defaultBusinessLogoImg>
                            <div class="businessLogo col-3 p-0">
                                <img class="logo-img" [src]="defaultBusinessLogo" alt="" width="40" height="40">
                            </div>
                        </ng-template>
                        <div class="col p-0" style="margin-top: 18px;">
                            <div class="row m-0">
                                <div class="col-12 p-0"
                                    [attr.style]="'max-height: 23px;'+'max-width: 570px;'+'font-family:' + headerLogo.fontName + ' !important;'+'font-size:' + headerLogo.fontSize + 'px;'+'color:' + headerLogo.fontColor + ';'+'width: ' +((headerLogo.fontSize.value*1)+headerLogo.businessName.length)*10 + 'px;'+ 'line-height:0.3;margin-left: 5px;'"
                                    [ngClass]="{'font-weight-bold': headerLogo.isBold, 'font-italic': headerLogo.isItalic }">
                                    {{headerLogo.businessName}}
                                    <img class="" style="display: block;margin-top: 7px;" [src]="poweredbyImg" alt=""
                                        width="" height="12" *ngIf="headerLogo.isPoweredByStraviso">
                                </div>
                                <!-- <div class="col-12 p-0">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #defaultImg>
                    <div class="defaultLogoContainer">
                        <img [src]="defaultStravisoIcon" />
                    </div>
                </ng-template>
            </a>
            <a [href]="blueURL">
                <div class="headerContainer">
                    <img [src]="banerLogo" alt="Logo" class="blue-logo">
                    <div
                        [ngClass]="{'componentHeader' : headerLogo, 'defaultHeader' : !headerLogo,'mt-25' : CurrentComponent.split(' ').length == 1}">
                        <div>{{CurrentComponent.split(' ')[0]}}</div>
                        <div>{{CurrentComponent.split(' ')[1]}}</div>
                    </div>
                </div>
            </a>
        </div>

        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
            <!-- (click)="op1.toggle($event)" -->
        </button>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link">
                        <div class="business">
                            <div style="line-height: 1.2 !important; margin-top: 6px !important;">
                                <img *ngIf="partnerLogo" class="brand-logo" [src]="partnerLogo" alt="">
                                <span *ngIf="partnerDetails?.partnerName" class="brand-title">{{partnerDetails?.partnerName}}</span>
                               
                            </div>
                        </div>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!partnerDetails?.partnerName">
                    <a class="nav-link">
                        <div class="business">
                            <div style="line-height: 1.2 !important; margin-top: 6px !important;">
                                <p style="font-size: 14px; font-weight: 500;text-align: left;color:#484DDD;">
                                    Business Id</p>
                                <p>
                                    <span *ngIf="bussinessData"
                                        style="font-size: 14px;color:#484DDD; font-weight: bolder!important;">{{bussinessData?.businessId}}</span>
                                </p>
                            </div>
                        </div>
                    </a>
                </li>
                <li class="nav-item align-self-center">
                    <div class="dateDiv">
                        <p id="day">{{todayDate | date: 'EEEE'}}</p>
                        <p id="date">{{todayDate | date: 'dd'}}</p>
                        <p id="month">{{todayDate | date: 'MMMM'}}</p>
                    </div>
                </li>
                <li class="nav-item align-self-center">
                    <a class="nav-link nine-dots-overlay pr-0">
                        <img class="pointer" [src]="ninedotImg" style="width: 16px;" alt="nineDot"
                            (click)="op1.toggle($event)">
                    </a>
                </li>
                <li class="nav-item align-self-center pr-0">
                    <a class="nav-link pr-0">
                        <img [src]="bellIcon" alt="notification">
                    </a>
                </li>
                <li class="nav-item align-self-center">
                    <a class="nav-link name">
                        {{userData?.firstName}}
                    </a>
                </li>
                <li class="nav-item" dropdown *ngIf="profileMenu=='' || profileMenu == 'logout'">
                    <a class="nav-link" dropdownToggle id="basic-link" aria-controls="basic-link-dropdown">
                        <img class="profileImg pointer mt-2" *ngIf="bussinessData"
                            [src]="bussinessData['userImage'] ? bussinessData['userImage']:profileSrc" alt="">
                    </a>
                    <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu logoutdropdown p-lg-2 p-md-1"
                        role="menu" aria-labelledby="basic-link">
                        <li class="pointer" *ngIf="profileMenu==''">
                            <label for="profile-input" style="cursor: pointer;">
                                <i class="pi pi-user align-middle pi_Icon" aria-hidden="true"></i>
                                Change Profile Picture
                            </label>
                            <input class="addProfileLogo" (change)="onProfilePicChange($event)" style="display: none;"
                                id="profile-input" type="file" accept="image/*" />
                        </li>

                        <li *ngIf="userProfile && profileMenu==''" class="pointer pb-1" (click)="removeProfile($event)">
                            <i class="pi pi-user-minus align-middle pi_Icon" aria-hidden="true"></i> Remove Profile
                            Picture
                        </li>

                        <li class="pointer pb-1" (click)="changePassword($event)" *ngIf="profileMenu==''">
                            <i class="pi pi-lock align-middle  pointer pi_Icon" aria-hidden="true"></i> Change Password
                        </li>
                        <li class="pointer" (click)="logOut($event)" *ngIf="profileMenu=='' || profileMenu=='logout'">
                            <i class="pi pi-power-off align-middle pointer pi_Icon" aria-hidden="true"></i> Logout
                        </li>
                    </ul>
                </li>

                <!-- <ng-template #userStatusTemplate>
                    <li class="nav-item user-profile">
                        <a class="nav-link pl-0 pr-0" >
                            <img [src]="bussinessData['userImage'] ? bussinessData['userImage']:profileSrc"
                                alt="User Image" class="profileImg pointer ml-2" />
                            <span class="badge-status" [style.background-color]="statusColor"></span>
                            <span class="w-100" style="cursor: pointer;text-decoration: none;">
                                <h6 class="header-status mb-0 pr-1">{{ currentStatus?.value }} </h6>
                            </span>
                        </a>
                    </li>
                </ng-template> -->

            </ul>
        </div>
    </nav>

    <p-overlayPanel styleClass="ninedotPanel" #op1 [dismissable]="true">

        <div class="row m-0">
            <ng-container *ngFor="let dot of nineDotsArray2.defaultAcceessMenu;">
                <div class="col-3 p-0 onedot">
                    <a class="nav-link  p-0" (click)="op1.hide();" routerLinkActive="link-active" [attr.href]="(dot?.redirectUrl.includes('token') || dot?.redirectUrl.includes('JWTtoken')) ?
                            browserBaseUrl+''+dot?.redirectUrl+''+userData.accessToken :
                            browserBaseUrl+''+dot?.redirectUrl">
                        <!-- href='{{dot?.redirectUrl}}' -->
                        <img class="nineImg" [src]="browserBaseUrl + dot?.productLogo" alt="{{dot?.productName}}">
                    </a>

                </div>
            </ng-container>
            <ng-container *ngFor="let dot of nineDotsArray2.customAcceessMenu;">
                <div class="col-3 p-0 onedot">
                    <a class="nav-link  p-0" href='{{browserBaseUrl}}{{dot?.redirectUrl}}'>
                        <img class="nineImg" [src]="browserBaseUrl + dot?.productLogo" alt="{{dot?.productName}}">
                    </a>
                </div>
            </ng-container>
        </div>
        <div class="row m-0 bg-nav">
            <ng-container *ngFor="let dot of nineDotsArray2.productAccessMenu;">
                <div class="col-3 p-0 productdot">
                    <a class="nav-link  p-0 "
                        href='{{(dot?.redirectUrl.includes("https://") || dot?.redirectUrl.includes("http://")) ? dot?.redirectUrl + userData.accessToken : browserBaseUrl + dot?.redirectUrl + userData.accessToken }}'>
                        <img class="nineImg" [src]="dot?.productLogo" alt="{{dot?.productName}}">
                    </a>
                </div>
            </ng-container>
        </div>
        <div class="row m-0 bg-nav">
            <div class="onedot col-3 p-0">
                <a class="nav-link  p-0" routerLinkActive="link-active" style="text-align: center;">
                    <img class="nineImg" [src]="feedback" alt="feedback"
                        (click)="op1.hide();getHelpandSupport($event,'Feedback')">
                </a>
            </div>
            <div class="onedot col-3 p-0" *ngIf="userData?.businessId != 'SVS001778' "> <!-- userData?.businessId != 'SVS000688' -->
                <a class="nav-link  p-0" routerLinkActive="link-active" style="text-align: center;">
                    <img class="nineImg" [src]="help" alt="help" (click)="op1.hide();getHelpandSupport($event,'Help')">
                </a>
            </div>
            <div class="onedot col-3 p-0">
                <a class="nav-link  p-0" [popover]="popTemplate" placement="top" [outsideClick]="true"
                    containerClass="customCsPannel" routerLinkActive="link-active" style="text-align: center;">
                    <img class="nineImg" [src]="contactSupport" alt="support">
                </a>
            </div>
            <div class="onedot col-3 p-0">
                <a class="nav-link  p-0" routerLinkActive="link-active" style="text-align: center;">
                    <img class="nineImg" [src]="about" alt="about"
                        (click)="op1.hide();getHelpandSupport($event,'About')">
                </a>
            </div>
            <!-- <button type="button" class="btn btn-success" [popover]="popTemplate"
                popoverTitle="Dynamic html inside">
                Show me popover with html
            </button> -->
        </div>
    </p-overlayPanel>
    <ng-template #popTemplate>
        <div class="csPannel">
            <h6 class="heading m-0">Contact StraViso Support</h6>
            <div class="row">
                <div class="onedot col-3 p-0">
                    <img class="csImg" (click)="op1.hide();getHelpandSupport($event,'Chat')" [src]="supportChatWithUs"
                        alt="notification">
                </div>
                <div class="onedot col-3 p-0">
                    <img class="csImg" (click)="op1.hide();getHelpandSupport($event,'Text Message')"
                        [src]="supportTextMessage" alt="notification">
                </div>
                <div class="onedot col-3 p-0">
                    <img class="csImg" (click)="op1.hide();getHelpandSupport($event,'Create Ticket')"
                        [src]="supportCreateTicket" alt="notification">
                </div>
                <div class="onedot col-3 p-0">
                    <img class="csImg" (click)="op1.hide();getHelpandSupport($event,'Email Us')" [src]="supportEmailUs"
                        alt="notification">
                </div>
            </div>
        </div>
    </ng-template>

    <p-breadcrumb [model]="breadCrumb"></p-breadcrumb>
    <div class="">
        <img class="lumenChatIcon" id="chatIcon" (click)="openForm('askLumen')" src="assets/img/chat_ic.png" alt="">
    </div>

    <div class="chat-popup" id="myForm">
        <iframe id="iframeID" title="LumenChat" allow="camera *;microphone *" [src]="lumenChat_url" height="575px"
            width="100%">
        </iframe>
    </div>

</div>

<app-feedback [aboutModal]="displayAbout" (cancelAboutForm)="closeAbout($event)" [feedbackModal]="displayFeedback"
    (cancelFeedbackForm)="closeFeedback($event)" [displayFPS]="displayHelp" (cancelHelpForm)="closeHelp($event)"
    [textMsgModal]="displaytextMsg" (canceltextMsg)="closetextMsg($event)" [mailModal]="displaytextMail"
    (canceltextMail)="closetextMail($event)" [createTicketSidebar]="displayTicketSidebar"
    (cancelTicketForm)="closeTicket($event)">
</app-feedback>
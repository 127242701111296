import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UamService {
  shareUserAccess: Observable<any>;
  private userAccess = new BehaviorSubject([]);
  constructor(private http: HttpClient) {
    this.shareUserAccess = this.userAccess.asObservable();
  }

  getActionList() {
    return this.http.get<any>(`${environment.mainurl}uam/getActionList`);
  }

  getModuleList() {
    // ${environment.mainurl}uam/getModuleList
    // ../assets/data.json
    return this.http.get<any>(`${environment.mainurl}uam/getModuleList`);
  }

  addRole(req) {
    return this.http.post<any>(`${environment.uamBaseUrl}uam/addUAMRoles`, req);
  }

  getAllUAMRoles() {
    return this.http.get<any>(`${environment.uamBaseUrl}uam/getAllUAMRoles`);
  }
  getAllRoles() {
    return this.http.get<any>(`${environment.uamBaseUrl}uam/getAllRoles`);
  }
  getRoleById(id) {
    // ${environment.uamBaseUrl}uam/getUAMRoleById?roleId=${id}
    // ../assets/roles.json
    return this.http.get<any>(`${environment.uamBaseUrl}uam/getUAMRoleById?roleId=${id}`);
  }

  getRoleByMail(mail) {
    return this.http.get<any>(`${environment.uamBaseUrl}uam/getUserRole?userEmail=${mail}`);
  }

  getRoleByMailV1(mail) {
    return this.http.get<any>(`${environment.uamBaseUrl}uam/getUserRoleV1?userEmail=${btoa(mail)}`);
  }

  updateRole(req) {
    return this.http.put<any>(`${environment.uamBaseUrl}uam/updateUAMRoles`, req);
  }

  deleteRole(id) {
    return this.http.delete<any>(`${environment.uamBaseUrl}uam/deleteUAMRoleById?roleId=${id}`);
  }

  addUserRole(req) {
    return this.http.post<any>(`${environment.uamBaseUrl}uam/addUserRole`, req);
  }

  transferAdminRole(req){
    return this.http.post<any>(`${environment.mainurl}profile/transferSuperAdminRole`, req);
  }

  transferAdminRoleToOthers(req){
    return this.http.post<any>(`${environment.mainurl}transferRole/taskEasy`, req);
  }

  getUserAccess(access){
    this.userAccess.next(access);
  }

  historyAudit(req){
    return this.http.post<any>(`${environment.uamBaseUrl}audit/addHistory`, req);
  }

  getHistoryAudit(req){
    return this.http.post<any>(`${environment.uamBaseUrl}audit/paginationCustomHistory`, req);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent implements OnInit {

  @Input() aboutModal: boolean = false;
  @Output() cancelAboutForm = new EventEmitter<boolean>();
  @Input() feedbackModal: boolean = false;
  @Output() cancelFeedbackForm = new EventEmitter<boolean>();
  @Input() displayFPS: boolean = false;
  @Output() cancelHelpForm = new EventEmitter<boolean>();
  @Input() textMsgModal: boolean = false;
  @Output() canceltextMsg = new EventEmitter<boolean>();
  @Input() mailModal: boolean = false;
  @Output() canceltextMail = new EventEmitter<boolean>();
  @Input() createTicketSidebar: boolean = false;
  @Output() cancelTicketForm = new EventEmitter<boolean>();


  max = 5;
  feedbackForm: FormGroup;
  ratingName: string;
  position = 'right';
  overposition = 'left';
  guideResponseNew: any = [];
  guideResponse: any;
  CopyguideResponse: any;
  fileName: string = 'assets/pdf/User Guide_V2.pdf';
  highlightCheck = "";
  showDetails: any;
  highlightCheckHelpHeading: string;
  showDetailsHelpHeading: number;
  checkValueHelp = false;
  guideResponseNewData = [];
  highlightCheckHelpHeadingNew: any;
  showDetailsHelpHeadingFinal: any;
  showDetailsHelpHeadingNew: any;
  highlightCheckHelpFinal: any;
  detailformData: any;
  detailformDataImg: any;
  moduleNameData: any;
  textNameData: any;
  displaydetailHelp: boolean;
  faqResponse: any = [];
  CopyfaqResponse: any = [];
  textvalueText = false;
  textvalueTextNew = true;
  inputText = "string";
  arrayNotEmptyNew: boolean;
  newArrayHelp = [];
  faqResponseNew = [];
  videoResponseNew = [];
  videoResponse = [];
  CopyvideoResponse: any;
  guideResponseLengthNewData: any;
  arrayNotEmpty: boolean;
  sameLenghtfaq: boolean;
  highlightCheckHelp = "";
  showDetailsHelp: any;
  newValueCheck: any;
  unsafeLink: any;
  videoLink: any;
  videoModalHelp: boolean;
  videoNameData: any;
  index: any = 0;
  helpText: any;
  supportContactNo: any = '';
  supportMailId: any = '';
  createTicketForm: FormGroup;
  files: any = [];
  getBusinessData: any;
  products: any = [];
  projects: any[];
  priority: any[];
  fileSize: boolean;
  isReadonly = false;
  currentYear:any;
  aboutImages: any =[
    {src: "assets/imgs/aboutv1/engage.png", alt:"engage"},
    {src: "assets/imgs/aboutv1/dispatch.png", alt:"dispatch"},
    {src: "assets/imgs/aboutv1/field.png", alt:"field"},
    {src: "assets/imgs/aboutv1/contact.png", alt:"contact"},
    {src: "assets/imgs/aboutv1/ops.png", alt:"ops"},
    {src: "assets/imgs/aboutv1/marketplace.png", alt:"market"},
    {src: "assets/imgs/aboutv1/quantum.png", alt:"quantam"}
  ]
  constructor(private ngxLoader: NgxUiLoaderService,
    private product: BaseService,private productservice:ProductService,
    public messageService: MessageService,
    public sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.product.getBussinessData().subscribe(res => {
      if (res['status']['statusCode'] == 2000) {
        this.getBusinessData = res['data'];
        localStorage.setItem('bDetailData', JSON.stringify(res['data']));
        this.createTicketForm.patchValue({
          businessId: this.getBusinessData['businessId'],
          businessName: this.getBusinessData['businessName'],
        })
      }
    });
    this.feedbackForm = new FormGroup({
      rating: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    })

    let req = { "supportBusinessKey": "NBOsupport" };
    this.productservice.getSupportToken(req)
      .subscribe(res => {
        if (res['status']['statusMsg'] == 'SUCCESS') {
          localStorage.setItem('supportToken', res['data']['token']);
          this.supportContactNo = res['data']['phone'];
          this.supportMailId = res['data']['email'];
          this.getProduct();
          this.getProject();
          this.getPriority();
        }
      }),
      err => {
        console.log(err)
      }

    this.createTicketForm = new FormGroup({
      businessId: new FormControl('', [Validators.required]),
      businessName: new FormControl('', [Validators.required]),
      subject: new FormControl(''),
      product: new FormControl(''),
      category: new FormControl(''),
      priority: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      file: new FormControl(''),
    });
    this.getTextValues();
  }
  clearAboutForm() {
    this.cancelAboutForm.emit(false);
  }
  clearFeedbackForm() {
    this.cancelFeedbackForm.emit(false);
    this.feedbackForm.reset();
  }
  clearHelpForm() {
    this.cancelHelpForm.emit(false);
  }
  cleartextMsg() {
    this.canceltextMsg.emit(false);
  }
  cleartextMail() {
    this.canceltextMail.emit(false);
  }
 
  clearTicketForm() {
    this.cancelTicketForm.emit(false);
    this.createTicketForm.reset();
    this.createTicketForm.patchValue({
      businessId: this.getBusinessData['businessId'],
      businessName: this.getBusinessData['businessName'],
    })
  }
  submitFeedback(formValue) {
    this.ngxLoader.start();
    let ratingName;
    switch (formValue.rating) {
      case 1:
        ratingName = "Poor";
        break;
      case 2:
        ratingName = "Average";
        break;
      case 3:
        ratingName = "Good";
        break;
      case 4:
        ratingName = "Very Good";
        break;
      case 5:
        ratingName = "Excellent";
        break;

      default:
        break;
    }
    let req = {
      "businessId": this.getBusinessData.businessId,
      "businessName": this.getBusinessData.businessName,
      "userEmail": this.getBusinessData.userEmail,
      "userName": this.getBusinessData.userName,

      "feedBackRating": formValue.rating,
      "feedBackRatingName": ratingName,
      "feedBackComments": formValue.comment
    }
    this.productservice.submitFeedback(req)
      .subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'Feedback submitted successfully' });
        this.clearFeedbackForm();
        this.ratingName = "";
        this.ngxLoader.stop();
      }),
      err => {
        console.log(err);
        this.ngxLoader.stop();
      }
  }
  hoveringOver(value: number): void {
    // console.log(value);
    switch (value) {
      case 1:
        this.ratingName = "Poor";
        break;
      case 2:
        this.ratingName = "Average";
        break;
      case 3:
        this.ratingName = "Good";
        break;
      case 4:
        this.ratingName = "Very Good";
        break;
      case 5:
        this.ratingName = "Excellent";
        break;

      default:
        this.ratingName = "";
        break;
    }
  }
  resetStar(): void {
    // console.log();
    if (!this.feedbackForm.value.rating) {
      this.ratingName = "";
    }
    else {
      this.hoveringOver(this.feedbackForm.value.rating);
    }
  }

  getTextValues() {
    this.ngxLoader.start();
    let faqList = [];
    this.product.getHelpData('FAQ').subscribe(response => {
      faqList = response['responseData'];
      this.faqResponse = faqList;
      this.faqResponseNew = faqList;
      this.CopyfaqResponse = faqList;
    });
    let videoList = [];
    this.product.getHelpData('Video').subscribe(response => {
      console.log(response)
      videoList = response['responseData'];
      this.videoResponse = videoList;
      this.videoResponseNew = videoList;
      this.CopyvideoResponse = videoList;
    });
    let indexList: any;
    console.log(sessionStorage.getItem('indexList'))
    if (sessionStorage.getItem('indexList') == null) {
      this.product.getHelpData('Index').subscribe(response => {
        console.log(response)
        indexList = response['responseData'];
        this.guideResponse = indexList;
        this.CopyguideResponse = indexList;
      });
    }
    else {
      this.guideResponse = JSON.parse(sessionStorage.getItem('indexList'));
      this.CopyguideResponse = JSON.parse(sessionStorage.getItem('indexList'));
      for (let i = 0; i < this.guideResponse.length; i++) {
        this.guideResponseNew.push(this.guideResponse[i]);
      }
      console.log("guide session====", this.guideResponseNew)
    }
    this.ngxLoader.stop();
  }

  onTabOpen(e) {
    this.index = e.index;
  }

  openDetails(index, Newjson) {

    if (this.highlightCheck == Newjson) {
      this.highlightCheck = "";
      this.showDetails = -1;
    } else {
      this.highlightCheck = Newjson;
      this.showDetails = index;
    }
    console.log(index + Newjson);
  }

  openDetailsHelpItemHeading(index, heading, service) {
    if (this.highlightCheckHelpHeading == heading) {
      this.highlightCheckHelpHeading = "";
      this.showDetailsHelpHeading = -1;
    } else {

      this.highlightCheckHelpHeading = heading;
      this.showDetailsHelpHeading = index;
    }
    console.log(this.showDetailsHelpHeading);
    console.log(index + heading);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openDetailsHelpItemHeadingNew(index, subheading, newvalue) {

    if (this.highlightCheckHelpHeadingNew == subheading) {
      this.highlightCheckHelpHeadingNew = "";
      this.showDetailsHelpHeadingNew = -1;
    } else {

      this.highlightCheckHelpHeadingNew = subheading;
      this.showDetailsHelpHeadingNew = index;
    }
    console.log(this.showDetailsHelpHeadingNew);
    console.log(index + subheading);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openDetailsHelpItemFinal(json, Newjson) {

    if (this.highlightCheckHelpFinal == Newjson) {
      this.highlightCheckHelpFinal = "";
      this.showDetailsHelpHeadingFinal = -1;
    } else {

      this.highlightCheckHelpFinal = Newjson;
      this.showDetailsHelpHeadingFinal = json;
    }
    console.log(this.showDetailsHelpHeadingFinal);
    console.log(json + Newjson);
    this.checkValueHelp = !this.checkValueHelp;
  }

  openFormDetails(json, name, Pnaame, imgArray) {
    console.log(JSON.stringify(json));
    this.detailformData = json;
    this.detailformDataImg = imgArray;
    this.moduleNameData = name;
    this.textNameData = Pnaame;
    this.displaydetailHelp = true;
  }

  CancelForm() {
    this.displaydetailHelp = false;
  }

  openDetailsHelpItem(json, Newjson, newvalue) {
    if (this.highlightCheckHelp == Newjson) {
      this.highlightCheckHelp = "";
      this.showDetailsHelp = -1;
    } else {

      this.highlightCheckHelp = Newjson;
      this.showDetailsHelp = json;
      this.newValueCheck = newvalue;
    }
    console.log(this.showDetailsHelp);
    console.log(json + Newjson);
    this.checkValueHelp = !this.checkValueHelp;
  }

  searchValues(text) {

    console.log(text.length);
    if (text.length > 0) {
      console.log("sssssssssssss")
      this.textvalueText = true;
      this.textvalueTextNew = false;
    } else {
      this.textvalueTextNew = true;
      this.textvalueText = false;
      console.log("dddddddddddddddddddd")
      this.inputText = "string";
      this.arrayNotEmptyNew = true
      this.guideResponseNew = this.CopyguideResponse;
      this.videoResponseNew = this.CopyvideoResponse;
      this.faqResponseNew = this.CopyfaqResponse;
      return;
    }


    this.newArrayHelp = [];
    this.faqResponseNew = [];
    this.videoResponseNew = [];
    this.guideResponseNewData = [];


    for (let i = 0; i < this.CopyfaqResponse.length; i++) {
      if (this.CopyfaqResponse[i].faqs) {
        for (let j = 0; j < this.CopyfaqResponse[i].faqs.length; j++) {
          // if(this.faqResponse[i].faqItem[j].Searchkeyword.toLowerCase() == text.toLowerCase()){
          if (this.CopyfaqResponse[i].faqs[j].keyword.toLowerCase().includes(text.toLowerCase())) {
            this.faqResponseNew.push((this.CopyfaqResponse[i].faqs[j]));
            // this.faqResponse.push((this.faqResponse[i].faqItem[j]));
          }
        }
      }
    }

    console.log(JSON.stringify(this.faqResponseNew));

    //video search
    for (let i = 0; i < this.CopyvideoResponse.length; i++) {
      if (this.CopyvideoResponse[i].videos) {
        for (let j = 0; j < this.CopyvideoResponse[i].videos.length; j++) {

          console.log(this.newArrayHelp);
          console.log(this.CopyvideoResponse[i].videos[j]);
          console.log(this.CopyvideoResponse[i].videos[j].keyword.toLowerCase());
          // if(this.faqResponse[i].faqItem[j].keyword.toLowerCase() == text.toLowerCase()){
          if (this.CopyvideoResponse[i].videos[j].keyword.toLowerCase().includes(text.toLowerCase())) {
            console.log("inside video");
            // this.newArrayHelp.push((this.videoResponse[i].videoItem[j]));
            this.videoResponseNew.push((this.CopyvideoResponse[i].videos[j]));
          }
        }
      }
    }

    //items search
    for (let i = 0; i < this.CopyguideResponse.length; i++) {
      if (this.CopyguideResponse[i].indexData) {
        for (let j = 0; j < this.CopyguideResponse[i].indexData.headings.length; j++) {
          console.log(JSON.stringify(this.CopyguideResponse[i]));

          // for(let k=0 ;k<this.CopyguideResponse[i].heading[j].headingName.length;k++){


          for (let l = 0; l < this.CopyguideResponse[i].indexData.headings[j].subHeadings.length; l++) {

            console.log(JSON.stringify(this.CopyguideResponse[i].indexData.headings[j]));

            for (let m = 0; m < this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics.length; m++) {
              // if(this.guideResponse[i].guideItem[j].Searchkeyword.toLowerCase() == text.toLowerCase()){

              console.log(this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m].keyword)
              if (this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m].keyword.toLowerCase().includes(text.toLowerCase())) {
                this.guideResponseNewData.push((this.CopyguideResponse[i].indexData.headings[j].subHeadings[l].helpTopics[m]));
              }
              // }
            }
          }
        }
      }
    }
    console.log(JSON.stringify(this.guideResponseNewData));


    this.guideResponseLengthNewData = this.guideResponseNewData.length;

    if (this.guideResponseLengthNewData > 0) {
      this.guideResponseLengthNewData = this.guideResponseNewData.length;
    } else {
      this.guideResponseLengthNewData = 0;
    }

    console.log(JSON.stringify(this.videoResponseNew));
    console.log(JSON.stringify(this.videoResponseNew.length));
    console.log(JSON.stringify(this.newArrayHelp));
    if (text.length) {
      this.sameLenghtfaq = true;

    } else {
      this.sameLenghtfaq = false;
    }

    if (this.faqResponseNew.length > 0) {
      console.log("aaaaaaaaaaaaaaaaaaaaa")
      this.inputText = "none";
      this.arrayNotEmpty = true;
    } else {
      console.log("ffffffffffffffff")
      this.inputText = "string";
      this.arrayNotEmptyNew = true

    }
    console.log(this.textvalueText);
  }

  openVideo(link, name) {
    console.log(link);
    this.unsafeLink = link;
    this.videoLink = this.transform(link);
    let envCheck = environment.mainurl.includes('northstar-app.straviso.net')
    let req = {
      'userEmail': this.getBusinessData.userEmail,
      'userName': this.getBusinessData.userName,
      "business_name": envCheck ? "Northstar" : 'Nsfarm'
    }
    this.videoLink.changingThisBreaksApplicationSecurity += '?getBusinessData=' + JSON.stringify(req);
    // this.videoLink = this.transform(this.videoLink);
    this.videoNameData = name;
    this.videoModalHelp = true;
  }

  transform(url) {
    url = url.replace(/<[^>]*>/g, '');
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  downloadQuickRefPdf() {
    var link = document.createElement('a');
    link.href = this.fileName;
    link.target = '_blank';
    link.click();
  }

  checkClose() {
    this.cancelHelpForm.emit(false);
    this.helpText = "";
    this.textvalueText = false;
  }
  onInputChange(event, backspace) {
    // debugger;
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }
  getProduct() {
    this.productservice.getAllProductList()
      .subscribe(res => {
        this.products = [];
        // let temp1: any = {
        //   "name": "Select Product",
        //   "code": "Null"
        // }
        // this.products.push(temp1);
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.productServiceName,
            "code": ele.productServiceId
          };
          this.products.push(temp);
        });
        // localStorage.setItem("productList", JSON.stringify(this.product));
      },
        error => {
          // this.ngxLoader.stop();
        });
  }

  getProject() {
    this.productservice.getAllProjectList()
      .subscribe(res => {

        this.projects = [];
        // let temp1: any = {
        //   "name": "Select Category",
        //   "code": "Null"
        // }
        // this.projects.push(temp1);
        
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.categoryName,
            "code": ele.categoryId
          };
          this.projects.push(temp);
        });
        // localStorage.setItem("projectList", JSON.stringify(this.projects));
      },
        error => {
          // this.ngxLoader.stop();
        });
  }

  getPriority() {
    this.productservice.getAllPriority()
      .subscribe(res => {
        this.priority = [];
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.priorityName,
            "code": ele.priorityId,
            //  "resolvedWithin": ele.resolvedWithin
          };
          this.priority.push(temp);
        });
      },
        error => {
          // this..stop();
        });
  }

  createTicket(data) {
    this.ngxLoader.start();

    let req1 = { "supportBusinessKey": "NBOsupport" };
    this.productservice.getSupportToken(req1)
      .subscribe(res => {
        if (res['status']['statusMsg'] == 'SUCCESS') {
          localStorage.setItem('supportToken', res['data']['token']);
          const formData = new FormData();
          let req = {
            "ticketName": data.subject,
            "ticketDescription": data.description,
            "createdBy": this.getBusinessData.userName,
            "createdById": null,
            "createdByName": this.getBusinessData.userName,
            "userName": null,
            "categoryId": data.category ? data.category.code : "",
            "productId": data.product ? data.product.code : "",
            "productName": data.product ? data.product.name : "",
            "categoryName": data.category ? data.category.name : "",
            "userId": null,
            "dueDate": null,
            "priorityId": data.priority ? data.priority.code : "",
            "priorityName": data.priority ? data.priority.name : "",
            "ticketType": "Support",
            "internalUser": false,
            "customerType": "Business",
            "statusName": "New",
            "customerDetails": {
              "customerId": this.getBusinessData.businessId,
              "firstName": this.getBusinessData.businessName,
              "lastName": "",
              "email": this.getBusinessData.userEmail,
              "mobileNo": this.getBusinessData.userContact,
              "customerType": "Business"
            }
          }
          formData.append("ticketDetails", JSON.stringify(req));
          if (this.files.length) {
            for (let file of this.files) {
              formData.append("file", file);
            }
          }
          else {
            this.files = [];
            formData.append("file", this.files);
          }
          // formData.append("file", data.file);
          this.productservice.createTicket(formData)
            .subscribe(res => {
              this.ngxLoader.stop();
              if (res.result == "Success") {
                this.clearTicketForm();
                // this.createTicketSidebar = false;
                // this.createTicketForm.reset();
                this.messageService.add({ severity: 'success', summary: "ID - " + res['responseData'] + ' Ticket Created Successfully' });
              }
            }),
            err => {
              console.log(err);
              this.ngxLoader.stop();
            }
        }
      }),
      err => {
        console.log(err)
      }
  }
  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      // this.files = event.target.files;
      for (let index = 0; index < event.target.files.length; index++) {
        let temp = event.target.files[index].name.split(".");
        let temptype = temp[temp.length - 1];

        event.target.files[index].customType = temptype;
        let b = event.target.files[index].size <= 1024 * 1024 * 5; //5242880 bytes = 5MB
        if (b == false) {
          this.fileSize = true;
          console.log("if");
        } else {
          this.files = [];
          this.files.push(event.target.files[index]);
          console.log("else");
        }
      }

    }

  }
}

import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Compiler, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Message, MessageService } from 'primeng/api';
import { BaseService } from '../services/base.service';
import { SignupService } from '../services/signup.service';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MessageService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})
export class ApprovalComponent implements OnInit {

  mail: any;
  userVerificationData: any = [];
  msgs: Message[] = [];
  isConfirmed: boolean = false;
  token: any;
  businessId: any;
  approvedBusinessData: any;
  approveQuery: string;
  position: 'center';
  isExpired: boolean = false;
  isError: boolean = false;
  errorMessage: any;
  isRejected: boolean = false;
  isApproved: boolean = false;
  isRegenaratedLink: boolean = false;
  constructor(public activatedRoute: ActivatedRoute,
    private signupService: SignupService,
    private router: Router,
    public messageService: MessageService,
    private baseService: BaseService,
    public loader: NgxUiLoaderService,
    private location: Location,
    private _compiler: Compiler,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this._compiler.clearCache();
    this.signupService.loggedIn.next(false);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.token) {
        this.loader.start();
        this.approveQuery = params.token;
        const temp = params.token.split('=>');
        localStorage.setItem('mail', temp[temp.length - 2]);
        localStorage.setItem('businessId', temp[temp.length - 1]);
        localStorage.setItem('userData', JSON.stringify({ accessToken: params.authtoken }));
        this.mail = localStorage.getItem('mail');
        this.token = temp[0];
        this.businessId = temp[temp.length - 1];
        this.getBusinessUserData();
      }
      else{
        this.isError = true;
        this.errorMessage = "Token not found. Please click on the link again."
      }
      this.location.replaceState('#/approve');
    });
  }

  approveBusiness() {
    this.loader.start();
    this.signupService.approveBusiness(this.token, this.mail, this.businessId).subscribe(res => {
      if (res?.responseMessage != "Business Account Approved" && !res?.responseData) {
        this.loader.stop();
        if (res?.responseMessage == 'token Invalid') {
          this.isExpired = true;
        }
        else {
          this.messageService.add({ severity: 'warn', summary: res?.responseMessage, life: 2000 });
            localStorage.clear();
            sessionStorage.clear();
        }
      }
      else {
        this.loader.stop();
        this.isConfirmed = true;
        this.isApproved = true;
        this.messageService.add({ severity: 'success', summary: "Business has been successfully approved", life: 2000 });
        localStorage.clear();
        sessionStorage.clear();
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: "Something went wrong. Please try again later.", life: 2000 });
        this.loader.stop();
        localStorage.clear();
        sessionStorage.clear();
    })
  }

  onCancel() {
    this.router.navigate(['home']);
  }

  getBusinessUserData() {
    this.signupService.getBusinessUserData(this.businessId, this.mail).subscribe(res => {
      this.approvedBusinessData = res?.responseData;
      if (res?.message != "Success") {
        //this.messageService.add({ severity: 'warn', summary: res?.message, life: 2000 });
        this.isError = true;
        if (res?.message == 'Business Already Rejected') {
          this.isRejected = true;
          this.errorMessage = "Business already rejected";
        }
        else if (res?.message == 'Business Already Approved' || res?.message == 'Business Not Found') {
          this.isApproved = true;
          this.errorMessage = "Business already approved";
        }
        else{
          this.errorMessage = res?.message;
        }
        this.loader.stop();
        localStorage.clear();
        sessionStorage.clear();
      }
      else {
        this.loader.stop();
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: "Something went wrong. Please try again later.", life: 2000 });
        localStorage.clear();
        sessionStorage.clear();
    })
  }

  regenerateLink() {
    this.loader.start();
    this.signupService.regenerateApproveLink(this.businessId).subscribe(res => {
      this.isExpired = false;
      this.isRegenaratedLink = true;
      this.loader.stop();
      this.messageService.add({ severity: 'success', summary: "Link has been re-generated.", life: 2000 });
        localStorage.clear();
        sessionStorage.clear();
    }, error => {
      this.messageService.add({ severity: 'error', summary: "Something went wrong. Please try again later.", life: 2000 });
        localStorage.clear();
        sessionStorage.clear();
    })
  }

}

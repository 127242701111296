import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceService {
  ipAddress: any;
  deviceInfo: any;
  shareDeviceDetails: Observable<any>;
  private userDevice = new BehaviorSubject([]);

  constructor(private deviceService: DeviceDetectorService,
    private http: HttpClient) {
      this.shareDeviceDetails = this.userDevice.asObservable();
   }

   getIPAddress(){
    return this.http.get('https://api.ipify.org/?format=json',{ headers: null });
  }

  storeIP(){
    this.getIPAddress().subscribe((res: any)=>{
      this.ipAddress=res.ip;
      this.getDeviceDetails();
    },
    err=> {
      this.getDeviceDetails();
    });
  }

  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.deviceInfo.device = isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktopDevice ? 'Desktop' : '';
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  getDeviceDetails(){
    const userDevice: any = {
      IP : this.ipAddress,
      deviceInfo : this.deviceInfo
    };
    this.userDevice.next(userDevice);
  }
}

import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from '../services/base.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SignupService } from '../services/signup.service';
// declare let JSEncrypt: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [MessageService]
})
export class ChangePasswordComponent implements OnInit {
  changePassForm: FormGroup;
  submitted = false;
  storedEmail = '';
  lastPath = '';
  fieldTextType1: any;
  fieldTextType: any;
  secreteKey: any;
  constructor(private fb: FormBuilder,
    private base: BaseService,
    private messageService: MessageService,
    private router: Router,
    public loader: NgxUiLoaderService,
    private signupService: SignupService,
    private ngZone: NgZone) { }

  ngOnInit() {
    this.loader.start();
    this.signupService.loggedIn.next(false);
    const data = JSON.parse(localStorage.getItem('userData'));
    this.storedEmail = data.emailId;
    this.changePassForm = this.fb.group({
      email: [this.storedEmail, [Validators.required, Validators.email]],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required,
      Validators.minLength(8),
      Validators.pattern( /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]]
    });
    // this.getSecretKey()
    this.loader.stop();
    this.lastPath = sessionStorage.getItem('lastUrl');
  }

  get f() {
    return this.changePassForm.controls;
  }

  btnDisabled = false;
  onSubmit() {
    this.submitted = true;
    if (this.changePassForm.invalid) {
      return;
    }
    // code for encryption
    // let encrypt = new JSEncrypt();
    // const publickey = this.secreteKey;
    // let decryptDataRow = this.changePassForm.value['newPassword'];
    // encrypt.setPublicKey(publickey);
    // let encryptedData = encrypt.encrypt(decryptDataRow);
    // this.changePassForm.value['newPassword'] = encryptedData;
    // decryptDataRow = this.changePassForm.value['oldPassword'];
    // let encryptedData = encrypt.encrypt(decryptDataRow);
    // this.changePassForm.value['oldPassword'] = encryptedData;
    const req = {
      newPassword: this.changePassForm.value.newPassword,
      oldPassword: this.changePassForm.value.oldPassword,
      userEmail: this.changePassForm.value.email,
      businessId: localStorage.getItem('businessId')
      // isRequestFromNS : true
    };
    this.loader.start();
    this.base.postData('changePassword', req).subscribe(
      (res: any) => {
        //console.log(res);
        if (res && res.status.statusCode === 2000) {

          if (res.status.statusMsg === 'Success') {
            this.btnDisabled = true;
            this.loader.stop();
            this.messageService.add({ severity: 'success', summary: 'Password updated successfully', life: 2000 });
            setTimeout(() => {
              this.goBack();
            }, 2000);
            // localStorage.clear();
            // this.router.navigateByUrl('/');
            // //console.log('CURRENT URL '+this.router.url);
            // this.lastPath = sessionStorage.getItem('lastUrl');
            // this.changePassForm.reset();
            // this.ngZone.runOutsideAngular(() => {
            //   setTimeout(() => {
            //     this.router.navigateByUrl(`/${this.lastPath}`);
            //     sessionStorage.removeItem('lastUrl');
            //     this.loader.stop();
            //   }, 2001);
            // });
            // 
          } else {
            this.loader.stop();
            this.messageService.add({ severity: 'warn', summary: `${res.status.statusMsg}`, life: 2000 });
          }

        }
      }, err => {
        //console.log(err);
        this.messageService.add({ severity: 'warn', summary: `Error`, life: 2000 });
        this.changePassForm.reset();
        this.loader.stop();
      });
  }

  goBack() {
    this.loader.start();
    this.signupService.loggedIn.next(true);
    this.router.navigateByUrl(this.lastPath);
    setTimeout(() => {
      window.location.reload();
    }, 100);
    
    // sessionStorage.removeItem('lastUrl');
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  getSecretKey() {
    this.signupService.getSecretKey().subscribe(
      (res: any) => {
        if (res.message === 'success') {
          this.secreteKey = res?.data;
          // console.log("SecretKey", this.secreteKey);
        }
      },
      err => {
        this.messageService.add({
          severity: 'warn', sticky: true,
          summary: 'Something went wrong. Please contact NBOSupport for further assistance.'
        });
      }
    );
  }
}

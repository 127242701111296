import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { SignupService } from '../services/signup.service';
import { MessageService } from 'primeng/api';
import { BaseService } from '../services/base.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Compiler } from '@angular/core';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
  providers: [
    MessageService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})

export class OtpVerificationComponent implements OnInit {
  verifyForm: FormGroup;
  mail: any = '';
  invitedEmail = '';
  invitedMode = '';
  UISentmailFlag = false;
  alreadyRegistred = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    private signupService: SignupService,
    private router: Router,
    public messageService: MessageService,
    private baseService: BaseService,
    public loader: NgxUiLoaderService,
    private location: Location,
    private _compiler: Compiler
  ) { }

  ngOnInit() {
    this._compiler.clearCache();
    // this.loader.start();
    this.signupService.loggedIn.next(false);
    this.verifyForm = new FormGroup({
      otp: new FormControl('', Validators.required)
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.token) {
        localStorage.setItem('auth_token', null);
        const temp = params.token.split('=>');
        localStorage.setItem('mail', temp[temp.length - 2]);
        localStorage.setItem('businessId', temp[temp.length - 1]);
        localStorage.setItem('userData', JSON.stringify({ accessToken: params.authtoken }));
        this.mail = localStorage.getItem('mail');
        this.signupService.OTPRequest(params.token)
          .subscribe((res: any) => {
            this.loader.stop();
            if (res.status.statusMsg === 'Success') {
              this.messageService.addAll([
                { severity: 'success', sticky: true, summary: 'OTP Successfully send to Your ' + this.mail + ' Mail ID' },
                { severity: 'success', sticky: true,
                summary: 'Please check your Spam/Junk folder if you do not receive the message in your inbox'}
              ]);
              // this.router.navigateByUrl("/verify");
            }

            else {
              //console.log("inside failed Otp");
              this.messageService.add({ severity: 'error', summary: res.status.statusMsg });
            }
          },
            error => {
              this.loader.stop();
              this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg });
            });
      }
      else {
        localStorage.setItem('auth_token', params.authtoken);
        this.invitedEmail = params.email;
        this.invitedMode = params.mode;
        sessionStorage.setItem('mode', this.invitedMode);
        this.mail = params.email;
        localStorage.setItem('businessId', params.businessId);
        this.callToOTPUI();
      }
      this.location.replaceState('#/verify');

    });

    // if (this.ActivatedRoute.snapshot.queryParams && this.ActivatedRoute.snapshot.queryParams['token']) {
    //   localStorage.setItem('auth_token', this.ActivatedRoute.snapshot.queryParams['token']);
    //   let temp = this.ActivatedRoute.snapshot.queryParams['token'].split("_");
    //   localStorage.setItem('mail', temp[temp.length - 1]);
    //   localStorage.setItem("userData", JSON.stringify({ 'accessToken': this.ActivatedRoute.snapshot.queryParams['authtoken'] }))
    // }

    // if (!localStorage.getItem('auth_token')) {
    //   this.mail = localStorage.getItem("mail");
    //   this.signupService.OTPRequest(localStorage.getItem('auth_token'))
    //     .subscribe((res: any) => {
    //       this.loader.stop();
    //       if (res.status['statusCode'] === 2000) {
    //         this.messageService.add({ severity: 'success', summary: 'OTP Successfully send to Your ' + this.mail + ' Mail ID' });
    //         this.router.navigateByUrl("/verify");
    //       }
    //       else {
    //         //console.log("inside failed Otp");
    //         this.messageService.add({ severity: 'error', summary: 'Error occure while sending OTP !' });
    //       }
    //     },
    //       error => {
    //         this.loader.stop();
    //         this.messageService.add({ severity: 'error', summary: error.error.status['statusMsg'] });
    //       })
    // }

    // this.verifyInviteOtp();

  }

  verifyOTP(formValue) {
    //console.log("OTP", formValue);

    const req = {
      OTP: formValue.otp,
      userEmail: localStorage.getItem('mail')
    };

    // at the time of invite member.
    if (this.invitedEmail && this.invitedMode === 'UI') {
      this.baseService.getData(`verifyOTP?OTP=${formValue.otp}&userEmail=${this.invitedEmail}&businessId=${localStorage.getItem('businessId')}`)
        .subscribe((result: any) => {
          //console.log(result);
          this._compiler.clearCache();
          if (result?.status.statusMsg === 'Success') {
            localStorage.setItem('verify', 'true');
            localStorage.setItem('mail', this.invitedEmail);
            this.messageService.add({ severity: 'success', summary: 'OTP Verify Successfully' });
            this.router.navigateByUrl('/home');
          }
          else if (result?.status.statusMsg === 'OTP Expires') {
            this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
          }
          else if (result?.status.statusMsg === 'OTP Resend Limit Reached') {
            this.messageService.add({ key: 'op', sticky: true, severity: 'warn' });
          }
          else {
            this.messageService.add({ severity: 'warn', summary: result?.status.statusMsg });
          }
        }, err => {
          this._compiler.clearCache();
          //console.log(err);
          this.messageService.add({ severity: 'error', summary: 'OTP verification failed' });
        });
    } else {
      // sign up service.
      this.signupService.verifyOTP(formValue.otp, localStorage.getItem('mail'),localStorage.getItem('businessId'))
        .subscribe((res: any) => {
          if (res.status.statusMsg === 'Success') {
            this.messageService.add({ severity: 'success', summary: 'OTP Verify Successfully' });
            localStorage.setItem('verify', 'true');
            this.router.navigateByUrl('/home');
          }
          else if (res.status.statusMsg === 'OTP verification Fail') {
            this.messageService.add({ severity: 'warn', summary: res.status.statusMsg });
          }
          else if (res.status.statusMsg === 'OTP Resend Limit Reached') {
            this.messageService.add({ key: 'op', sticky: true, severity: 'warn' });
          }
          else {
            this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
          }
        },
          error => {
            this.messageService.add({ severity: 'error', summary: 'OTP Verification Failed' });
          });
    }
  }

  onConfirm() {
    this.messageService.clear('op');
  }

  resendOTP() {
    let mail = localStorage.getItem('mail');
    if (this.invitedEmail && this.invitedMode === 'UI') {
      mail = localStorage.getItem('inviteEmail');
      //console.log('Invited mail', mail)
    }
    this.baseService.getData(`getOtpForInviteUser?email=${this.mail}&businessId=${localStorage.getItem('businessId')}`)
      .subscribe((res: any) => {
        if (res.status.statusMsg === 'Success') {
          // this.messageService.add({ severity: 'success', summary: 'OTP sent Successfully' });
          this.messageService.addAll([
            { severity: 'success', sticky: true, summary: 'OTP sent Successfully' },
            { severity: 'success', sticky: true,
            summary: 'Please check your Spam/Junk folder if you do not receive the  message in your inbox'}
          ]);
        }
        else if (res.status.statusMsg === 'OTP Resend Limit Reached') {
          this.messageService.add({ key: 'op', sticky: true, severity: 'warn' });
        }
        else {
          this.messageService.add({ severity: 'error', summary: res.status.statusMsg });
        }
      },
        error => {
          this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg });
        });
  }

  verifyInviteOtp() {
    this.loader.stop();
    //console.log('check verify call');
    // this.baseService.getData(`getOtpForInviteUser`);
    this.activatedRoute.queryParams.subscribe(params => {
      this.invitedEmail = params.email;
      this.invitedMode = params.mode;
      //console.log(this.invitedEmail + '-- ' + this.invitedMode);
      this.callToOTPUI();

    });
  }

  callToOTPUI() {
    if (this.invitedEmail && this.invitedMode === 'UI') {
      this.baseService.getData(`getOtpForInviteUser?email=${this.invitedEmail}&businessId=${localStorage.getItem('businessId')}`)
        .subscribe((result: any) => {
          this.loader.stop();
          //console.log(result);
          if (result?.status.statusMsg === 'Success') {
            //console.log(result['data']);
            // this.UISentmailFlag = true;

            this.messageService.addAll([
              { severity: 'success', sticky: true, summary: 'Mail sent successfully' },
              { severity: 'success', sticky: true,
              summary: 'Please check your Spam/Junk folder if you do not receive the  message in your inbox'}
            ]);
            // setTimeout(() => {
            //   this.UISentmailFlag = false;
            // }, 2000);
          }
          else {
            console.log('Error Verify', result);
            this.messageService.add({ severity: 'error', summary: result?.status.statusMsg });
          }
        },
          err => {
            console.log('Error Verify', err.status);
            this.messageService.add({ severity: 'error', summary: err.error.status.statusMsg });
          });
    }
  }

  // test () {
  //   this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
  // }

}

import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDigitonly]'
})
export class DigitonlyDirective {

  @Input('decimals') decimals = 0;
  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
      if (this.specialKeys.indexOf(event.key) !== -1) {
          return;
      }
      // Do not use event.keycode this is deprecated.
      // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      const current: string = this.el.nativeElement.value;
      const next: string = current.concat(event.key);
      if ( next && !this.check(next, this.decimals) ) {
         event.preventDefault();
      }
  }

  private check(value: string, decimalsInput: number)
  {
    if (decimalsInput <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
        const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimalsInput + '})?)|((\\d*(\\.\\d{1,' + decimalsInput + '}))))\\s*$';
        return String(value).match(new RegExp(regExpString));
    }
  }

}

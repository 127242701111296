import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stickynav',
  templateUrl: './stickynav.component.html',
  styleUrls: ['./stickynav.component.scss']
})
export class StickynavComponent implements OnInit {
  contactusUrl: any;
  constructor() { }

  ngOnInit() {
    this.contactusUrl = environment.contactusUrl;
  }

  openContactPage() {
    window.open(environment.contactusUrl);
  }

}

import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { LazyLoadEvent, MessageService } from "primeng/api";
import { DataExtractService } from "../services/data-extract.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DataSharingService } from "../services/data-sharing.service";
import * as moment from "moment";
import { Table } from "primeng/table";
import { environment } from "src/environments/environment";
import { FilterUtils } from 'primeng/utils';

@Component({
  selector: "app-dataexport",
  templateUrl: "./dataexport.component.html",
  styleUrls: ["./dataexport.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DataexportComponent implements OnInit {
  @ViewChild("dt") private table: Table;
  @ViewChild("viewDt") private viewTable: Table;
  @ViewChild("searchValueClear") inputName;
  cols: any = [];
  cols1: any = [];
  modalStyle: string = 'none';
  viewFileData: any[];
  backupFileData: any[] = [];
  nextOffset: number;
  selectedFileSize: number;
  fileHeaders: any[];
  fileCols: any[] = [];
  fileFilterValues: any = {};
  viewFileModal: boolean = false;
  rows: number = 100;
  loading: boolean = false;
  filterFlag: boolean = false;
  filter: any = {};
  currentPayload: any = {};
  tableRef: string;
  breadCrumb: any;
  exportedData: any;
  noOfRows = 15;
  selecteddataExtractColumns: any;
  exportDataList: any = [];
  messageCodeData: any = [];
  searchText: any = "";
  dt00 ="";
  dt01 = "";
  dt02 = "";
  dt03 = "";
  dt04 = "";
  dt05 = "";
  tablerecordid: any = [];
  tablecomponentName: any = [];
  tablereportName: any = [];
  tabletriggerDate: any = [];
  tablesize: any = [];
  tablevalidTill: any = [];
  addPartConfigForm: FormGroup;
  lastUpdated: any;
  addMessageCodeForm: FormGroup;
  deleteReportModal: boolean=false;
  DataReportDisplay: any = [];
  storedEmail: any;
  inProgressStatus: any;
  deleteId: any;
  deleteRecord: any;
  dummy: any = [];
  prevReq: any;
  constructor(
    public messageService: MessageService,
    private dataExtractService: DataExtractService,
    private router: Router,
    private datePipe: DatePipe,
    public loader: NgxUiLoaderService,
    public datasharing: DataSharingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.datasharing.urlChange(this.router.url);
    this.breadCrumb = [
      {
        label: "Home",
        url: environment.northstarproductUrl + "business-dashboard",
      },
    ];
    localStorage.setItem("breadCrumb", JSON.stringify(this.breadCrumb));
    this.lastUpdated = moment(new Date()).format("MM/DD/YYYY, h:mm A");

    const data = JSON.parse(localStorage.getItem("userData"));
    this.storedEmail = data.emailId;
    this.getExportViewData();
    this.cols = [
      {
        field: "id",
        header: "Id",
        class: "recordid",
        isHighlighted: false,
      },
      {
        field: "componentName",
        header: "Component",
        class: "componentName",
        isHighlighted: false,
      },
      {
        field: "fileName",
        header: "Report Name",
        class: "reportName",
        isHighlighted: false,
      },
      {
        field: "triggerDate",
        header: "Requested Date",
        class: "triggerDate",
        isHighlighted: false,
      },
      {
        field: "validTill",
        header: "Available Till",
        class: "validTill",
        isHighlighted: false,
      },
      { field: "size", header: "Size (KB)", class: "size" },
    ];
    this.selecteddataExtractColumns = this.cols;
    this.exportDataList = [];
    localStorage.removeItem("dataExportBreadCrumb");
    this.updateBreadcrumb();

    let payload = {
      fileName: "triggeredAutomationData_04122022010525",
      userId: "teststar107@northstar.straviso.net",
      reportName: "triggeredAutomationData",
      fileOffset: 0,
      numberOfLines: 200,
    };

    // this.getFileDataStream(payload);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  @Input() get selectedColumns(): any[] {
    return this.selecteddataExtractColumns;
  }
  set selectedColumns(val: any[]) {
    this.selecteddataExtractColumns = this.cols.filter((col) =>
      val.includes(col)
    );
  }

  updateBreadcrumb() {
    this.breadCrumb = JSON.parse(localStorage.getItem("breadCrumb"));
    let ele = {
      label: "Data Export",
      url: environment.dataExportUrl,
    };
    localStorage.setItem("dataExportBreadCrumb", JSON.stringify(ele));
    if (this.breadCrumb.length && this.breadCrumb != null) {
      for (let i = 0; i < this.breadCrumb.length; i++) {
        if (this.breadCrumb[i].label == ele.label) {
          this.breadCrumb.splice(i, this.breadCrumb.length - 1);
        }
      }
      this.breadCrumb.push(ele);
      localStorage.setItem("breadCrumb", JSON.stringify(this.breadCrumb));
    } else {
      this.breadCrumb = [ele];
    }
    this.breadCrumb = [...this.breadCrumb];
  }

  exportToExcelMessage() {
    this.dataExtractService.exportExcel(
      this.messageCodeData,
      "messageCodeData"
    );
  }
  downloadFile(url) {
    if (url != null) {
      var win = window.open(url, "_self");
      win.location;
    }
  }
  cancelDelete(){
    this.deleteReportModal=false;
  }
  deleteFile(data){
    this.deleteReportModal=true;
    this.deleteRecord=data;
    this.deleteId=data.id;
  }

  deleteReportDialog(data){
  const req={
    userId: this.deleteRecord.userId,
    reportName: this.deleteRecord.reportName,
    id: this.deleteRecord.id,
    fileName: this.deleteRecord.fileName
    }
    this.loader.start("1");
    this.dataExtractService.deleteFile(req).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.deleteReportModal=false;
          this.messageService.add({
            severity: "success",
            summary: "Record deleted successfully ",
            life: 2000,
          });
          this.getExportViewData();
          
        }
        this.loader.stop("1");
      })
   
}
  byteConvert(size) {
    return (size / 1000).toFixed(2);
  }
  lastUpdatedRefresh() {
    this.ngOnInit();
  }

  hideFileModal() {
    this.modalStyle = 'none';
    this.filterFlag = false;
    this.viewTable.scrollToVirtualIndex(0);
    this.viewTable.reset();
  }

  resetModalTable() {
    this.viewTable.reset();
    this.fileCols.forEach(col => {
      col.isHighlighted = false;
    });
    this.fileHeaders.forEach((head,i) => {
      this.fileFilterValues[head]='';
    });
    this.viewFileData = this.backupFileData;
    this.filterFlag = false;
  }

  loadTable() {
    this.loading = true;
    setTimeout(() => {
      this.loading=false;
    },2000);
    this.viewTable.resetScrollTop();
  }

  getFileDataStream(data) {
    this.loader.start();
    this.rows = 100;
    let req = {
      fileName: data.fileName,
      userId: data.userId,
      reportName: data.reportName,
      fileOffset: 0,
      numberOfLines: 300
    }
    this.prevReq = Object.assign({}, req);
    let req1 = {
      "fileName":"Auto Assist Opportunities_04262022144730",
      "userId":"jitender.saini@straviso.com",
      "reportName":"Auto Assist Opportunities",
      "fileOffset":25991,
      "numberOfLines":500
    }
    this.dataExtractService.getFileDataStream(req)
    .subscribe(res => {
      let fileData = res['fileDataLines'];
      this.selectedFileSize = fileData.length -1;
      this.nextOffset = res['offSet'];
        let temp = {
          label:'',
          value: '',
        };
        const key = "label";
        this.fileCols = [];
        this.fileHeaders = fileData[0];
        fileData.splice(0,1);
        this.filter = {};
        this.fileHeaders.forEach((head,i) => {
          this.filter[i] = [];
          this.fileFilterValues[head]='';
        })
        fileData.forEach((data) => {
          data.forEach((val,i) => {
              temp = {
                label: val,
                value: val,
              };
              this.filter[i].push(temp);
              this.filter[i] = [
                ...new Map(
                  this.filter[i].map((item) => [item[key], item])
                ).values()
              ];
          })
        });
        this.fileHeaders.map((item,index) => {
          this.fileCols.push({
            field: item,
            header: item,
            class: item,
            filter: this.filter[index],
            isHighlighted: false
          });
        });

        this.viewFileData = fileData.map((file) => {
          return file.reduce((result, field, index) => {
            result[this.fileHeaders[index]] = field;
            return result;
          }, {});
        });
        
        temp = {
          label:'',
          value: '',
        };
        this.backupFileData = this.viewFileData;
        this.viewFileModal = true;
        this.modalStyle = 'block';
        setTimeout(() => {
          this.viewTable.clearCache();
        },3000);
        this.loader.stop();
        // this.renderer.setStyle(document.getElementsByClassName('cdk-virtual-scroll-content-wrapper'),'transform','translateY(0px)');
    },err => {
      console.log(err);
      this.loader.stop();
    });
  }

  loadMoreData(event: LazyLoadEvent) {
    
    let first = 200;
    let req = {
      fileName: this.currentPayload.fileName,
      userId: this.currentPayload.userId,
      reportName: this.currentPayload.reportName,
      fileOffset: this.nextOffset,
      numberOfLines: 300
    }
  
    if(this.nextOffset !== 0 && this.prevReq["fileOffset"]!==this.nextOffset) {
   
     
      if(event.first > first + 100 || event.first == first) {
        this.prevReq = Object.assign({}, req);
        first+=100;
        if(this.filterFlag) {
          this.resetModalTable();
        }
        this.loading = true;
        this.nextOffset=0;
        this.dataExtractService.getFileDataStream(req)
        .subscribe(res => {
       
          let fileData = res['fileDataLines'];
          this.nextOffset = res['offSet'];
          // alert(res['offSet']);
          let temp = {
            label:'',
            value: '',
          };
          const key = "label";
          let filter = {};
          this.fileHeaders.forEach((head,i) => {
            filter[i] = [];
            // this.fileFilterValues[head]='';
          });
          fileData.forEach((data) => {
            data.forEach((val,i) => {
                temp = {
                  label: val,
                  value: val,
                };
                filter[i].push(temp);
                filter[i] = [
                  ...new Map(
                    filter[i].map((item) => [item[key], item])
                  ).values()
                ];
            })
          });
          let viewFile = fileData.map((file) => {
            return file.reduce((result, field, index) => {
              result[this.fileHeaders[index]] = field;
              return result;
            }, {});
          });
          this.viewFileData = this.viewFileData.concat(viewFile);
  
          this.fileHeaders.map((item,index) => {
            this.filter[index] = [...this.filter[index],...filter[index]]
            this.filter[index] = [
              ...new Map(
                this.filter[index].map((item) => [item[key], item])
              ).values()
            ];
            this.fileCols[index]['filter'] =  this.filter[index];
          });
          
          this.backupFileData = this.viewFileData;
          temp = {
            label:'',
            value: '',
          };
          this.loading = false;
        },err => {
          console.log(err);
        });
      }
    }

    
  }

  filterTable(event:any,i:number,colField:string) {
    if (event.value.length !== 0) {
      this.fileCols[i]["isHighlighted"] = true;
    } else {
      this.fileCols[i]["isHighlighted"] = false;
    }
    
    if(Object.keys(this.viewTable.filters).length !== 0) {
      this.filterFlag = true;
      let filterObj = this.viewTable.filters;
      let filterKeys = Object.keys(filterObj);
      if(filterKeys.length > 1) {
        if(event.value.length !== 0) {
          filterKeys.forEach((key,j) => {
            if(filterObj[colField].value.length == 1) {
              this.dummy = this.dummy.filter(data => filterObj[colField].value.includes(data[colField]));
            } else {
              let temp1 = []
              temp1 = this.backupFileData.filter(data => filterObj[colField].value.includes(data[colField]));
              if(key !== colField){
                temp1 = temp1.filter(data => filterObj[filterKeys[j]].value.includes(data[filterKeys[j]]));
                this.dummy = temp1;
              }
            }
          });
        } else {
          let temp =[];
          if(!filterKeys.includes(colField)) {
            temp= this.backupFileData.filter(data => filterObj[filterKeys[0]].value.includes(data[filterKeys[0]]));
            filterKeys.forEach((key,j) => {
              if(key !== filterKeys[0]) {
                this.dummy = temp.filter(data => filterObj[key].value.includes(data[key])); 
              }     
            });
          } else {
            filterKeys.forEach((key,j) => {
              this.dummy = temp.filter(data => filterObj[key].value.includes(data[key]));      
            });
          }    
        }
       } else {
         if(event.value.length !== 0) {
          filterKeys.forEach(key => {
            this.dummy = this.backupFileData.filter(data => filterObj[key].value.includes(data[key]));
          })
         }
         else {
          filterKeys.forEach(key => {
            this.dummy = this.backupFileData.filter(data => filterObj[key].value.includes(data[key]));
          })
         }
       }
      this.viewFileData = this.dummy;
    }
    else {
      this.viewFileData = this.backupFileData;
      this.filterFlag = false;
    }
  }

  getExportViewData() {
    const req = {
      pageNumber: 1,
      pageSize: 15,
      userId: this.storedEmail,
    };
    this.loader.start("1");

    this.dataExtractService.getExportData(req).subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.exportDataList = res?.exportMetaDataList;

          if (this.exportDataList && this.exportDataList.length > 0) {
            this.exportDataList.forEach((element) => {
              const key = "label";
              if (element.id != null) {
                const d = {
                  label: element.id,
                  value: element.id,
                };
                this.tablerecordid.push(d);
                this.tablerecordid = [
                  ...new Map(
                    this.tablerecordid.map((item) => [item[key], item])
                  ).values(),
                ];
              }
              if (element.componentName != null) {
                const d = {
                  label: element.componentName,
                  value: element.componentName,
                };
                this.tablecomponentName.push(d);
                this.tablecomponentName = [
                  ...new Map(
                    this.tablecomponentName.map((item) => [item[key], item])
                  ).values(),
                ];
              }
              if (element.fileName != null) {
                const d = {
                  label: element.fileName,
                  value: element.fileName,
                };
                this.tablereportName.push(d);
                this.tablereportName = [
                  ...new Map(
                    this.tablereportName.map((item) => [item[key], item])
                  ).values(),
                ];
              }
              if (element.triggerDate != null) {
                const d = {
                  label: moment(element.triggerDate).format("MM/DD/YY, HH:mm"),
                  value: element.triggerDate,
                };
                this.tabletriggerDate.push(d);
                this.tabletriggerDate = [
                  ...new Map(
                    this.tabletriggerDate.map((item) => [item[key], item])
                  ).values(),
                ];
              }
              if (element.size != null) {
                const d = {
                  label:
                    element.size == 0
                      ? element.size
                      : (element.size / 1000).toFixed(2),
                  value: element.size,
                };
                this.tablesize.push(d);
                this.tablesize = [
                  ...new Map(
                    this.tablesize.map((item) => [item[key], item])
                  ).values(),
                ];
              }
              if (element.validTill != null) {
                const d = {
                  label: moment(element.validTill).format("MM/DD/YY, HH:mm"),
                  value: element.validTill,
                };
                this.tablevalidTill.push(d);
                this.tablevalidTill = [
                  ...new Map(
                    this.tablevalidTill.map((item) => [item[key], item])
                  ).values(),
                ];
              }
            });
          }
        }
        this.loader.stop("1");
      },
      (err) => {
        this.loader.stop("1");
      }
    );
  }
  generateBtnClick() {
    this.router.navigateByUrl("/dynamicReport");
  }
  clearFilterConfig() {
    this.dt00 ="";
    this.dt01 = "";
    this.dt02 = "";
    this.dt03 = "";
    this.dt04 = "";
    this.dt05 = "";
    this.inputName.nativeElement.value = null;
    this.cols.map((col) => {
      col.isHighlighted = false;
    });
    this.table.reset();
    this.getExportViewData();
  }

  checkClass(e, i) {
    if (e.value.length !== 0) {
      this.cols[i]["isHighlighted"] = true;
    } else {
      this.cols[i]["isHighlighted"] = false;
    }
  }

  checkFileClass(e,i) {
    if (e.value.length !== 0) {
      this.fileCols[i]["isHighlighted"] = true;
    } else {
      this.fileCols[i]["isHighlighted"] = false;
    }
  }
}

<div class="default-theme" *ngIf="(isLoggedIn$ | async) && userData?.businessId &&  userData?.userRole && userData?.businessId != 'SVS000688'">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    
    <!-- help code here -->
    <!-- <app-help [displayhelpcontent]=displayFPS (cancelhelpEmit)="getCancelAddMessage($event)"></app-help> -->

    <!-- Tour code here -->
    <app-tour [displayTourContent]=displayTour (cancelTourEmit)="getCancelTourMessage($event)"></app-tour>

    <sv-nsportalheader *ngIf="isLoggedIn$ | async" [bussinessLogo]="bussinessLogo" [businessLogoObj]="headerLogo"
        [userData]="userData" [nineDots]="nineDotsArray" [bussinessData]="bussinessData" [userProfile]="userProfile"
        (onOpenHelpAndSupport)="getHelpandSupport($event)" (onChangeProfilePicture)="onProfilePicChange($event)"
        (onRemoveProfilePicture)="removeProfile()" (onChangePassword)="changePassword()" (onLogOut)="logOut()" [profileMenu]="''" 
        [CurrentComponentTitle]="CurrentComponent" [blueRedirectURL]="blueURL">
    </sv-nsportalheader>

    <div class="date-item qLink" *ngIf="subscribeServices != null ">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" *ngIf="quickLinkAccessMenu.length > 0"
            (click)="op2.toggle($event)" alt="quickLink">
        <span *ngIf="productAccessMenu.length > 0" (click)="openFPFormHelp('help','right')" pTooltip="Help"
            style="float: right;" tooltipPosition="bottom">
            <i class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
              <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.businessId != 'SVS000688' && userData?.userRole?.includes('Partner') == false -->
    </div>
    <div class="date-item qLink" *ngIf="subscribeServices === null">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" pTooltip="Subscribe Product" alt="quickLink">
        <span (click)="openFPFormHelp('help','right')" pTooltip="Help" style="float: right" tooltipPosition="bottom"> <i
                class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
        <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.userRole?.includes('Partner') == false -->
    </div>

</div>
<div class="default-theme" *ngIf="(isLoggedIn$ | async) && userData?.businessId &&  userData?.userRole && !userData?.userRole?.includes('Partner') && userData?.businessId == 'SVS000688'">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    
    <!-- help code here -->
    <!-- <app-help [displayhelpcontent]=displayFPS (cancelhelpEmit)="getCancelAddMessage($event)"></app-help> -->

    <!-- Tour code here -->
    <app-tour [displayTourContent]=displayTour (cancelTourEmit)="getCancelTourMessage($event)"></app-tour>

    <sv-nsportalheader *ngIf="isLoggedIn$ | async" [bussinessLogo]="bussinessLogo" [businessLogoObj]="headerLogo"
        [userData]="userData" [nineDots]="nineDotsArray" [bussinessData]="bussinessData" [userProfile]="userProfile"
        (onOpenHelpAndSupport)="getHelpandSupport($event)" (onChangeProfilePicture)="onProfilePicChange($event)"
        (onRemoveProfilePicture)="removeProfile()" (onChangePassword)="changePassword()" (onLogOut)="logOut()" [profileMenu]="''" 
        [CurrentComponentTitle]="CurrentComponent" [blueRedirectURL]="blueURL">
    </sv-nsportalheader>

    <div class="date-item qLink" *ngIf="subscribeServices != null ">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" *ngIf="quickLinkAccessMenu.length > 0"
            (click)="op2.toggle($event)" alt="quickLink">
        <span *ngIf="productAccessMenu.length > 0" (click)="openFPFormHelp('help','right')" pTooltip="Help"
            style="float: right;" tooltipPosition="bottom">
            <i class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
              <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.businessId != 'SVS000688' && userData?.userRole?.includes('Partner') == false -->
    </div>
    <div class="date-item qLink" *ngIf="subscribeServices === null">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" pTooltip="Subscribe Product" alt="quickLink">
        <span (click)="openFPFormHelp('help','right')" pTooltip="Help" style="float: right" tooltipPosition="bottom"> <i
                class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
        <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.userRole?.includes('Partner') == false -->
    </div>

</div>
<div *ngIf="(isLoggedIn$ | async)  && userData?.businessId &&  userData?.userRole && userData?.userRole?.includes('Partner') && userData?.businessId == 'SVS000688'">
    <app-tour [displayTourContent]=displayTour (cancelTourEmit)="getCancelTourMessage($event)"></app-tour>
    <app-usic-parnter-header  [headerLogo]="headerLogo" [CurrentComponent]="CurrentComponent" [partnerDetails]="partnerDetails"
    [partnerLogo]="partnerLogo" [bussinessData]="bussinessData" [userData]="userData" [nineDotsArray2]="nineDotsArray"
    *ngIf="userData?.businessId && userData?.businessId === 'SVS000688' &&  userData?.userRole && userData?.userRole?.includes('Partner')"></app-usic-parnter-header>
    <div class="date-item qLink" *ngIf="subscribeServices != null ">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" *ngIf="quickLinkAccessMenu.length > 0"
            (click)="op2.toggle($event)" alt="quickLink">
        <span *ngIf="productAccessMenu.length > 0" (click)="openFPFormHelp('help','right')" pTooltip="Help"
            style="float: right;" tooltipPosition="bottom">
            <i class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
              <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.businessId != 'SVS000688' && userData?.userRole?.includes('Partner') == false -->
    </div>
    <div class="date-item qLink" *ngIf="subscribeServices === null">
        <img class="qlink-btn" [class.active]="CurrentComponent === 'Error Board'" src="assets/imgs/quickLinkIcon.png" pTooltip="Subscribe Product" alt="quickLink">
        <span (click)="openFPFormHelp('help','right')" pTooltip="Help" style="float: right" tooltipPosition="bottom"> <i
                class="fa fa-question-circle qlink-btns" style="cursor: pointer;color: #484DDD"></i></span>
        <img src="assets/imgs/tour.png" class="taketourbtn" *ngIf="routerCheck" (click)="openTour()" alt="tour"> <!--  && userData?.userRole?.includes('Partner') == false -->
    </div>
</div>


<div class="qLink">
    <p-overlayPanel #op2>
        <div *ngFor="let Sproduct of quickLinkAccessMenu;trackBy:trackByServiceCode">
            <div class="onedotQ">
                <a class="nav-link lkj" *ngIf="Sproduct.productName != 'Nexus'; else nexusTemplate"
                    href="{{nineDotBaseUrl}}{{Sproduct?.redirectUrl}}{{token}}&quickLink=quickLink"
                    style="text-align: center;">
                    <img class="nineImgQ" [src]="Sproduct?.notificationLinkIcon" alt="{{Sproduct?.productName}}">
                </a>
                <ng-template #nexusTemplate>
                    <a class="nav-link lkj" href="{{nineDotBaseUrl}}{{Sproduct?.redirectUrl}}{{token}}"
                        style="text-align: center;">
                        <img class="nineImgQ" [src]="Sproduct?.notificationLinkIcon" alt="{{Sproduct?.productName}}">
                    </a>
                </ng-template>
            </div>
        </div>
    </p-overlayPanel>
</div>

<!-- Create Ticket Form -->
<p-sidebar class="createTicketSidebar" [(visible)]="createTicketSidebar" position="right" [baseZIndex]="10000" ariaCloseLabel="close">
    <h6 class="heading">Create Support Ticket for StraViso</h6>
    <form [formGroup]="createTicketForm">
        <div class="row">
            <div class="col-4 businessId pr-1">
                <label class="label m-0" for="businessId">Business Id</label>
                <input type="text" formControlName="businessId" name="businessId" id="businessId" disabled=true>
            </div>
            <div class="col-8 businessName pl-1">
                <label class="label m-0" for="businessName">Business Name</label>
                <input type="text" formControlName="businessName" name="businessName" id="businessName" disabled=true>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 subject">
                <div class="Cshadow">
                    <div class="row">
                        <div class="col-2 text-center">
                            <img src="assets/imgs/subject.png" alt="">
                        </div>
                        <div class="col-10">
                            <label class="label m-0" for="subject">Subject<span class="redSpan"> *</span></label>
                            <input type="text" formControlName="subject" name="subject" id="subject" autocomplete="off"
                                maxlength="70" placeholder="Maximum 70 characters...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 product">
                <div class="Cshadow">
                    <div class="row">
                        <div class="col-2 text-center">
                            <img src="assets/imgs/product.png" alt="">
                        </div>
                        <div class="col-5 pr-1">
                            <label class="label m-0" for="dd">Product</label>
                            <p-dropdown #dd [options]="products" filter="true" formControlName="product" name="product"
                                (onShow)="dd.resetFilter()" optionLabel="name" placeholder="Select a product">
                            </p-dropdown>
                        </div>
                        <div class="col-5 pl-1">
                            <label class="label m-0" for="projectdd">Category</label>
                            <p-dropdown #projectdd [options]="projects" filter="true" (onShow)="projectdd.resetFilter()"
                                formControlName="category" name="category" optionLabel="name"
                                placeholder="Select a category"></p-dropdown>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-5 pr-1">
                            <label class="label m-0" for="prioritydd">Priority</label>
                            <p-dropdown #prioritydd [options]="priority" filter="true" formControlName="priority"
                                ui-dropdown-filter='' (onShow)="prioritydd.resetFilter()" optionLabel="name"
                                placeholder="Select a Priority">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12 des">
                <div class="Cshadow">
                    <div class="row">
                        <div class="col-2 text-center">
                            <img src="assets/imgs/desc.png" alt="">
                        </div>
                        <div class="col-10">
                            <label class="label m-0" for="description">Description<span class="redSpan"> *</span></label>
                            <textarea rows="6" formControlName="description" name="description" id="description"></textarea>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-7">
                            <label class="label m-0" for="file">Attachments</label>
                            <p>{{files[0]?.name}}</p>
                        </div>
                        <div class="col-3 text-right">
                            <input #file style='display: none;' type="file" (change)="onFileChange($event)"
                                formControlName="file" name="file">
                            <button type="button" role="button" (click)="file.click()" aria-pressed="false">Attach File</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 des text-right">
                <button type="submit" [disabled]="createTicketForm.invalid" role="button" aria-pressed="false" (click)="createTicket(createTicketForm.value)">Create</button>
                <button class="ml-2" (click)="createTicketSidebar = !createTicketSidebar" role="button" aria-pressed="false">Cancel</button>
            </div>
        </div>
    </form>
</p-sidebar>


<p-dialog [(visible)]="feedbackMoal" class="feedbackModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div class="heading">
        <h2 class="">Feedback</h2>
        <p>Please rate your experience</p>
    </div>
    <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback(feedbackForm.value)">
        <div class="row text-center">
            <div class="col-12">
                <rating class="rating" formControlName="rating" [max]="max" [readonly]="isReadonly"
                    (onHover)="hoveringOver($event)" (onLeave)="resetStar()"></rating>
                <p class="ratingLabel">{{ratingName}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <textarea class="feedbackTextarea" rows="8" cols="45" formControlName="comment"
                    placeholder="Your comments....."></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12 buttonCol">
                <button class="submit" [disabled]="feedbackForm.invalid">Submit</button>
                <button type="button" class="cancel" (click)="feedbackMoal=false; feedbackForm.reset();">Cancel</button>
            </div>
        </div>
    </form>
</p-dialog>

<!-- old about modal
<p-dialog [(visible)]="aboutModal" class="aboutModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div class="row">
        <div class="col-12">
            <h6 class="heading">Product Information</h6>
            <h1 class="productHeading">NorthStar</h1>
            <p>Product Activated</p>
            <p>NorthStar Version 2020.5</p>
        </div>
    </div>

    <div class="row product mt-5">
        <div class="col-12 mb-2">
            <p>This product contains</p>
        </div>
    </div>
    <div class="row product">
        <div class="col pr-0 mt-2">
            <img src="assets/imgs/about/fieldserve.svg" alt="fieldserve">
        </div>
        <div class="col pr-0 mt-2">
            <img src="assets/imgs/about/buzz.svg#svgView(viewBox(0,0,100%,100%))" alt="Buzz">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/nexus.svg" alt="nexus">
        </div>
        <div class="col pr-0 mt-2">
            <img src="assets/imgs/about/lumen.svg" alt="lumen">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/amtrack.svg" alt="amtrack">
        </div>
        <div class="col pl-0  mt-2">
            <img src="assets/imgs/about/taskeasy.svg" alt="taskeasy">
        </div>
    </div>
    <div class="row product">
        <div class="col pr-0 mt-2">
            <img src="assets/imgs/about/appointment.svg" alt="appointment">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/workorder.svg" alt="workorder">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/ticket.svg" alt="ticket'">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/customer.svg" alt="customer">
        </div>
        <div class="col p-0 mt-2">
            <img src="assets/imgs/about/knowme.svg" alt="knowme">
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 buttonCol text-center">
            <button class="cancel" (click)="aboutModal=false;">Close</button>
        </div>
    </div>
</p-dialog> -->
<!-- About Modal Start here -->

<p-dialog [(visible)]="aboutModal" class="aboutModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div class="row m-0">
        <div class="col-12 mt-3">
            <h6 class="info-label mb-0">Product Information</h6>
            <h1 class="productHeading">StraViso Global Cloud</h1>
            <p class="info-label mb-0">NorthStar {{currentYear}}</p>
            <p class="info-label mb-0">Version 6.5</p>
        </div>
    </div>
    <div class="info-row mt-3">
        <div class="row m-0 product-row">
            <div class="col-6 col-md-4 col-lg p-0 mt-4" *ngFor="let img of aboutImages">
                <img [src]="img.src" [alt]="img.alt">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 buttonCol text-center">
                <button class="cancel info-button" (click)="aboutModal=false">Close</button>
            </div>
        </div>
    </div>
</p-dialog>
<!-- About Modal End here -->
<p-dialog [(visible)]="textMsgModal" class="textMsgModal" [modal]="true" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false">
    <div *ngIf="msg">
        <div class="heading">
            <h6 class="">Send text message at this number</h6>
        </div>
        <div>
            <p class="contactNox text-center">{{onInputChange(supportContactNo,false)}}</p>
        </div>
    </div>
    <div *ngIf="mail">
        <div class="heading">
            <h6 class="">Email Us</h6>
        </div>
        <div>
            <p class="contactMail text-center">{{supportMailId}}</p>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 buttonCol text-center">
            <button class="cancel" (click)="textMsgModal=false;mail=false;msg=false;">Close</button>
        </div>
    </div>
</p-dialog>

<div class="">
    <img class="lumenChatIcon" id="chatIcon" (click)="openForm('askLumen')" src="assets/imgs/chat_ic.png" alt="">
</div>


<div class="chat-popup" id="myForm">
    <!-- {{lumenChatURL | json}} -->
    <iframe id="iframeID" title="LumenChat" allow="camera *;microphone *" [src]="lumenChatUrl" height="575px"
        width="100%">
    </iframe>
</div>
<p-dialog header="Help" [(visible)]="displayhelpcontent" class="overlayDialogHelp" [modal]="true" [position]="position"
     [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="checkClose()">
    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    <div class="help-search p-2">
        <div class="ui-inputgroup">
            <span class="searchSpan d-inline-block mr-2">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input placeholder="Search" class="searchBar" type="text" name="helpSearch" [(ngModel)]="helpText"
                    (ngModelChange)="searchValues(helpText)">
            </span>
            <!-- <span class="ui-inputgroup-addon"><i class="pi pi-search" style="line-height: 1.25;"></i></span>
            <span class="searchSpan">
                <input placeholder="Search" class="searchBar" type="text"  style="
                    margin-bottom: 10px;">
            </span> -->

            <span class="pl-3"> <!-- (click)="downloadQuickRefPdf()" -->
                <button class="btn btn-sm qrefbtn float-right" (click)="openUserGuidePopup(quickRefGuideLabel,quickRefGuideLink)">Quick Reference
                    Guide</button>
            </span>
        </div>
    </div>
    <p-accordion (onOpen)="onTabOpen($event)" [activeIndex]="index">
        <p-accordionTab>
            <p-header *ngIf="!textvalueText" style="cursor: pointer;">Index <span class="ml-3"
                    *ngIf="textvalueText">Search Results Found
                    ({{guideResponseLengthNewData}}) </span> </p-header>
            <p-header *ngIf="textvalueText" style="cursor: pointer;">Index <span class="ml-3"
                    *ngIf="textvalueText">Search Results Found
                    ({{guideResponseLengthNewData}}) </span> </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">

                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of guideResponse;let indexofEle=index;">
                        <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName === highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>

                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div>


                        <div *ngIf="showDetails === indexofEle " style="margin-left: 30px;padding: 0px">
                            <div *ngFor="let guideHeading of guide.indexData?.headings;let indexofEless=index;">
                                <span style="display: inline-block">
                                    <p class="faqPara hovera"
                                        (click)="openDetailsHelpItemHeading(indexofEless,guideHeading.headingName,guide.serviceName)"
                                        style="display: list-item;font-size: 14px;cursor: pointer;">

                                        <i class="arrow-text-secondary down"
                                            *ngIf="guideHeading?.headingName && guideHeading?.headingName === highlightCheckHelpHeading "
                                            style="margin-right: 5px;margin-left: 4px;"></i>
                                        <i class="arrow-text-secondary right text-secondary"
                                            *ngIf="guideHeading?.headingName && guideHeading?.headingName && guideHeading.headingName != highlightCheckHelpHeading "
                                            style="margin-right: 5px;margin-left: 4px;"></i>


                                        <span class="text-secondary">{{guideHeading?.headingName}}</span>
                                    </p>

                                </span>
                                <div *ngIf="showDetailsHelpHeading === indexofEless && highlightCheckHelpHeading === guideHeading.headingName "
                                    style="margin-left: 30px;padding: 0px">
                                    <div
                                        *ngFor="let guideSubHeading of guideHeading?.subHeadings;let indexofElesNew=index;">
                                        <span style="display: inline-block">
                                            <p class="faqPara hovera"
                                                (click)="openDetailsHelpItemHeadingNew(indexofElesNew,guideSubHeading.subHeadingName,guideSubHeading.Response)"
                                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                                <i class="arrow-text-secondary down text-secondary"
                                                    *ngIf="guideSubHeading?.subHeadingName && guideSubHeading?.subHeadingName === highlightCheckHelpHeadingNew "
                                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                                <i class="arrow-text-secondary right text-secondary"
                                                    *ngIf="guideSubHeading?.subHeadingName && guideSubHeading?.subHeadingName != highlightCheckHelpHeadingNew "
                                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                                <span class="text-secondary">{{guideSubHeading?.subHeadingName}}</span>
                                            </p>

                                        </span>

                                        <div *ngIf="showDetailsHelpHeadingNew === indexofElesNew && highlightCheckHelpHeadingNew === guideSubHeading.subHeadingName"
                                            style="margin-left: 30px;padding: 0px">
                                            <div
                                                *ngFor="let topic of guideSubHeading?.helpTopics;let indexofElesFinal=index;">
                                                <span style="display: inline-block">
                                                    <p class="faqPara hovera"
                                                        (click)="openDetailsHelpItemFinal(indexofElesFinal,topic.topicName)"
                                                        style="display: list-item;font-size: 14px;cursor: pointer;">

                                                        <i class="arrow-text-secondary down text-secondary"
                                                            *ngIf="topic?.topicName && topic?.topicName === highlightCheckHelpHeading "
                                                            style="margin-right: 5px;margin-left: 4px;"></i>
                                                        <i class="arrow-text-secondary right text-secondary"
                                                            *ngIf="topic?.topicName && topic?.topicName != highlightCheckHelpHeading "
                                                            style="margin-right: 5px;margin-left: 4px;"></i>


                                                        <span class="text-secondary">{{topic?.topicName}}</span>
                                                    </p>

                                                </span>

                                                <img style="display: inline-block" *ngIf="topic?.topicName" 
                                                    src="assets/imgs/maximize.png" alt="customer"
                                                    style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                                                    (click)="openFormDetails(topic.answer,topic.topicName,'Help',[] )">

                                                <p *ngIf="showDetailsHelpHeadingFinal === indexofElesFinal && highlightCheckHelpFinal === topic?.topicName"
                                                    style="padding-left: 20px">
                                                    <span style="font-size: 13px;" [innerHTML]="sanitizer.bypassSecurityTrustHtml(topic?.answer)"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let faqHelp of  guideResponseNewData;let indexofEles=index;">
                        <span style="display: inline-block;width: 280px">
                            <p class="faqPara hovera"
                                (click)="openDetailsHelpItem(indexofEles,faqHelp.topicName,faqHelp.answer)"
                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                <i class="arrow down text-secondary " *ngIf="faqHelp.topicName === highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right text-secondary" *ngIf="faqHelp.topicName != highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                <b style="display: inline-block;"
                                    style="font-family: Roboto !important;">{{faqHelp.topicName}}</b>

                            </p>

                        </span>

                        <img style="display: inline-block" src="assets/imgs/maximize.png" alt="customer"
                            style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                            (click)="openFormDetails(faqHelp.answer,faqHelp.topicName,'Help',faqHelp.imgLink)">

                        <div *ngIf="showDetailsHelp === indexofEles && newValueCheck === faqHelp.answer ">
                            <p style="padding-left: 20px;font-family: Roboto !important;"><span class="text-secondary"
                                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(faqHelp.answer)"></span></p>
                        </div> ​
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
        <p-accordionTab>
            <p-header style="cursor: pointer;">FAQ's<span class="ml-3" *ngIf="helpText"> Search Results Found
                    ({{faqResponseNew.length}})</span>
            </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">
                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of faqResponse;let indexofEle=index;">
                        <!-- <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName === highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
    
                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div> -->
                        <!-- <div *ngIf="showDetails === indexofEle" style="margin-left: 30px;padding: 0px"> -->
                        <div *ngFor="let faqHelp of  guide?.faqs;let indexofEles=index;">
                            <span style="display: inline-block">
                                <p class="faqPara hovera"
                                    (click)="openDetailsHelpItem(indexofEles,faqHelp.topicName,'')"
                                    style="display: list-item;font-size: 14px;cursor: pointer;">

                                    <i class="arrow down " *ngIf="faqHelp.topicName === highlightCheckHelp "
                                        style="margin-right: 5px;margin-left: 4px;"></i>
                                    <i class="arrow right " *ngIf="faqHelp.topicName != highlightCheckHelp "
                                        style="margin-right: 5px;margin-left: 4px;"></i>


                                    <b style="display: inline-block;"
                                        style="font-family: Roboto !important;">{{faqHelp.topicName}}</b>
                                </p>
                            </span>

                            <img style="display: inline-block" src="assets/imgs/maximize.png" alt="customer"
                                style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                                (click)="openFormDetails(faqHelp.answer,faqHelp.topicName,'Help',[])">

                            <p *ngIf="showDetailsHelp === indexofEles && highlightCheckHelp === faqHelp.topicName"
                                style="padding-left: 20px"><span class="text-secondary"
                                    [innerHTML]="sanitizer.bypassSecurityTrustHtml(faqHelp.answer)"></span></p>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let guide of faqResponseNew;let indexofEle=index;">
                        <!-- <div *ngFor="let faqHelp of  guide.faqs;let indexofEles=index;"> -->
                        <span style="display: inline-block">
                            <p class="faqPara hovera"
                                (click)="openDetailsHelpItem(indexofEle,guide.topicName,guide.answer)"
                                style="display: list-item;font-size: 14px;cursor: pointer;">

                                <i class="arrow down " *ngIf="guide.topicName === highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.topicName != highlightCheckHelp "
                                    style="margin-right: 5px;margin-left: 4px;"></i>


                                <b style="display: inline-block;"
                                    style="font-family: Roboto !important;">{{guide.topicName}}</b>
                            </p>
                        </span>

                        <img style="display: inline-block" src="assets/imgs/maximize.png" alt="customer"
                            style="display: inline-block;width: 9px;margin-left: 5px;cursor: pointer"
                            (click)="openFormDetails(guide.answer,guide.topicName,'Help',[])">

                        <p *ngIf="showDetailsHelp === indexofEle && highlightCheckHelp === guide.topicName"
                            style="padding-left: 20px"><b style="font-family: Roboto !important; font-size: 13px;"
                                [innerHTML]="sanitizer.bypassSecurityTrustHtml(guide.answer)"></b></p>
                        <!-- </div> -->
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
        <p-accordionTab>
            <p-header style="cursor: pointer;">Videos <span class="ml-3" *ngIf=" helpText">Search Results Found
                    ({{videoResponseNew.length}})</span> </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">
                <div *ngIf="!textvalueText">
                    <div *ngFor="let guide of videoResponse;let indexofEle=index;">
                        <!-- <div>
                            <p class="faqPara" (click)="openDetails(indexofEle,guide.serviceName)"
                                style="display: inline-block;font-family: Roboto !important;font-size: 15px;cursor: pointer;">
                                <i class="arrow down " *ngIf="guide.serviceName === highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
                                <i class="arrow right " *ngIf="guide.serviceName != highlightCheck "
                                    style="margin-right: 5px;margin-left: 4px;"></i>
    
                                <b>{{guide.serviceName}}</b>
                            </p>
                        </div> -->
                        <!-- <div *ngIf="showDetails === indexofEle" style="margin-left: 30px;padding: 0px"> -->
                        <div *ngFor="let video of  guide?.videos;let indexofEles=index;">
                            <img (click)="openVideo(video.answer,video.topicName)" src="assets/imgs/play.png"
                                alt="" style="width: 28px;display: inline-block;cursor: pointer;margin-right: 15px;">
                            <p class="faqPara" (click)="openVideo(video.answer,video.topicName)"
                                style="font-family: Roboto !important;font-size: 15px;margin-bottom: 5px;display: inline-block;">
                                <b style="cursor: pointer;">{{video.topicName}}</b></p>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <div *ngIf="textvalueText">
                    <div *ngFor="let guide of videoResponseNew;let indexofEle=index;">
                        <!-- <div *ngFor="let video of  guide.videos;let indexofEles=index;"> -->
                        <img (click)="openVideo(guide.answer,guide.topicName)" src="assets/imgs/play.png"
                            alt="" style="width: 28px;display: inline-block;cursor: pointer;margin-right: 15px;">
                        <p class="faqPara" (click)="openVideo(guide.answer,guide.topicName)"
                            style="font-family: Roboto !important;font-size: 15px;margin-bottom: 5px;display: inline-block;">
                            <b style="cursor: pointer;">{{guide.topicName}}</b></p>
                        <!-- </div> -->
                    </div>
                </div>
            </p-scrollPanel>
        </p-accordionTab>
        <p-accordionTab>
            <p-header style="cursor: pointer;">Reference/User Guide <span class="ml-3" *ngIf=" helpText">Search Results Found
                    ({{videoResponseNew.length}})</span> </p-header>

            <p-scrollPanel [style]="{ height: '400px'}" styleClass="mystyle">
                <div>
                    <div *ngFor="let userGuide of userGuideResponse">
                        <p class="faqPara ml-3" (click)="openUserGuidePopup(userGuide.label,userGuide.link)"
                            style="font-family: Roboto !important;font-size: 15px;margin-bottom: 5px;display: inline-block;">
                            <b style="cursor: pointer;">{{userGuide.label}}</b></p><br/>
                    </div>
                    
                </div>
            </p-scrollPanel>
        </p-accordionTab>
    </p-accordion>
</p-dialog>

<p-dialog [(visible)]="displaydetailHelp" class="textMsgModalHelp" [modal]="true" [baseZIndex]="10000"
    [position]="overposition" [draggable]="false" [resizable]="false"
    [style]="{width: '950px', height: 'auto',left: '80px'}">
    <p-header>
        <p class="pheading">
            {{textNameData}}</p>
    </p-header>
    <div>
        <p class="faqPara"><b>{{moduleNameData}}</b></p>
    </div>
    <div>
        <div>
            <p class="left-padding">
                <span class="text-secondary" [innerHTML]="sanitizer.bypassSecurityTrustHtml(detailformData)"></span>
            </p>
        </div>
    </div>
</p-dialog>

<p-dialog [(visible)]="videoModalHelp" class="textMsgModalHelpVideo" [modal]="true" [baseZIndex]="10000"
    [position]="overposition" [draggable]="false" [resizable]="false"
    [style]="{width: '950px', height: 'auto',left: '80px'}">

    <p-header>
        <p class="pheading">
            {{videoNameData}}</p>
    </p-header>

    <iframe [src]="videoLink" width="920" height="750" title='video'></iframe>
    <a [href]="videoLink" target="_blank">
        <span>
            <i class="fa fa-expand"></i>
        </span>
    </a>

</p-dialog>


<p-dialog [(visible)]="displayUserGuide" class="textMsgModalHelp userGuidePopup" [modal]="true" [baseZIndex]="10000"
    [position]="overposition" [draggable]="false" [resizable]="false" [maximizable]="true"
    [style]="{width: '1032px', height: 'auto',left: '14px'}" #userGuideDialog>
    <p-header>
        <p class="pheading">{{userGuideTitle}}</p>
    </p-header>
    <div>
        <iframe id="iframeID" title="" allow="camera *;microphone *" [src]="userGuideLink" height="589px"
        width="100%"></iframe>
        <div class="row justify-content-end mr-2" >
            <span (click)="userGuideFullScreenView(userGuideDialog)">
                <i class="fa fa-expand" style="color:blue;cursor: pointer;"></i>
            </span>
        </div>
    </div>
</p-dialog>




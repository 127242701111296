<p-dialog [(visible)]="displayTourContent" class="textMsgModalHelpTour" [closeOnEscape]="false" [modal]="true" [baseZIndex]="10000"
    position="center" [draggable]="false" [resizable]="false" [ngClass]="{'svs688tour':loggedInBusinessId == 'SVS000688'}">

    <!-- <p-carousel [value]="counts" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" 
    [circular]="false" [responsiveOptions]="responsiveOptions" [page]="currentPage" (onPage)="changedPage($event)">
        <ng-template let-count pTemplate="item">
            <div class="car-details">
                <div class="p-grid p-nogutter">
                    <div class="p-col-12">
                        <img src="assets/tour/{{count}}.png" alt="tour" />
                    </div>
                    <div class="p-col-12">
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel> -->
    <p-galleria #galleria [(value)]="counts" [(activeIndex)]="activeIndex" [numVisible]="5" 
        [containerStyle]="{'max-width':'100%'}" [showThumbnails]="showThumbnails" [showIndicators]="true"
        [showItemNavigators]="true" [circular]="true" [autoPlay]="false">
        <ng-template pTemplate="item" let-item>
            <img src="assets/tour/{{item}}.png" alt="" *ngIf="loggedInBusinessId != 'SVS000688'"/>
            <img src="assets/tour/SVS000688/{{item}}.png" alt="" *ngIf="loggedInBusinessId == 'SVS000688'"/>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img src="assets/tour/{{item}}.png" alt="" *ngIf="loggedInBusinessId != 'SVS000688'"/>
                <img src="assets/tour/SVS000688/{{item}}.png" alt="" *ngIf="loggedInBusinessId == 'SVS000688'"/>
            </div>
        </ng-template>
    </p-galleria>
    <!-- <ng-template pTemplate="footer"> 
    <button class="ui-carousel-prev ui-button ui-widget ui-state-default ui-corner-all"
        (click)="previousPage(currentPage)" >
        <span class="ui-carousel-prev-icon pi pi-chevron-left"></span>
    </button>
    <button class="ui-carousel-next ui-button ui-widget ui-state-default ui-corner-all"
    (click)="nextPage(currentPage)" >
    
        <span class="ui-carousel-next-icon pi pi-chevron-right"></span></button>
             </ng-template> -->
    <div class="row m-0">
        <div class="" *ngIf="!displayCheckbox" >
            <p-checkbox class="checkBox" name="tourCheckbox"
             [(ngModel)]="checked" [ngModelOptions]="{standalone: true}" (onChange)="disableTour()" binary="true" label="Don't Show Again" ></p-checkbox>
        </div>
        
        <p-button class="skip ui-button-secondary" icon="pi pi-angle-double-right" iconPos="right" label="SKIP" (click)="checkClose()"></p-button>
    </div>
</p-dialog>
import { Component, OnInit } from '@angular/core';
// import { SwUpdate } from '@angular/service-worker';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NorthStar Framework';

  constructor() { }

  ngOnInit() {
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    let url = window.location.hash;
    let inviteUrl = url.includes("home?email");
    let verifyUrl = url.includes("verify?email");
    if(inviteUrl){
      // this.clearPreviousLoggedInData();
      localStorage.clear();
    }    
    if(isLoggedIn && !inviteUrl && !verifyUrl){
      if(url==""){
        window.location.href = window.location.origin +"/#/"+"business-dashboard";
      }
      else{
        window.location.href = window.location.origin +"/"+url;
      }
     
    }
    // if (this.swUpdate.isEnabled) {
    //     this.swUpdate.available.subscribe(() => {
    //         if(confirm("New version available. Load New Version?")) {
    //             window.location.reload();
    //         }
    //     });
    // }
  }
}

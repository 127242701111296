import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject,Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  currentSrc: Observable<any>;
  currentURLSrc: Observable<any>;
  profileData: Observable<any>;
  customerRedirect: Observable<any>;
  shareproductSubscribed: Observable<any>;
  shareAddConfig: Observable<any>;
  shareconfigLength: Observable<any>;
  allUserSubscribed: Observable<any>;

  private businessLogoSrc = new BehaviorSubject('');
  private urlSrc = new BehaviorSubject('');
  private userProfileData = new BehaviorSubject (JSON.parse(localStorage.getItem('userPData')));
  private customerSrc = new BehaviorSubject('');
  private subject = new Subject();
  private productSubscribed = new BehaviorSubject([]);
  private addConfig = new BehaviorSubject('');
  private configLength = new BehaviorSubject(0);
  private businessDetailsSubject = new Subject();
  private allUser = new BehaviorSubject([]);

  constructor() {
    this.currentSrc = this.businessLogoSrc.asObservable();
    this.currentURLSrc = this.urlSrc.asObservable();
    this.profileData = this.userProfileData.asObservable();
    this.customerRedirect = this.customerSrc.asObservable();
    this.shareproductSubscribed = this.productSubscribed.asObservable();
    this.shareAddConfig = this.addConfig.asObservable();
    this.shareconfigLength = this.configLength.asObservable();
    this.allUserSubscribed = this.allUser.asObservable();

   }

  changeUrl(message: string) {
    this.businessLogoSrc.next(message);
  }

  userProfileDataChange(data: any) {
    this.userProfileData.next(data);
  }

  urlChange(message: string){
    this.urlSrc.next(message);
  }

  redirectCustomer(message: string){
    this.customerSrc.next(message);
  }

  //update header after product subscription
  sendHeaderUpdateEvent() {
    this.subject.next();
  }
  getHeaderUpdateEvent(): Observable<any>{
    return this.subject.asObservable();
  }

  getProductSubscribed(access){
    this.productSubscribed.next(access);
  }

  getaddConfig(type){
    this.addConfig.next(type);
  }

  getConfigLength(length){
    this.configLength.next(length);
  }

  //update header after business Header is changed.
  sendBusinessUpdateEvent() {
    this.businessDetailsSubject.next();
  }
  getBusinessUpdateEvent(): Observable<any>{
    return this.businessDetailsSubject.asObservable();
  }

  getAllUserShared(data) {
    this.allUser.next(data);
  }

}
